import React, { useEffect, useState, type FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Button, Headline } from '../../../components'
import { Subnav } from '../../../components/Subnav/Subnav'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useGetOrganizationQuery } from '../../../services/orders/Orders'
import { useGetLoggedInUserQuery } from '../../../services/user/User'
import { useGetAccessTokenMutation } from 'services/auth/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

const Settings: FC = (): JSX.Element => {
  const location = useLocation()
  const { data: loggedInUser } = useGetLoggedInUserQuery(1)
  const [ isTracker, setIsTracker ] = useState(false)
  const { data: organization } = useGetOrganizationQuery(
    loggedInUser?.organizationSid,
    {
      skip: !loggedInUser,
    }
  )
  const [getAccessToken] = useGetAccessTokenMutation()
  console.log(organization)

  useEffect(() => {
    const accessData: any = async () => {
      const { data: { defaultPage }, } = (await getAccessToken({})) as {
        data: { accessToken: string; defaultPage: '/track' | '/update' }
      }
      setIsTracker(defaultPage === '/track')
    }
    accessData()
  }, [])


  const tabs = [
    {
      active:
        !location.pathname?.includes('team') &&
        !location.pathname?.includes('communications') &&
        !location.pathname?.includes('flags'),
      content: 'my profile',
      path: '',
    },
    {
      active: location.pathname?.includes('team'),
      content: 'team',
      path: 'team',
    },
    // TODO: Enable once backend is done
    // {
    //   active: location.pathname?.includes('communications'),
    //   content: 'communications',
    //   path: 'communications',
    // },
  ]

  if(isTracker){
    tabs.push(
      {
        active: location.pathname?.includes('flags'),
        content: 'flags',
        path: 'flags',
      },
    )
  }
  
  return (
    <>
      <TopSection sm>
        <Headline size="xl" className="text-offWhite-light p-0 flex gap-20 items-center">
          <div>Settings</div>
          <div className="flex flex-col">
            <span className="text-xs text-offBlack-light">MY ORGANIZATION</span>
            <span className="text-offWhite-light text-xl h-[28px]">
      {organization?.name}
    </span>
          </div>
          <div className="ml-auto">
            <Button style="secondary">
              <a href='/api/integrations/google/login'>
                <FontAwesomeIcon icon={brands('google')} /> Sign in with Google
              </a>
            </Button>
          </div>
        </Headline>
        <div className="mt-[72px]">
          <Subnav tabs={tabs} />
        </div>
      </TopSection>
      <div className="pt-20 pl-10">
        <Outlet />
      </div>
    </>
  )
}

export default Settings
