import clsx from 'clsx'
import { type FC, type MouseEventHandler, type ReactNode } from 'react'

interface TableRowProps {
  children: ReactNode;
    key: string;
    index: number;
    testId: string;
    onClick?: MouseEventHandler<HTMLTableRowElement>;
    selected?: boolean;
}

export const TableRow: FC<TableRowProps> = ({
  children,
  index,
  testId,
  onClick,
  selected = false
}) => {
  return (
    <tr
      className={clsx(
        'hover:shadow-base cursor-pointer hover:relative transition-all duration-200',
        { 'bg-teal-lightest': selected},
        { 'bg-white': index % 2 === 0 && !selected  },
        { 'bg-offWhite-light': index % 2 !== 0 && !selected }
      )}
      data-testid={testId}
      onClick={onClick}
    >
      {children}
    </tr>
  )
}
