import { type FC, type JSX } from 'react'

export interface MemberCardProps {
  memberName: string
  memberEmail: string
  currentUser?: boolean
}
export const MemberCard: FC<MemberCardProps> = ({ memberName, memberEmail, currentUser = false }: MemberCardProps): JSX.Element => {
  return (
    <div className="rounded-md shadow-lg">
      <div className="shadow-lg rounded-b-md">
        <div className="bg-offBlack-lightest text-xl pl-9 pr-10 py-3 rounded-t-md">
          <h1>{memberName} {currentUser && '(You)'}</h1>
        </div>
        <div className="font-mono pl-9 py-4 text-offBlack-dark text-sm flex justify-between pr-10">
          <div className="flex">
            <p className="self-center">EMAIL</p>
          </div>
          <div className='font-sans'>
            {memberEmail}
          </div>
        </div>
      </div>
    </div>
  )
}
