import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, {
  type ChangeEventHandler,
  type FC,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { slideOverSelector, searchParamsSelector } from 'store/selectors'
import { closeSlideOver } from 'store/slideOvers'

import { RadioButtons } from 'components/RadioButtons/RadioButtons'
import { Slideover } from 'components/Slideover/Slideover'

import { Button, Datepicker, TextArea } from 'components'

import {
  type ItemGroupCreate,
  type ItemGroupWithRelations,
} from 'types'

import { FormField } from '../FormField/FormField'
import { DateField } from './components/DateField'
import { Title } from './components/Title'
import { useHandlers } from './hooks/useHandlers'
import { countItems } from './utils/itemGrouping'
import { deliveryOptions, shipmentOptions } from './utils/options'
import {
  DeliveryOption,
  ShipOption,
  type UpdateItemFormValues,
} from './utils/types'
import { validate } from './utils/validation'

export const UpdateOrderItem: FC = () => {
  const dispatch = useAppDispatch()
  const { data, name } = useSelector(slideOverSelector)
  const { searchText, pageToken } = useSelector(searchParamsSelector)
  const itemGroups = data?.items as ItemGroupWithRelations[]

  const [submitting, setSubmitting] = useState(false)
  const [groupsFromSingleItem] = useState(itemGroups)

  const show = name === 'UpdateOrderItem'
  const itemsCount = countItems(itemGroups)
  const singleItem = itemsCount === 1

  const { initialValues, submitUpdate, submitCreate } =
    useHandlers(itemGroups, data?.role, singleItem)

  const formik = useFormik({
    initialValues: initialValues as UpdateItemFormValues,
    enableReinitialize: true,
    validate: validate(data?.role ?? 'buyer'),
    onSubmit: async (data) => {
      setSubmitting(true)
      const onSuccess = (): void => {
        onClose?.()
        setTimeout(() => {
          setSubmitting(false)
        }, 500)
      }

      if (singleItem) {
        const groupsToUpdate = groupsFromSingleItem
          .map((itemGroup) => {
            if (itemGroup.sid === data.sid) {
              return data
            }
            return itemGroup as unknown as UpdateItemFormValues
          })
          .filter((itemGroup) => itemGroup.sid?.includes('new') === false)

        submitUpdate(groupsToUpdate, onSuccess, { searchText, pageToken })

        const groupsToCreate = groupsFromSingleItem.filter((itemGroup) =>
          itemGroup.sid?.includes('new')
        ) as ItemGroupCreate[]

        if (groupsToCreate.length > 0) {
          submitCreate(groupsToCreate, onSuccess)
        }
      } else {
        submitUpdate(data, onSuccess, { searchText, pageToken })
      }
    },
  })

  const onClose = (): void => {
    setSubmitting(false)
    formik.resetForm()
    dispatch(closeSlideOver())
  }

  const handleChangeShipped = (value: string): void => {
    formik.setFieldValue('shipped', value === ShipOption.shipped)
  }

  const handleChangeShipDate = async (value: string | null): Promise<void> => {
    await formik.setFieldValue('shipDate', value)
    const newDate = dayjs(value, 'MM/DD/YY')

    if (newDate.isSameOrBefore(dayjs())) {
      await formik.setFieldValue('shipped', true)
    }
  }

  const handleChangeDueDate = (value: string | null): void => {
    formik.setFieldValue('dueDate', value)
  }

  const handleChangeDeliveryDate = async (
    value: string | null
  ): Promise<void> => {
    await formik.setFieldValue('deliveryDate', value)
    const newDate = dayjs(value, 'MM/DD/YY')

    if (newDate.isSameOrBefore(dayjs())) {
      await formik.setFieldValue('delivered', true)
    }
  }

  const handleChangeQuantity: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    formik.setFieldValue('quantity', event.target.value)
  }

  const disableQuantity = itemsCount > 1

  const updateButtonText = singleItem
    ? 'Update 1 item'
    : `Update ${itemGroups?.length} ${
        itemGroups?.length === 1 ? 'Item' : 'Items'
      }`

  if (itemsCount === 0) return null

  return (
    <Slideover open={show} onClose={onClose}>
      {itemGroups?.length > 0 ? (
        <>
          <Title itemGroups={itemGroups} role={data?.role} />
          <FormField
            label={'Item Title'}
            className='mt-5'
            inputName={'title'}
            onChange={formik.handleChange}
            defaultValue={formik.values?.title}
          />
          <FormField
            label={'Item Name'}
            className='mt-5'
            inputName={'uniqueName'}
            onChange={formik.handleChange}
            defaultValue={formik.values?.uniqueName}
          />
          <FormField
            label={'Tracking Number'}
            className='mt-5'
            inputName={'trackingNumber'}
            onChange={formik.handleChange}
            defaultValue={formik.values?.trackingNumber}
          />
          <DateField
            role={data?.role}
            onChange={handleChangeDueDate}
            value={formik.values?.dueDate as string}
            error={formik.errors.dueDate as string}
          />
          <FormField
            className="mt-5 mb-10"
            label="Shipment quantity"
            inputName="quantity"
            type={disableQuantity ? 'text' : 'number'}
            onChange={handleChangeQuantity}
            defaultValue={disableQuantity ? 'Multiple' : formik.values?.quantity}
            disabled={disableQuantity}
            cySelector="editItemGroupQuantity"
          />
          <RadioButtons
            className="mb-5"
            options={shipmentOptions}
            onChange={handleChangeShipped}
            selected={
              formik.values?.shipped ? ShipOption.shipped : ShipOption.pending
            }
          />
          <Datepicker
            className="mb-12"
            label={formik.values?.shipped ? 'Ship date' : 'Est ship date'}
            onChange={handleChangeShipDate}
            value={formik.values?.shipDate}
            error={formik.errors?.shipDate as string}
            cySelector="shipDate"
          />
          <RadioButtons
            className="mb-5"
            options={deliveryOptions}
            onChange={(value): void => {
              formik.setFieldValue(
                'delivered',
                value === DeliveryOption.delivered
              )
            }}
            selected={
              formik.values?.delivered
                ? DeliveryOption.delivered
                : DeliveryOption.pending
            }
          />
          <Datepicker
            className="mb-12"
            label={
              formik.values?.delivered ? 'Delivery date' : 'Est delivery date'
            }
            onChange={handleChangeDeliveryDate}
            value={formik.values?.deliveryDate}
            error={formik.errors?.deliveryDate as string}
            minDate={formik.values?.shipDate}
            cySelector="deliveryDate"
          />
          <TextArea
            onChange={(value): void => {
              formik.setFieldValue('notes', value)
            }}
            defaultValue={formik.values?.notes}
            placeholder="Add notes to this order..."
          />
          <div className="flex justify-end mt-10 bottom-0 right-6">
            <Button style="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={
                Object.keys(formik.errors).length > 0 || formik.isSubmitting
              }
              onClick={formik.handleSubmit}
              loading={submitting}
              cySelector="updateItems"
            >
              {updateButtonText}
            </Button>
          </div>
        </>
      ) : null}
    </Slideover>
  )
}
