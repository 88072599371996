import { type FC } from 'react'
import { Avatar } from '../Avatar/Avatar'

export interface OrderMemberAvatars {
  initials: string | undefined,
  orgName: string,
  memberType: string,
  email: string,
  color: string,
  border?: string,
}

export interface AvatarListProps {
  users: OrderMemberAvatars[],
}

export const AvatarList: FC<AvatarListProps> = ({
  users
}: AvatarListProps): JSX.Element => {
  return (
    <div className={`flex`}>
      {users.map((user, index) => (
        <div key={`avatar-list-${index}`}>
          <Avatar
            extraClasses={'p-3'}
            content={user.initials?.toUpperCase()}
            color={user.color}
            overlap={index !== 0}
            border={user.border}
            tooltipOn={true}
            tooltipData={user}
          />
        </div>
      ))}
      {/* TODO: implement add order members */}
      {/* <Avatar */}
      {/*  extraClasses={ */}
      {/*    'border-2 border-offWhite-light border-solid text-center px-3 py-2.5' */}
      {/*  } */}
      {/*  overlap */}
      {/* /> */}
    </div>
  )
}
