import { type FieldMapping } from '../../types'

export const sortFieldMappingsForSelect = (fieldMappings: FieldMapping[]): FieldMapping[] => {
  const onlyExport: FieldMapping[] = fieldMappings.filter((fm: FieldMapping) => fm.exportAllowed && !fm.importAllowed)
  const exportAndImport: FieldMapping[] = fieldMappings.filter((fm: FieldMapping) => fm.exportAllowed && fm.importAllowed)
  const onlyImport: FieldMapping[] = fieldMappings.filter((fm: FieldMapping) => !fm.exportAllowed && fm.importAllowed)
  return [
    ...onlyExport,
    ...exportAndImport,
    ...onlyImport,
    {
      name: 'Factor Default',
      sid: null,
      created: '',
      updated: '',
      organizationSid: '',
      importAllowed: true,
      exportAllowed: true,
    }
  ]
}
