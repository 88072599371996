import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dialog, Transition } from '@headlessui/react'
import { type FC, Fragment, useEffect, useState } from 'react'

interface TurnOffCommunicationProps {
  onConfirm: () => void;
  onClose?: () => void;
}
export const TurnOffCommunication: FC<TurnOffCommunicationProps> = ({ onClose, onConfirm}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(()=>{
      setTimeout(()=>{
        setIsOpen(true);
      }, 300)
  }, [])

  const closeModal = (): void => {
    setIsOpen(false)
    setTimeout(()=>{
      onClose?.()
    }, 300)
  }

  const handleConfirm = (): void => {
    onConfirm?.()
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-lg bg-white px-4 pt-10 pb-5 text-left align-middle shadow-xl transition-all">
                  <button
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={closeModal}
                    aria-label="Close"
                    data-testid="close-button"
                  >
                    <FontAwesomeIcon
                      icon={light('xmark')}
                      className="w-6 h-6 mr-2 mt-2"
                    />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-offBlack-dark"
                  >
                    Stop all followup emails from Factor?
                  </Dialog.Title>
                  <div className="mt-4 mb-5">
                    <p className="text-sm text-offBlack-dark">
                        Saving these changes will stop Factor from sending any followup emails to your vendors. Please confirm that you would like to stop these communications to your vendors.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      type="button"
                      data-testid="cancel-button"
                      className="rounded-full inline-flex justify-center border border-offWhite-mid bg-white px-8 py-3 text-sm font-semibold text-offBlack-dark"
                      onClick={closeModal}
                    >
                      Go back
                    </button>
                    <button
                      data-testid="stop-button"
                      type="button"
                      className="rounded-full inline-flex justify-center border border-offWhite-mid bg-red-mid px-8 py-3 text-sm font-semibold text-offWhite-light"
                      onClick={handleConfirm}
                    >
                      Stop communications
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
