import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

export interface TabItem {
  active: boolean
  content: string
  path: string
}

export interface SubnavProps {
  tabs: TabItem[]
  extraClassesContainer?: string
  extraClassesElements?: string
}

export const Subnav: FC<SubnavProps> = ({
  tabs,
  extraClassesContainer = '',
  extraClassesElements = '',
}: SubnavProps): JSX.Element => {
  const navigate = useNavigate()
  const statusClasses = (active: boolean): string => {
    return active
      ? 'border-b-teal-light border-b-4 pb-3 text-teal-light'
      : 'text-offWhite-light'
  }
  return (
    <>
      <ul className={`inline ${extraClassesContainer}`}>
        {tabs.map((tab: TabItem, index: number) => {
          return (
            <li
              key={`nav-${index}`}
              onClick={() => {
                navigate(tab.path)
              }}
              className={clsx(
                'inline cursor-pointer',
                { 'ml-10': index !== 0 },
                statusClasses(tab.active),
                extraClassesElements
              )}
            >
              {tab.content.toUpperCase()}
            </li>
          )
        })}
      </ul>
    </>
  )
}
