import React, { type FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components'
import { Slideover } from 'components/Slideover/Slideover'
import Dropzone from 'components/Dropzone/Dropzone'
import { Link } from 'react-router-dom'

import { useUploadOpenOrderFileMutation } from 'services/orders/Orders'

import { slideOverSelector } from 'store/selectors'
import { useAppDispatch } from 'store'
import { closeSlideOver } from 'store/slideOvers'
import { useGetFieldMappingQuery } from 'services/fieldMapping/FieldMapping'
import { type FieldMapping, type OpenOrderUpload } from 'types'
import { openNotification } from 'store/notifications'
import { useLazyGetOpenOrderUploadQuery } from 'services/openOrderUpload/OpenOrderUpload'

export const filterFieldMappingByOrganization = (fieldMappings: FieldMapping[] , organizationSid: string): FieldMapping[] => {
  if(fieldMappings === undefined || organizationSid === undefined) return []
  return fieldMappings.filter(fieldMapping => fieldMapping.organizationSid === organizationSid && fieldMapping.importAllowed)
}

export const UploadItems: FC = () => {
  const { data: organizationSid, name } = useSelector(slideOverSelector)
  const show = name === 'UploadItems'

  const [file, setFile] = useState<File | null>(null)
  const [uploadFile, { isLoading }] = useUploadOpenOrderFileMutation()

  const { data: fieldMappings, isLoading: isLoadingFieldMappings } = useGetFieldMappingQuery(1)
  const [getOpenOrderUpload] = useLazyGetOpenOrderUploadQuery()

  const dispatch = useAppDispatch()

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  const handleFile = (uploadedFile: File): void => {
    setFile(uploadedFile)
  }

  const filteredFieldMappings = filterFieldMappingByOrganization(fieldMappings?.fieldMappings, organizationSid)
  const [template, setTemplate] = useState<string | null>(filteredFieldMappings[0]?.sid);

  useEffect(()=> {
    if (template === undefined) {
      setTemplate(filteredFieldMappings[0]?.sid)
    }
  }, [filteredFieldMappings])

  const handleTemplateChange = (selector: any): void => {
    setTemplate(selector.target.value);
  };

  async function sleepInMS(ms: number): Promise<any> {
    return await new Promise((resolve) => setTimeout(resolve, ms))
  }

  const refetchOpenOrderUploadUntilFinished = async (sid: string): Promise<void> => {
    const {status} = (await getOpenOrderUpload(sid).unwrap())
    await sleepInMS(1000)
    if (status === 'processing') {
      await refetchOpenOrderUploadUntilFinished(sid)
    }
  }

  const handleSave = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', file as File)
    data.append('organizationSid', organizationSid)
    if (template !== '' && template !== undefined) data.append('fieldMappingSid', template as string)

    try {
      const { sid: openOrderUploadSid } = (await uploadFile(data).unwrap()) as OpenOrderUpload
      handleClose()
      await refetchOpenOrderUploadUntilFinished(openOrderUploadSid)
      const {status: openOrderUploadStatus, rowCountSuccess, rowCountFailure} = (await getOpenOrderUpload(openOrderUploadSid).unwrap()) as OpenOrderUpload
      if(openOrderUploadStatus === 'finished' && rowCountFailure === 0) dispatch(openNotification({title: "All items added", message: "Your items have been added successfully! Your suppliers can now use Factor to provide updates on these orders.", type: 'success', show: true}))
      else if(openOrderUploadStatus === 'finished' && rowCountFailure > 0) dispatch(openNotification({title: "New items added, but there were some issues", message: `${rowCountSuccess} items were added successfully, but ${rowCountFailure} items failed. Try checking your CSV for blank fields or other issues, and try uploading any rows with problems again.`, type: 'warning', show: true}))
      else  dispatch(openNotification({title: "New item upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
    } catch (e) {
      dispatch(openNotification({title: "New item upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
      handleClose()
    }
  }

  if (isLoadingFieldMappings) return <div>Loading...</div>

  return (
    <Slideover open={show} onClose={handleClose} title="Add items to track">
      <p className="text-sm"> Add items to Factor by uploading your open orders CSV file. Once your items and orders are in Factor, we will begin requesting updates from your vendors on your behalf.
      <br/><br/>Choose the input template that matches your file:</p>
      <br/>
      <div className='relative flex flex-col'>
        <label className='absolute text-xs text-gray-400 pl-2 mt-1'>Template</label>
        <select name="template" defaultValue={template ?? ''} onChange={handleTemplateChange} className='text-sm pt-5 text-black-400 border border-offBlack-lightest rounded-lg shadow-lg'>
          {filteredFieldMappings.map(({name, sid}: {name: string, sid: string | null}) => {
            return <option value={sid ?? ''} key={sid}>{name}</option>;
          })}
          <option value=''>Factor Default</option>
        </select>
      </div>
      <div className="mt-5">
        <Dropzone onChange={handleFile} title="Drag and drop your open orders CSV file here" />
      </div>
      <div className="flex justify-end mt-auto absolute bottom-0 right-6">
        <Button style="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={file == null}
          loading={isLoading}
          cySelector='uploadItem'
        >
          Add items
        </Button>
      </div>
      <br/>
      <Link className="underline text-blue-mid text-xs cursor-pointer mt-5"
            to={`https://www.factor.io/support/upload-csvs-in-factor`} >
            Learn more about input templates
      </Link>
    </Slideover>
  )
}
