export interface MessageInfo {
  title: string,
  message: string
}

export const getMessageInfo = (emails: string, addMe: boolean, removeMe: boolean): MessageInfo => {
  let title = '';
  let message = '';
  if(emails && addMe){
    title = 'You’ve successfully added yourself and added other order members to this order'
    message = `We added you to this order, and added these email addresses: ${emails}.`
  }
  else if(emails && removeMe){
    title = 'You’ve successfully removed yourself and added other order members to this order'
    message = `We removed you from this order, and added these email addresses: ${emails}.`
  }
  else if(addMe){
    title = 'You’ve been added to this order as a Tracking member'
    message ='You’ll receive notifications about this order’s status as updates are provided by Updating members.'
  }
  else if(emails){
    title = 'You’ve successfully added other order members to this order'
    message = `We added these email addresses: ${emails}.`
  }
  return {title, message}
}
