import { type RootState } from './index'
import { type SlideOversState } from './slideOvers'
import { type SessionState } from './session'
import { type NotificationState } from './notifications'
import { type DownloadCSVState } from './downloadCSV'
import { type SearchParamsState } from './searchParameters'

export const slideOverSelector = (state: RootState): SlideOversState =>
  state.slideOvers
export const sessionSelector = (state: RootState): SessionState => state.session

export const notificationSelector = (state: RootState): NotificationState => state.notifications

export const downloadCSVSelector = (state: RootState): DownloadCSVState => state.downloadCSV
export const featuresSelector = (state: RootState): string[] => state.session.features ?? []

export const searchParamsSelector = (state: RootState): SearchParamsState => state.searchParameters