import { type ReactNode } from 'react'
import clsx from 'clsx'

export interface BadgeProps {
  children: ReactNode
  color: 'success' | 'warning' | 'error' | 'empty' | 'danger'
}

export const Badge = ({ children, color }: BadgeProps): JSX.Element => {
  const statusClasses = {
    success: {
      background: 'bg-green-light border-green-mid',
    },
    warning: {
      background: 'bg-yellow-light border-yellow-mid',
    },
    error: {
      background: 'bg-red-light border-red-mid',
    },
    danger: {
      background: 'bg-red-light border-red-mid',
    },
    empty: {
      background: 'bg-offBlack-lightest border-offBlack-mid',
    },
  }

  return (
    <div
      className={clsx(
        statusClasses[color].background,
        'border border-solid rounded-full py-[5px] px-[9px] text-sm max-w-fit'
      )}
    >
      <div className="flex flex-row justify-center leading-none font-semibold">
        {children}
      </div>
    </div>
  )
}
