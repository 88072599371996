import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiOrderInvitesSlice = createApi({
  reducerPath: 'orderInvitesApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['OrderInvites'],
  endpoints: (builder) => ({
    getOrderInvites: builder.query({
      query: (orderSid: string | null) => `/v1/OrderInvites?orderSid=${orderSid}`,
      providesTags: ['OrderInvites'],
    }),
    createOrderInvite: builder.mutation({
      query: (body) => ({
        url: '/v1/OrderInvites',
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
    }),
  }),
})

export const { 
  useGetOrderInvitesQuery,
} = apiOrderInvitesSlice
