import { useLayoutEffect, useRef, useState } from 'react'
import { TimelineEvent } from './Event'
import { type TimelineProps } from './types'
import { isSupportedEvent } from '../../utils/timelineEvent/timelineEvent'

export const Timeline = ({
  eventFeed,
  itemName,
  selectedItemGroups = [],
  role = 'buyer',
}: TimelineProps): JSX.Element => {
  const list = useRef<any>(null)
  const [listHeight, setListHeight] = useState<number>(0)

  useLayoutEffect(() => {
    setListHeight(list.current?.clientHeight)
  }, [listHeight])

  return (
    <div className="relative pb-10">
      <div>
        <ul ref={list} className="list-ic vertical relative">
          <div className="border-r-2 border-teal-light absolute left-[7.5px] top-1 h-full"></div>
          {eventFeed
            .filter((event) => isSupportedEvent(event.eventType))
            .map((event, index) => (
              <TimelineEvent
                itemName={itemName}
                event={event}
                key={event.id}
                first={index === 0}
                role={role}
                selectedItemGroups={selectedItemGroups}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}
