import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiClearToBuildSlice = createApi({
  reducerPath: 'clearToBuildApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['ClearToBuild', 'ClearToBuildTargets'],
  endpoints: (builder) => ({
    getClearToBuilds: builder.query({
      query: () => `/v1/ClearToBuilds`,
      providesTags: ['ClearToBuild'],
    }),
    getClearToBuildsByDates: builder.query({
      query: ({startDate, endDate}) => ({
          url: `/v1/ClearToBuilds`,
          params: {startDate, endDate}
      }),
      providesTags: ['ClearToBuild'],
    }),
    getClearToBuildsByBom: builder.query({
      query: (sid: string) => ({
          url: `/v1/ClearToBuilds`,
          params: { sid }
      }),
      providesTags: ['ClearToBuild'],
    }),
    getClearToBuildTargetsByBom: builder.query({
      query: (bomSid: string) => ({
          url: `/v1/ClearToBuildTargets`,
          params: { bomSid }
      }),
      providesTags: ['ClearToBuildTargets'],
    })
  }),
})

export const {
  useGetClearToBuildsQuery,
  useGetClearToBuildsByDatesQuery,
  useGetClearToBuildsByBomQuery,
  useGetClearToBuildTargetsByBomQuery
} = apiClearToBuildSlice
