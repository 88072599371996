import React, { type FC, useEffect } from "react";
import { FormField } from "../../../components/FormField/FormField";
import { useGetSettingsQuery, usePostUpdateSettingsMutation } from "../../../services/organizationSettings/OrganizationSettings";
import { useGetLoggedInUserQuery } from "../../../services/user/User";
import { Button } from "../../../components";
import { useFormik } from "formik";
import { openNotification } from "../../../store/notifications";
import { useAppDispatch } from "../../../store";
// import { useNavigate } from "react-router-dom";


export const Flags: FC = (): JSX.Element => {
  const { data: user } = useGetLoggedInUserQuery(1)
  const { data: orgSettings, isLoading } = useGetSettingsQuery(
    user?.organizationSid,
    { skip: user === undefined }
  )
  // const navigate = useNavigate()
  const [updateOrgSettings] = usePostUpdateSettingsMutation()
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      defaultUnresponsiveAfterBusinessDays: orgSettings?.defaultUnresponsiveAfterBusinessDays,
      pastDueBusinessDays: orgSettings?.pastDueBusinessDays,
    },
    onSubmit: async (data) => {
      try {
        await updateOrgSettings({
          organizationSid: orgSettings?.sid,
          ...data,
        })
        dispatch(
          openNotification({
            title: 'Settings updated',
            message: 'Your settings were updated successfully',
            show: true,
          })
        )
      } catch {
        dispatch(
          openNotification({
            title: 'Something went wrong',
            message: 'Your settings were not updated. Please try again or contact us at support@factor.io for assistance.',
            type: 'error',
            show: true,
          })
        )
      }
    },
  })

  useEffect(() => {
    formik.initialValues.defaultUnresponsiveAfterBusinessDays = orgSettings?.defaultUnresponsiveAfterBusinessDays
    formik.initialValues.pastDueBusinessDays = orgSettings?.pastDueBusinessDays
    formik.setFieldValue('defaultUnresponsiveAfterBusinessDays', orgSettings?.defaultUnresponsiveAfterBusinessDays)
    formik.setFieldValue('pastDueBusinessDays', orgSettings?.pastDueBusinessDays)
  }, [orgSettings]);

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="pr-28">
      <div className="grid grid-cols-10 gap-x-4 gap-y-0 w-full">
        <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
          UNRESPONSIVE VENDOR
        </div>
        <div className="col-span-7">
          When vendor does not respond to first Update Request Reminder with an update, mark the line item as having an
          Unresponsive Vendor after:
        </div>
        <div className="col-span-2">
          <label
            htmlFor="unresponsive_business_days"
            className="text-offBlack-light relative top-8 left-24 text-sm z-10"
          >
            Business days
          </label>
          <FormField
            className={'w-[280px]'}
            type='number'
            inputName={'defaultUnresponsiveAfterBusinessDays'}
            defaultValue={formik.values.defaultUnresponsiveAfterBusinessDays}
            onChange={(e) => {
              formik.setTouched({ defaultUnresponsiveAfterBusinessDays: true })
              formik.handleChange(e);
            }}
          />
        </div>
      </div>

      <hr className="mt-5" />

      <div className="grid grid-cols-10 gap-x-4 gap-y-0 w-full mt-5 mb-20">
        <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
          PAST DUE
        </div>
        <div className="col-span-7">
          When today&apos;s date is after the delivery date (or due date if delivery date is unknown) on an item, mark
          the item as
          Past Due after waiting:
        </div>
        <div className="col-span-2">
          <label
            htmlFor="past_due_business_days"
            className="text-offBlack-light relative top-8 left-24 text-sm z-10"
          >
            Business days
          </label>
          <FormField
            className={'w-[280px]'}
            type='number'
            inputName={'pastDueBusinessDays'}
            defaultValue={formik.values.pastDueBusinessDays}
            onChange={(e) => {
              formik.setTouched({ pastDueBusinessDays: true })
              formik.handleChange(e);
            }}
          />
        </div>
        <div className="col-span-7 text-offBlack-light text-sm mt-3">
        {/*  Note that this setting controls when items become past due for the purpose of <span onClick={() => {  */}
        {/*  navigate("/settings/communications");  */}
        {/*  }}  */}
        {/*  className="underline text-blue-mid text-sm cursor-pointer mt-6">Past Due email notifications.</span>  */}
        </div>
        <div className="col-span-6">
          <div className="underline text-blue-mid text-sm cursor-pointer mt-5">
            <a target="_blank" rel="noreferrer" href="https://www.factor.io/support/understanding-flags-in-factor">
              Learn more about flags in Factor.
            </a>
          </div>
        </div>
        <div className='mb-3 mt-10 col-start-10'>
          <Button
            className="w-[140px]"
            style='primary'
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}