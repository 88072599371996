import { type AnyAction, type CaseReducer, type Draft, type PayloadAction } from "@reduxjs/toolkit";
import { type DownloadCSVState } from '../downloadCSV'

export const setFileStatusAction: CaseReducer<
  DownloadCSVState,
  PayloadAction<DownloadCSVState>
> = (state: Draft<DownloadCSVState>, action: AnyAction) => {
  state.fileStatus = action.payload.fileStatus
}

export const setFileSidAction: CaseReducer<
  DownloadCSVState,
  PayloadAction<DownloadCSVState>
> = (state: Draft<DownloadCSVState>, action: AnyAction) => {
  state.fileSid = action.payload.fileSid
}

export const setKeepPollingAction: CaseReducer<
  DownloadCSVState,
  PayloadAction<DownloadCSVState>
> = (state: Draft<DownloadCSVState>, action: AnyAction) => {
  state.keepPolling = action.payload.keepPolling
}

export const resetDownloadCSVAction: CaseReducer<
  DownloadCSVState
> = (state: Draft<DownloadCSVState>, action: AnyAction) => {
  state.fileSid = action.payload.fileSid
  state.keepPolling = action.payload.keepPolling
  state.fileStatus = action.payload.fileStatus
}
