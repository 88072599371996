import needsUpdateImg from '../Update/TourAssets/flags.png'
import actionsMenuImg from '../Update/TourAssets/actions_menu.gif'
import selectingItemsImg from '../Update/TourAssets/checking_items.gif'

export const updateSteps : any[] = [
  {
    id: 'step_1',
    buttons: [
      {
        classes: 'shepherd-button',
        text: 'Start Tour',
        type: 'next'
      }
    ],
    cancelIcon: {
      enabled: true,
    },
    title: 'Welcome to Factor!',
    text: ['Helping your customers keep track of their orders is aboutto get a whole easier <br><br> Let\'s get started!'],
  },
  {
    id: 'step_2',
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Previous',
        type: 'back'
      },
      {
        classes: 'shepherd-button',
        text: 'Next',
        type: 'next'
      }
    ],
    cancelIcon: {
      enabled: true,
    },
    title: 'Selecting Items',
    text: [`<img src=${selectingItemsImg} style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);"> <br> To take an action, like confirming current dates or providing new dates for your customer, use the checkboxes to select items from the list.`],
  },
  {
    id: 'step_3',
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Previous',
        type: 'back'
      },
      {
        classes: 'shepherd-button',
        text: 'Next',
        type: 'next'
      }
    ],
    cancelIcon: {
      enabled: true,
    },
    title: 'Actions Menu',
    text: [`<img src=${actionsMenuImg} style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);"> <br> Once you select some items to update, use the Actions menu to edit or confirm dates.`],
  },
  {
    id: 'step_4',
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Previous',
        type: 'back'
      },
      {
        classes: 'shepherd-button',
        text: 'Done',
        type: 'cancel'
      }
    ],
    cancelIcon: {
      enabled: true,
    },
    title: 'Needs Update',
    text: [`<img src=${needsUpdateImg} style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);"> <br> If you haven’t made an update in a while, Factor will ask you for updates on tracked items. These items display as “Needs update”.<br><br> Provide updates on these items ASAP to make sure your customer has the most up-to-date information.`],
  },
];

export const updateTourOptions = {
  defaultStepOptions: {
    cancelIcon: {
        enabled: true,
    }
  },
  useModalOverlay: true
};