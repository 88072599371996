import clsx from 'clsx'
import { type FC, useEffect, useState } from 'react'

interface Option {
  label: string
  value: string
}
interface RadioButtonsProps {
  options: Option[]
  onChange: (value: string) => void
  selected: string
  sm?: boolean
  className?: string
}
export const RadioButtons: FC<RadioButtonsProps> = ({
  options,
  onChange,
  selected,
  sm = false,
  className,
}) => {
  const [value, setValue] = useState<string>(selected)

  useEffect(() => {
    setValue(selected)
  })

  const handleSelectedOption = (value: string): void => {
    setValue(value)
    onChange?.(value)
  }

  return (
    <div className={clsx('flex gap-3', className)} id='knownDatePicker'>
      {options?.map((option) => (
        <div
          key={option.label}
          className={clsx(
            'px-4 py-1.5 cursor-pointer rounded-md text-center w-1/2',
            { 'bg-offBlack-dark text-white': value === option.value },
            {
              'bg-white border border-offWhite-lightest':
                value !== option.value,
            },
            { 'text-xs': sm }
          )}
          onClick={() => {
            handleSelectedOption(option.value)
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  )
}
