import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'
import { apiItemGroupSlice } from '../itemgroup/ItemGroup'
import { type UpdateRequestPayloadWithParams } from 'types'

export const apiUpdateRequestSlice = createApi({
  reducerPath: 'updateRequestApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['UpdateRequests'],
  endpoints: (builder) => ({
    createUpdateRequest: builder.mutation({
      query: (request: UpdateRequestPayloadWithParams) => ({
        url: `/v1/UpdateRequests`,
        method: 'POST',
        body: request.payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted({ searchParams }, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const { data: updateRequest } = await queryFulfilled
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroups',
              null,
              (draft) => {
                draft.lastUpdateRequests[updateRequest.itemGroupSid] =
                  updateRequest
              }
            )
          )
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsTrack',
              searchParams,
              (draft) => {
                draft.lastUpdateRequests[updateRequest.itemGroupSid] =
                  updateRequest
              }
            )
          )
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsUpdate',
              null,
              (draft) => {
                draft.lastUpdateRequests[updateRequest.itemGroupSid] =
                  updateRequest
              }
            )
          )
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
    }),
  }),
})

export const { useCreateUpdateRequestMutation } = apiUpdateRequestSlice
