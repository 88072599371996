import { 
    type ItemGroupWithRelations, 
    type BulkUpdateWithParams,
} from 'types'
import dayjs from 'dayjs'

export const confirmItemGroupDates = async (itemGroupsWithRelations: ItemGroupWithRelations[], selectedDetail: string[], bulkUpdateItemGroups: (params: BulkUpdateWithParams) => void): Promise<void> => {

    const selectedItemGroups = itemGroupsWithRelations.filter(
        (item: ItemGroupWithRelations) => selectedDetail.includes(item.sid)
    )

    const itemGroups = selectedItemGroups.map((itemgroup: any) => {
        return {
        ...itemgroup,
        lastUpdateDate: dayjs.utc(Date.now()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        dueDate:
            itemgroup.dueDate !== 'Unknown'
            ? dayjs(itemgroup.dueDate).toISOString()
            : null,
        shipDate:
            itemgroup.shipDate !== 'Unknown'
            ? dayjs(itemgroup.shipDate).toISOString()
            : null,
        deliveryDate:
            itemgroup.deliveryDate !== 'Unknown'
            ? dayjs(itemgroup.deliveryDate).toISOString()
            : null,
        confirmed: true,
        }
    })

    try {
        bulkUpdateItemGroups({ itemGroups, searchParams: null })
    } catch (e) {
        console.log(e)
    }
}