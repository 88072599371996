import { type FC, createContext, useState} from "react";

export interface ShowConfirmationModalContextInterface {
    orderConfirmed: string | null
    setOrderConfirmed:(c: string | null) => void
  }
export const ShowConfirmationModalContext = createContext<ShowConfirmationModalContextInterface>({
  orderConfirmed: null,
  setOrderConfirmed: () => {},
  })

export interface ShowConfirmationModalInterface {
    children: React.ReactNode
}


export const ShowConfirmationModal: FC<ShowConfirmationModalInterface> = ({children}) : JSX.Element => {
    const [orderConfirmed, setOrderConfirmed] = useState<string | null>(null)
    return (
        <ShowConfirmationModalContext.Provider value={{orderConfirmed, setOrderConfirmed}}>
            {children}
        </ShowConfirmationModalContext.Provider>
    )
}