import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { type FC, Fragment, type ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { buyerNav, vendorNav } from 'utils/nav'

import { Sidebar } from 'components/Sidebar/Sidebar'

import useDownloadFile from '../../hooks/useDownloadFile/useDownloadFile'
import * as paths from '../../routing/paths'
import { useLazyGetFileExportQuery } from '../../services/fileExports/FileExports'
import { useAppDispatch } from '../../store'
import { setFileStatus } from '../../store/downloadCSV'
import { openNotification } from '../../store/notifications'
import {
  downloadCSVSelector,
  notificationSelector,
  sessionSelector,
  slideOverSelector,
} from '../../store/selectors'
import { type FileExport, type INavItem } from '../../types'
import { DownloadCSV } from '../DownloadCSV/DownloadCSV'
import { FieldMappingSlideover } from '../FieldMappingSlideover/FieldMappingSlideover'
import { ItemActivity } from '../ItemActivity/ItemActivity'
import { Notification } from '../Notification/Notification'
import { QualitySlideover } from '../QualitySlideover/QualitySlideover'
import { RequestUpdates } from '../RequestUpdates/RequestUpdates'
import { UpdateOrderItem } from '../UpdateOrderItem/UpdateOrderItem'
import { UpdateOrderMembers } from '../UpdateOrderMembers/UpdateOrderMembers'
import { UploadCTBData } from '../UploadCTBData/UploadCTBData'
import { UploadItems } from '../UploadItems/UploadItems'
import {AddShipment} from "../UpdateOrderItem/AddShipment";

interface LayoutProps {
  children?: ReactNode
}

const slideOvers: any = {
  UpdateOrderItem: <UpdateOrderItem />,
  RequestUpdate: <RequestUpdates />,
  UploadItems: <UploadItems />,
  ItemActivity: <ItemActivity />,
  DownloadCSV: <DownloadCSV />,
  UploadCTBData: <UploadCTBData />,
  FieldMapping: <FieldMappingSlideover />,
  UpdateOrderMembers: <UpdateOrderMembers />,
  QualityIssue: <QualitySlideover />,
  AddShipment: <AddShipment />,
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const session = useSelector(sessionSelector)
  const notification = useSelector(notificationSelector)
  const nav = session.userType === 'buyer' ? buyerNav : vendorNav
  const { data, name } = useSelector(slideOverSelector)
  const { fileSid } = useSelector(downloadCSVSelector)
  const [getFileExport] = useLazyGetFileExportQuery()
  const dispatch = useAppDispatch()
  useDownloadFile()

  async function sleep(ms: number): Promise<any> {
    return await new Promise((resolve) => setTimeout(resolve, ms))
  }

  const refetchUntilFinished = async (): Promise<void> => {
    if (fileSid === null || fileSid === undefined) return
    const { status } = (await getFileExport(fileSid).unwrap()) as FileExport
    dispatch(setFileStatus({ fileStatus: status }))
    await sleep(200)
    if (status === 'waiting' || status === 'creating') {
      dispatch(setFileStatus({ fileStatus: status }))
      await refetchUntilFinished()
    }
  }

  useEffect(() => {
    ;(async () => {
      if (fileSid !== null) {
        dispatch(
          openNotification({
            title: 'Download queued',
            message:
              'We’re generating your CSV now and will automatically download it when it’s complete. Please do not close your browser.',
            type: 'success',
            show: true,
          })
        )
        await refetchUntilFinished()
      }
    })()
  }, [fileSid])

  const getNav = (): INavItem[] => {
    let featureBasedNav = [] as INavItem[]
    if (session.features?.includes('clear_to_build')) {
      featureBasedNav = [
        ...featureBasedNav,
        {
          url: `${paths.PLAN}?filter=all`,
          label: 'Plan',
          icon: <FontAwesomeIcon icon={light('crystal-ball')} />,
        },
      ]
    }
    if (session.features?.includes('order_tracking')) {
      featureBasedNav = [...featureBasedNav, ...nav]
    }
    // Add Settings tab at the end
    featureBasedNav = [
      ...featureBasedNav,
      {
        url: paths.SETTINGS,
        label: 'Settings',
        icon: <FontAwesomeIcon icon={light('gear')} />,
      },
    ]
    return featureBasedNav
  }

  return (
    <Fragment>
      <Notification {...notification} />
      <div className="flex min-h-screen">
        <Sidebar navItems={getNav()} />
        <div className="grow bg-offWhite-light">{children}</div>
      </div>
      {typeof data !== 'undefined' && data !== null && name !== null ? (
        <>{slideOvers[name]}</>
      ) : null}
    </Fragment>
  )
}
