import { type ItemGroupWithRelations } from '../../types'
import _ from 'lodash'

export interface FormattedLabels {
  vendor: string
  ordersNumber: number
  recipients: Array<{ name: string }>
  itemGroupsNumber: number
}

export type OrderMembers = Record<string, Array<{ name: string }>>

export const formatUpdateRequestItemGroups = (
  itemGroups: ItemGroupWithRelations[] | null,
  orderMembers?: OrderMembers
): FormattedLabels[] => {
  if (itemGroups === null || orderMembers === undefined) {
    return [
      {
        vendor: '',
        ordersNumber: 0,
        itemGroupsNumber: 0,
        recipients: [{ name: '' }],
      },
    ]
  }
  const groupedByVendorName = _.groupBy(
    itemGroups,
    (ig: ItemGroupWithRelations) => {
      return ig.vendor.name
    }
  )
  const groupedByVendorAndOrder = Object.entries(groupedByVendorName).map(
    ([vendorName, itemGroups]) => {
      const groupedByOrder = _.groupBy(
        itemGroups,
        (ig: ItemGroupWithRelations) => {
          return ig.order?.sid
        }
      )
      return {
        [vendorName]: groupedByOrder,
      }
    }
  )
  return groupedByVendorAndOrder.flatMap((vendorData) => {
    return Object.entries(vendorData).map(([vendorName, orderGrouped]) => {
      const totalVendorIgs = Object.values(orderGrouped).flatMap(
        (ig) => ig
      ).length
      const recipients = Object.keys(orderGrouped).flatMap(
        (orderSid) => orderMembers[orderSid]
      )

      return {
        vendor: vendorName,
        recipients,
        ordersNumber: Object.values(orderGrouped).length,
        itemGroupsNumber: totalVendorIgs,
      }
    })
  })
}
