import { useGetReportsQuery } from '../../services/reports/Reports'
import { type FC } from 'react'

export const Reports: FC = (): JSX.Element => {
  const { data: iframeUrl, isLoading } = useGetReportsQuery(463)
  if (isLoading) return <div>Loading...</div>
  return <iframe src={iframeUrl} width={'100%'} height={'1000'} />
}

export default Reports
