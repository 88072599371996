import { type ItemGroupWithRelations } from '../../types'

export const itemGroupStage = (
  itemGroup: ItemGroupWithRelations
): 'empty' | 'success' | 'warning' => {
  if (itemGroup.delivered) {
    return 'success'
  } else if (itemGroup.shipped) {
    return 'warning'
  } else {
    return 'empty'
  }
}
