import dayjs from 'dayjs';
import { useCallback } from 'react';
import { type SearchParamsState } from 'store/searchParameters';
import { getDateOrDefault } from 'utils/formatDatesForRender/formatDatesForRender';
import { useBulkUpdateItemGroupsMutation, useCreateItemGroupMutation, useCreateMessageForItemUpdateMutation, useDeleteItemGroupsMutation, useDeleteItemMutation, useLazyGetItemGroupByItemIdQuery, useUpdateItemGroupMutation, useUpdateItemMutation } from 'services/orders/Orders';
import { type ItemGroup, type ItemGroupBulkUpdate, type ItemGroupCreate, type ItemGroupWithRelations, type UpdateItemGroupPayload } from 'types';
import { type Role, type UpdateItemFormValues } from '../utils/types';

interface UseHandlersHook {
  initialValues: UpdateItemFormValues | null;
  submitUpdate: (data: UpdateItemFormValues | UpdateItemFormValues[], onSuccess: () => void, searchParams: SearchParamsState | null) => void;
  submitCreate: (data: ItemGroupCreate[], onSuccess: () => void) => void;
  deleteItemGroup: (sid: string, itemSid: string, onSuccess?: () => void) => void;
  cancelItemGroup: (sid: string) => void;
  isLoading: boolean;
  error: any;
  isSingleItem: boolean;
}

export const useHandlers = (itemGroups: ItemGroupWithRelations[], role: Role, isSingleItem = false): UseHandlersHook => {
  const [updateItemGroup, { isLoading: updateLoading, error: updateError }] = useUpdateItemGroupMutation()
  const [updateItem, {isLoading: updateItemLoading, error: updateItemError }] = useUpdateItemMutation()
  const [createMessage, { isLoading: bulkUpdateLoading, error: bulkUpdateError }] = useCreateMessageForItemUpdateMutation()
  const [bulkUpdateItemGroups,  { isLoading: createMessageLoading, error: createMessageError }] = useBulkUpdateItemGroupsMutation()
  const [createItemGroup, { isLoading: createLoading, error: createError }] = useCreateItemGroupMutation()
  const [deleteItem, { isLoading: deleteItemLoading, error: deleteItemError }] = useDeleteItemMutation()
  const [getItemGroupByItemId] = useLazyGetItemGroupByItemIdQuery()
  const [deleteItemGroup, { isLoading: deleteItemGroupLoading, error: deleteItemGroupError }] = useDeleteItemGroupsMutation()

  /**
   * @constant valuesForSingleGroup
   * @description Initial values for Single Group and Multiple Groups from Single Item
   */
  const valuesForSingleGroup = {
        sid: itemGroups?.length === 0 ? null : itemGroups[0]?.sid,
        itemSid: itemGroups?.length === 0 ? null : itemGroups[0].itemSid,
        title: itemGroups?.length === 0 ? null : itemGroups[0]?.item?.title,
        uniqueName: itemGroups?.length === 0 ? null : itemGroups[0]?.item?.uniqueName,
        trackingNumber: itemGroups?.length === 0 ? null : itemGroups[0]?.trackingNumber,
        dueDate: itemGroups?.length === 0 ? null : getDateOrDefault(itemGroups[0]?.dueDate, 'Unknown'),
        shipped: itemGroups?.length === 0 ? null : itemGroups[0]?.shipped === undefined ? false : itemGroups[0]?.shipped,
        shipDate:
          itemGroups?.length === 0 ? null :
            (itemGroups[0]?.shipDate == null || itemGroups[0]?.shipDate === 'Unknown')
            ? null
            : dayjs(itemGroups[0].shipDate).format('MM/DD/YY'),
        delivered:
            itemGroups?.length === 0 ? null : itemGroups[0]?.delivered === undefined ? false : itemGroups[0].delivered,
        deliveryDate:
          itemGroups?.length === 0 ? null :
            (itemGroups[0]?.deliveryDate == null || itemGroups[0]?.deliveryDate === 'Unknown')
            ? null
            : dayjs(itemGroups[0].deliveryDate).format('MM/DD/YY'),
        notes: '',
        quantity: itemGroups?.length === 0 ? null : itemGroups[0]?.quantity ?? 0,
  };


  /**
   * @constant valuesForMultipleGroups
   * @description Initial values for Multiple Groups from Multiple Items
   */
  const valuesForMultipleGroups =  {
      dueDate: 'Multiple',
      shipped: false,
      title: itemGroups?.length === 0 ? null : itemGroups[0]?.item?.title,
      uniqueName: itemGroups?.length === 0 ? null : itemGroups[0]?.item?.uniqueName,
      shipDate: 'Multiple',
      delivered: false,
      deliveryDate: 'Multiple',
      notes: '',
  }


  /**
   * @fynction getDateOrOriginalValue
   * @description Util function to get dates
   */
  const getDateOrOriginalValue = useCallback((fieldName: 'shipDate' | 'deliveryDate' | 'dueDate', itemGroup: ItemGroup | UpdateItemFormValues, value: string | null) => {
    if (value === 'Multiple') {
      const date = dayjs(itemGroup[fieldName], 'MM/DD/YY')
      return itemGroup[fieldName] === 'Unknown' || !date.isValid() ? null : date.toISOString()
    } else if (value === null) {
      return null
    } else {
      const date = dayjs(value)
      return date.isValid() ? date.toISOString() : null
    }
  }, [])


  /**
   * @function submitSingleItemBulkUpdate
   * @description Submit function for Multiple Groups from Single Item
   */
  const submitSingleItemBulkUpdate = useCallback(async (data: UpdateItemFormValues[], onSuccess: () => void, searchParams: SearchParamsState | null): Promise<void> => {
    const bulkUpdatedItemGroups: ItemGroupBulkUpdate[] = data?.map((itemGroup: UpdateItemFormValues) => {
      const updatedItemGroup = {
        sid: itemGroup.sid as string,
        shipDate: getDateOrOriginalValue('shipDate', itemGroup, itemGroup.shipDate) !== null ? dayjs(itemGroup.shipDate).toISOString() : null,
        deliveryDate:
          getDateOrOriginalValue('deliveryDate', itemGroup, itemGroup.deliveryDate) !== null ? dayjs(itemGroup.deliveryDate).toISOString() : null,
        shipped: itemGroup.shipped,
        trackingNumber: itemGroup.trackingNumber,
        delivered: itemGroup.delivered,
        quantity: itemGroup.quantity
      } as UpdateItemFormValues

      if (role === 'buyer') {
        updatedItemGroup.dueDate = getDateOrOriginalValue('dueDate', itemGroup, itemGroup.dueDate) !== null ? dayjs(itemGroup.dueDate).toISOString() : null
      }

      return updatedItemGroup as unknown as ItemGroupBulkUpdate;
    })

    const itemPayload = {
      title: data[0].title ?? undefined,
      uniqueName: data[0].uniqueName ?? undefined,
    }

    try {
      await bulkUpdateItemGroups({ itemGroups: bulkUpdatedItemGroups, searchParams })
      await updateItem({ sid: data[0].itemSid, ...itemPayload })
      const itemGroupSids = data?.map((ig: UpdateItemFormValues) => ig.sid as unknown as string)
      const notes = data?.map((ig: UpdateItemFormValues) => ig.notes)
      const newMessage = {
        content: notes[0],
        itemGroupSids,
      }
      newMessage?.content && await createMessage(newMessage)
      onSuccess?.()
    } catch (e: any) {
      console.log('Error on handleUpdate', e)
    }

  }, [updateItemGroup, role, createMessage]);

  /**
   * @function submitSingleGroupUpdate
   * @description Submit function for Single Group
   */
  const submitSingleGroupUpdate = useCallback(async (data: UpdateItemFormValues, onSuccess: () => void): Promise<void> => {
    const {
      sid,
      shipped,
      dueDate,
      shipDate,
      delivered,
      deliveryDate,
      notes,
      quantity,
      trackingNumber,
    } = data

    const { title, uniqueName} = data

    const updatedItemGroup: UpdateItemGroupPayload = {
      sid: sid as string,
      shipDate: shipDate !== null ? dayjs(shipDate).toISOString() : null,
      deliveryDate:
        deliveryDate !== null ? dayjs(deliveryDate).toISOString() : null,
      shipped,
      trackingNumber,
      delivered,
      quantity
    }

    const updateItemPayload = {
      title,
      uniqueName,
    }

    if (role === 'buyer') {
      updatedItemGroup.dueDate = dueDate != null ? dayjs(dueDate).toISOString() : null
    }

    try {
      await updateItemGroup(updatedItemGroup)
      if (notes?.length > 0) {
        const newMessage = {
          content: notes,
          itemGroupSids: [sid as unknown as string],
        }
        newMessage?.content && await createMessage(newMessage)
      }
      await updateItem({ sid: data.itemSid, ...updateItemPayload })
      onSuccess?.()
    } catch (e: any) {
      console.log('Error on handleUpdate', e)
    }
  },  [updateItemGroup, role, createMessage]);

  /**
   * @function submitMultipleGroupUpdate
   * @description Submit function for Multiple Groups from Multiple Items
   */
  const submitMultipleGroupUpdate = useCallback(async (data: UpdateItemFormValues, onSuccess: () => void, searchParams: SearchParamsState | null): Promise<void> => {
    const {
      shipped,
      dueDate,
      shipDate,
      delivered,
      deliveryDate,
      notes
    } = data
    const bulkUpdatedItemGroups: ItemGroupBulkUpdate[] = itemGroups?.map((itemGroup: ItemGroup) => {
      const itemGroupBulkUpdate: ItemGroupBulkUpdate = {
        sid: itemGroup.sid,
        shipDate: getDateOrOriginalValue('shipDate', itemGroup, shipDate),
        deliveryDate: getDateOrOriginalValue('deliveryDate', itemGroup, deliveryDate),
        shipped,
        delivered,
      }

      if (role === 'buyer') {
        itemGroupBulkUpdate.dueDate = getDateOrOriginalValue('dueDate', itemGroup, dueDate)
      }

      return itemGroupBulkUpdate
    })

    try {
      await bulkUpdateItemGroups({ itemGroups: bulkUpdatedItemGroups, searchParams })
      if (notes.length > 0) {
        const itemGroupSids = itemGroups?.map((ig: ItemGroup) => ig.sid)
        const newMessage = {
          content: notes,
          itemGroupSids,
        }
        newMessage?.content && await createMessage(newMessage)
      }
      onSuccess?.()
    } catch (e: any) {
      console.log('Error on handleUpdate', e)
    }
  }, [bulkUpdateItemGroups, role, createMessage])

  /**
   * @function submitCreateItemGroup
   * @description Submit function for Creating Item Group to Single Item
   */
  const submitCreateItemGroup = useCallback(async (data: ItemGroup[], onSuccess: () => void): Promise<void> => {
    try {
      await Promise.all(data.map(async (itemGroup: ItemGroup) => {
        const { sid, uniqueName, title, ...dataWithoutSid } = itemGroup;

        createItemGroup({
          ...dataWithoutSid,
          dueDate: getDateOrOriginalValue('dueDate', itemGroup, itemGroup.dueDate) !== null ? dayjs(itemGroup.dueDate).toISOString() : undefined,
          shipDate: getDateOrOriginalValue('shipDate', itemGroup, itemGroup.shipDate) !== null ? dayjs(itemGroup.shipDate).toISOString() : null,
          deliveryDate:
            getDateOrOriginalValue('deliveryDate', itemGroup, itemGroup.deliveryDate) !== null ? dayjs(itemGroup.deliveryDate).toISOString() : null
        })
      }))
      onSuccess?.()
    } catch(e) {
      console.log('Error on create Item group', e)
    }
  }, [bulkUpdateItemGroups, role, createMessage])

  /**
   * @function updateItemGroup
   * @description Submit function for Update Item Group
   */
  const submitCancelItemGroup = useCallback(async (itemGroupSid: string, onSuccess: () => void): Promise<void> => {
    try {
      updateItemGroup({
        sid: itemGroupSid,
        status: 'canceled'
      })
      onSuccess?.()
    } catch(e) {
      console.log('Error on canceling Item group', e)
    }
  }, [bulkUpdateItemGroups, role, createMessage])

  const submitDeleteItemGroup = useCallback(async (itemGroupSid: string, itemSid: string, onSuccess: () => void): Promise<void> => {
    try {
      const { itemGroups } = await getItemGroupByItemId(itemSid).unwrap()
      await deleteItemGroup(itemGroupSid).unwrap()
      if (itemGroups.length === 1) {
        await deleteItem(itemSid).unwrap()
        onSuccess?.()
      }
    } catch(e) {
      console.log('Error on deliting Item group', e)
    }
  }, [bulkUpdateItemGroups, role, createMessage])

  /**
   * Return the handlers
   * @return {UseHandlersHook}
   */
  return {
    initialValues: itemGroups?.length === 1 || isSingleItem ? valuesForSingleGroup : valuesForMultipleGroups,
    submitUpdate: isSingleItem ? submitSingleItemBulkUpdate : itemGroups?.length === 1 ? submitSingleGroupUpdate : submitMultipleGroupUpdate,
    submitCreate: submitCreateItemGroup,
    cancelItemGroup: submitCancelItemGroup,
    deleteItemGroup: submitDeleteItemGroup,
    isLoading: updateLoading || bulkUpdateLoading || createMessageLoading || createLoading || updateItemLoading || deleteItemGroupLoading || deleteItemLoading,
    error: updateError ?? bulkUpdateError ?? createMessageError ?? createError ?? updateItemError ?? deleteItemGroupError ?? deleteItemError,
  } as unknown as UseHandlersHook
}
