import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'
import { type UpdateUser } from "../../types";

export const apiUpdateUserSlice = createApi({
  reducerPath: 'updateUserApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['updateUser'],
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: (body: UpdateUser) => ({
        url: `/v1/Users/${body.sid}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'AKh7qhk7m9kfbd377m3y8jqg4ft4qh'
        },
        body,
      }),
    }),
  }),
})

export const {
  useUpdateUserMutation
} = apiUpdateUserSlice
