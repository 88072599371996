import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import { Tooltip } from 'components/Tooltip/Tooltip'

import type BuyerRow from '../ItemsTable/BuyerRow/BuyerRow'
import type VendorRow from '../ItemsTable/VendorRow/VendorRow'

interface TableHeader {
  name: string
  onClick?: () => void
  children?: Array<{
    label: string
    startSection: boolean
    mobile?: boolean
    tablet?: boolean
  }>
  mobile?: boolean
  tablet?: boolean
  sortedDirection?: 'DESC' | 'ASC' | null
}

export interface TableProps {
  data: any[] | Array<typeof BuyerRow> | Array<typeof VendorRow> | null
  rowItem?: typeof BuyerRow | typeof VendorRow
  headers?: TableHeader[]
  onSelect?: (item: any) => void
  onSort?: (sortBy: string | null, sortDirection: 'DESC' | 'ASC' | null) => void
  hide?: string[]
  selected?: Set<string>
  onClick?: (
    open: boolean,
    itemName: string,
    itemSid: string,
    itemGroupSid: string
  ) => void
  canSelect?: boolean
  handleSelectAll?: () => any
}

export const Table = ({
  data,
  rowItem: RowItem,
  headers,
  selected = new Set([]),
  onSelect,
  onSort,
  hide = [],
  onClick,
  canSelect = true,
  handleSelectAll = () => {},
}: TableProps): JSX.Element => {
  const [sortDirection, setSortDirection] = useState<'DESC' | 'ASC' | null>(
    null
  )
  const [sortBy, setSortBy] = useState<string | null>(null)
  const flagColumnRef = useRef(null)

  const headerChildren = headers
    ?.filter((header) => header.children)
    .flatMap((header) => header.children)

  const handleSort = (property: string | null): void => {
    if (sortBy !== property) {
      setSortBy(property)
      setSortDirection('DESC')
      onSort?.(property, 'DESC')
      return
    }

    const newSortDirection =
      sortDirection === 'DESC' ? 'ASC' : sortDirection === 'ASC' ? null : 'DESC'
    setSortBy(newSortDirection === null ? null : property)
    setSortDirection(newSortDirection)
    onSort?.(property, newSortDirection)
  }

  return (
    <div>
      <div className="inline-block min-w-full py-2 align-middle">
        <table className="min-w-full divide-y divide-offWhite-mid border-spacing-y-1 overflow-hidden">
          <thead>
            <tr role="list">
              {headers?.map((header: TableHeader, index: number) => {
                const colspan = header.children != null ? 'colgroup' : undefined
                const rowGroup = header.children != null ? undefined : 3
                const flagColumn = header.name === 'flag'

                return (
                  <th
                    ref={flagColumn ? flagColumnRef : undefined}
                    className={clsx(
                      'cursor-pointer py-3.5 text-left text-sm font-semibold text-offBlack-mid text-xs font-mono pb-3',
                      {
                        'pl-1 py-0 pb-0 border-l border-solid pl-3 capitalize':
                          header.children != null,
                        'table-cell': header?.mobile === true,
                        'hidden md:table-cell': header?.tablet === true,
                        'hidden xl:table-cell': header?.mobile !== true,
                      },
                      {
                        uppercase:
                          header.children === null ||
                          header.children === undefined,
                      },
                      {
                        'pl-5 sm:pl-7': index === 0,
                      }
                    )}
                    scope={colspan}
                    rowSpan={rowGroup}
                    colSpan={header.children?.length}
                    key={`th-${index}`}
                    onClick={() => {
                      if (header.onClick !== undefined) {
                        header.onClick()
                      } else {
                        header.children == null && handleSort(header.name)
                      }
                    }}
                  >
                    {flagColumn && (
                      <Tooltip position="top" ref={flagColumnRef}>
                        <p className="normal-case font-sans">
                          Flags show you items that Factor thinks may need your
                          attention
                        </p>
                      </Tooltip>
                    )}
                    <>
                      {index === 0 && canSelect && (
                        <input
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSelectAll()
                          }}
                          data-testid="select-all-checkbox"
                          className="mr-2 border-offBlack-light outline-0 rounded"
                          type="checkbox"
                        />
                      )}
                      {header.name}

                      <span className="ml-1 w-[10px] inline-block">
                        {header.sortedDirection !== null &&
                          header.children !== null &&
                          sortBy === header.name &&
                          (sortDirection === 'DESC' ? (
                            <FontAwesomeIcon icon={light('arrow-down')} />
                          ) : (
                            <FontAwesomeIcon icon={light('arrow-up')} />
                          ))}
                        {header.sortedDirection !== null &&
                          header.sortedDirection !== undefined &&
                          (header.sortedDirection === 'DESC' ? (
                            <FontAwesomeIcon icon={light('arrow-down')} />
                          ) : (
                            <FontAwesomeIcon icon={light('arrow-up')} />
                          ))}
                      </span>
                    </>
                  </th>
                )
              })}
            </tr>
            <tr>
              {headerChildren?.map(
                (
                  header:
                    | {
                        label: string
                        startSection: boolean
                        mobile?: boolean
                        tablet?: boolean
                      }
                    | undefined,
                  index: number
                ) => (
                  <th
                    className={clsx(
                      'cursor-pointer py-3.5 text-left text-sm font-semibold text-offBlack-mid uppercase text-xs font-mono pb-3 xl:pt-1',
                      {
                        'xl:border-l xl:border-solid pl-3':
                          header?.startSection,
                        'table-cell': header?.mobile === true,
                        'hidden md:table-cell': header?.tablet === true,
                        'hidden xl:table-cell': header?.mobile !== true,
                      }
                    )}
                    key={`multi-group-${index}`}
                    onClick={() => {
                      header != null && handleSort(header.label)
                    }}
                  >
                    <>
                      {header?.label}
                      {sortBy === header?.label && (
                        <span className="ml-1">
                          {sortDirection === 'DESC' ? (
                            <FontAwesomeIcon icon={light('arrow-down')} />
                          ) : (
                            <FontAwesomeIcon icon={light('arrow-up')} />
                          )}
                        </span>
                      )}
                    </>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-offWhite-mid pl-5">
            {data?.map((itemData, index) => {
              return RowItem != null ? (
                <RowItem
                  onClick={onClick}
                  key={`row-item-${index}`}
                  data={itemData}
                  index={index}
                  onSelect={onSelect}
                  isCheck={selected.has(itemData.sid)}
                  hide={hide}
                />
              ) : (
                <tr
                  key={`tr-${index}`}
                  className={index % 2 === 0 ? 'bg-white' : 'bg-offWhite-light'}
                >
                  {headers?.map((header, colIndex) => (
                    <td
                      key={`td-${header.name}-${colIndex}`}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 md:pl-0"
                    >
                      {colIndex === 0 && (
                        <input
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          className="mr-2 border-offBlack-light outline-0 rounded"
                          type="checkbox"
                        />
                      )}
                      <span className="align-middle">
                        {itemData[header.name]}
                      </span>
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
