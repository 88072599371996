import { type FC, type ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Select } from '../Select/Select'

interface Action {
  name: string
  onClick?: () => void
  icon?: ReactNode
  disabled?: boolean
  testId?: string
}

export interface ButtonProps {
  actions: Action[]
}

function checkAllDisabled(actions: Array<{
  name: string
  onClick?: () => void
  icon?: ReactNode
  disabled?: boolean
}>): boolean {
  let amountActionDisabled = 0;
  actions.forEach(function (action) {
    if(action.disabled ?? false){
      amountActionDisabled += 1;
    }
  })
  return amountActionDisabled === actions.length
}

const DisabledTrigger: FC = () => {
  return ( 
    <div  
      className="bg-white border border-offWhite-mid font-semibold text-offBlack-dark rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
    >
      Actions
      <FontAwesomeIcon
        className="ml-2 text-offBlack-dark text-lg"
        icon={light('ellipsis-v')}
      />
    </div>
  )
}

const ActiveTrigger: FC = () => {
  return (
    <div  
      className="bg-teal-dark border border-offwhite-light font-semibold text-offWhite-light rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
    >
      Actions
      <FontAwesomeIcon
        className="ml-2 text-offWhite-light text-lg font-bold"
        icon={light('ellipsis-v')}
      />
    </div>
  )
}

export const ActionMenu = ({ actions }: ButtonProps): JSX.Element => {
  const allDisabled = checkAllDisabled(actions)
  const Trigger = allDisabled ? DisabledTrigger : ActiveTrigger
  return (
    <Select
      triggerComponent={Trigger}
      options={actions}
      disabled={allDisabled}
      disabledMessage='Select items from the list to make an update or confirm dates '
    />
  )
}
