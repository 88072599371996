import { Bullet } from './Bullet'
import { type FC } from 'react'
import { type FactorEvent, type TimelineEventProps } from './types'
import dayjs from 'dayjs'
import { Message } from './Message'
import { ItemGroupUpdateEvent, OrderInviteCreateEvent, InboundEmailRelationsCreateEvent } from "../../utils/timelineEvent/timelineEvent";

export const TimelineEvent: FC<TimelineEventProps> = ({
  event: eventData,
  first = false,
  itemName,
  selectedItemGroups = [],
  role = 'buyer',
}) => {
  const { event, eventTime } = eventData
  const timelineEvent = event as FactorEvent

  const getUserName = (): string => {
    if (timelineEvent.eventType === ItemGroupUpdateEvent) {
      return timelineEvent?.updatedBy?.human?.name ?? 'Factor'
    } else if (timelineEvent.EventType === OrderInviteCreateEvent) {
      return timelineEvent?.CreatedBy_Human_Name ?? 'Factor'
    } else if (timelineEvent.EventType === InboundEmailRelationsCreateEvent) {
      return timelineEvent?.fromEmail ?? 'Factor'
    }
    return timelineEvent?.createdBy?.human?.name ?? 'Factor'
  }

  return (
    <li className="flex items-start" key={`timelineEvent-${timelineEvent.id}`}>
      <Bullet first={first} />
      <div className="mb-5 justify-between w-full">
        <div className="text-sm">
          <span className="font-bold">{getUserName()}</span>{' '}
          <span className="text-gray-700">
            <Message data={eventData} itemName={itemName} role={role} selectedItemGroups={selectedItemGroups} />
          </span>
        </div>
        <p className="flex flex-col item-start text-[13px] font-mono text-offBlack-light">
          {dayjs(eventTime).format('MM/DD/YYYY | HH:mm A')}
        </p>
      </div>
    </li>
  )
}
