import { createApi } from '@reduxjs/toolkit/query/react'

import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiBomsSlice = createApi({
  reducerPath: 'bomsApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['Boms', 'BomLines', 'BomUploads'],
  endpoints: (builder) => ({
    getBoms: builder.query({
      query: ({
        ctbLessThanEqualTo,
        pageToken,
      }: {
        ctbLessThanEqualTo?: number
        pageToken?: string
      }) => ({
        url: `/v1/Boms`,
        params: {
          ctbLessThanEqualTo,
          pageToken,
          pageSize: 12,
        },
      }),
      providesTags: ['Boms'],
    }),
    getBom: builder.query({
      query: (sid: string) => `/v1/Boms/${sid}`,
      providesTags: ['Boms'],
    }),
    getBomByUniqueName: builder.query({
      query: (uniqueName: string) => `/v1/Boms?uniqueName=${uniqueName}`,
      providesTags: ['Boms'],
    }),
    getBomLines: builder.query({
      query: () => `/v1/BomLines`,
      providesTags: ['BomLines'],
    }),
    getBomLinesByBomSid: builder.query({
      query: ({
        bomSid,
        inventoryCountLessThanEqualTo,
        orderedQuantityLessThanEqualTo,
        orderBy,
        sortOrder,
      }: {
        bomSid: string
        inventoryCountLessThanEqualTo?: number
        orderedQuantityLessThanEqualTo?: number
        orderBy?: string
        sortOrder?: 'Asc' | 'Desc'
      }) => ({
        url: `/v1/BomLines`,
        params: {
          bomSid,
          inventoryCountLessThanEqualTo,
          orderedQuantityLessThanEqualTo,
          orderBy,
          sortOrder,
        },
      }),
      providesTags: ['BomLines'],
    }),
    uploadBOMData: builder.mutation({
      query: (body) => ({
        url: '/v1/BomUploads',
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
      invalidatesTags: ['Boms'],
    }),
    getBOMUpload: builder.query({
      query: (sid: string) => `/v1/BomUploads/${sid}`,
      providesTags: ['BomUploads'],
    }),
  }),
})

export const {
  useGetBomsQuery,
  useLazyGetBomsQuery,
  useGetBomQuery,
  useGetBomLinesQuery,
  useGetBomLinesByBomSidQuery,
  useUploadBOMDataMutation,
  useLazyGetBOMUploadQuery,
  useGetBomByUniqueNameQuery,
} = apiBomsSlice
