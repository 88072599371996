import { type ItemGroupWithRelations } from '../../types'
import dayjs from 'dayjs'

interface ItemStatus {
  status: 'On time' | 'Early' | 'Delayed' | 'Unknown'
  classes: 'text-green-dark' | 'text-yellow-dark' | 'text-offBlack-mid'
}

export const getStatusItemGroup = (
  itemGroup: ItemGroupWithRelations
): ItemStatus => {
  if (dayjs(itemGroup.deliveryDate).isSame(itemGroup.dueDate)) {
    return {
      status: 'On time',
      classes: 'text-green-dark',
    }
  } else if (dayjs(itemGroup.deliveryDate).isBefore(itemGroup.dueDate)) {
    return {
      status: 'Early',
      classes: 'text-green-dark',
    }
  } else if (dayjs(itemGroup.deliveryDate).isAfter(itemGroup.dueDate)) {
    return {
      status: 'Delayed',
      classes: 'text-yellow-dark',
    }
  } else {
    return {
      status: 'Unknown',
      classes: 'text-offBlack-mid',
    }
  }
}
