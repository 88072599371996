import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react'
import { type FC, Fragment, useEffect, useState } from 'react'

interface SuccessConfirmationProps {
  orderTitle: string
  onConfirm: () => void;
  onClose?: () => void;
}
export const SuccessConfirmation: FC<SuccessConfirmationProps> = ({ orderTitle, onClose, onConfirm}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(()=>{
      setTimeout(()=>{
        setIsOpen(true);
      }, 300)
  }, [])

  const closeModal = (): void => {
    setIsOpen(false)
    setTimeout(()=>{
      onClose?.()
    }, 300)
  }

  const handleConfirm = (): void => {
    onConfirm?.()
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-lg bg-white px-4 pt-10 pb-5 text-left align-middle shadow-xl transition-all">
                  <button
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={closeModal}
                    aria-label="Close"
                    data-testid="close-button"
                  >
                    <FontAwesomeIcon
                      icon={light('xmark')}
                      className="w-6 h-6 mr-2 mt-2"
                    />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-offBlack-dark"
                  >
                    {orderTitle} confirmed
                  </Dialog.Title>
                  <div className="mt-4 mb-5">
                    <p className="text-sm text-offBlack-dark">
                    Thanks for confirming this order! These items now will show in Factor as delivering on the due date.  If you have more information to provide, like ship dates, please update the items on the order now.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      data-testid="view-order"
                      type="button"
                      className="rounded-full inline-flex justify-center border border-offWhite-mid bg-teal-dark px-8 py-3 text-sm font-semibold text-offWhite-light"
                      onClick={handleConfirm}
                    >
                      View order
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
