export const downloadFile = (blob: Blob, fileName: string): void => {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', blobUrl)
  link.setAttribute('download', fileName)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
