import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { type FC, useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { BackLink } from 'components/BackLink/BackLink'
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { AvatarList, type OrderMemberAvatars } from 'components/AvatarList/AvatarList'
import { Subnav } from 'components/Subnav/Subnav'
import { Button, Headline } from 'components'
import {
  useCreateOrderMemberMutation,
  useGetOrderQuery,
  useLazyGetOrganizationQuery,
  useLazyGetUserOrderMemberQuery,
  useLazyGetVendorQuery,
} from 'services/orders/Orders'

import { Select } from '../../../components/Select/Select'
import { TopSection } from '../../../components/TopSection/TopSection'
import { useGetOrderInvitesQuery } from '../../../services/orderInvites/OrderInvites'
import { useGetOrderMembersWithCurrentUserQuery } from '../../../services/orderMembers/OrderMembers'
import { useGetLoggedInUserQuery } from '../../../services/user/User'
import { openNotification } from '../../../store/notifications'
import { openSlideOver } from 'store/slideOvers'
import { type User, type OrderInviteCreate } from 'types'
import clsx from 'clsx'
import { useConfirmItemGroupsMutation } from 'services/itemgroup/ItemGroup'
import { SuccessConfirmation } from 'components/Modals/SuccessConfirmation/SuccessConfirmation'

export const Order: FC = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { orderSid } = useParams()

  const paramOptOut = searchParams.get('fromOptOut') === 'true'
  const {
    data: order,
    isLoading: orderLoading,
    error,
  } = useGetOrderQuery(orderSid as string)
  const [getVendor, { data: vendor, isLoading: vendorLoading }] =
    useLazyGetVendorQuery()
  const [getOrganization, { data: organization, isLoading: orgLoading }] =
    useLazyGetOrganizationQuery()
  const { data: loggedInUser } = useGetLoggedInUserQuery(1)
  const { data: orderInvitesData } = useGetOrderInvitesQuery(orderSid as string)
  const { data: orderMembers, isLoading: isLoadingOrderMembers } =
    useGetOrderMembersWithCurrentUserQuery(`orderSids=${orderSid}`)
  const [getUserOrderMember] = useLazyGetUserOrderMemberQuery()

  const [createOrderMember] = useCreateOrderMemberMutation()
  const dispatch = useAppDispatch()
  const [isUserFollowingOrder, setIsUserFollowingOrder] = useState(false)
  const [confirmItemGroups, confirmItemGroupsResponse] = useConfirmItemGroupsMutation()
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const outboundEmailSid: null | string = searchParams.get('outboundEmailSid')
  const confirmItemGroupsQueryParam: null | string = searchParams.get('confirm')

  const orderError = error as { status: number }

  useEffect(() => {
    if (orderSid && loggedInUser?.sid) {
      const fetchAlOrderMembers = async (): Promise<void> => {
        const { data } = await getUserOrderMember({
          orderSid,
          memberSid: loggedInUser?.sid,
        })
        if (
          data?.orderMembers?.length > 0 &&
          data?.orderMembers.some(
            (orderMember: { memberSid: string }) =>
              orderMember.memberSid === loggedInUser?.sid
          )
        ) {
          setIsUserFollowingOrder(true)
        } else {
          setIsUserFollowingOrder(false)
        }
      }
      fetchAlOrderMembers()
    }
  }, [orderSid, loggedInUser?.sid, orderMembers])

  useEffect(() => {
    if (order?.organizationSid !== undefined) {
      getOrganization(order?.organizationSid as string)
      if (order?.vendorSid !== undefined && order?.vendorSid !== null) {
        getVendor(order?.vendorSid as string)
      }
    }
  }, [order])

  useEffect(() => {
    if (confirmItemGroupsQueryParam !== null && confirmItemGroupsQueryParam) {
      confirmItemGroups(outboundEmailSid)
    }
  }, [outboundEmailSid, confirmItemGroupsQueryParam])

  useEffect(() => {
    if (confirmItemGroupsResponse.isSuccess) {
      setShowConfirmationModal(true)
    }
  }, [confirmItemGroupsResponse])

  if (
    orderLoading ||
    isLoadingOrderMembers ||
    orderLoading ||
    orgLoading ||
    vendorLoading
  )
    return <div>Loading...</div>

  const colorSequence = ['#41c8a7', '#eca25b', '#0a7e7b']

  const role =
    order?.organizationSid === loggedInUser?.organizationSid
      ? 'buyer'
      : 'vendor'
  const isVendor = role === 'vendor'
  const backText = isVendor ? 'Update' : 'Track'
  const backPath = isVendor ? '/update' : '/track'

  if (orderError?.status === 404) {
    return (
      <div className="mt-[49vh] ml-[40vw] text-[25px]">Order Not Found</div>
    )
  }

  const tabs = [
    {
      active: !location.pathname?.includes('activity'),
      content: 'items',
      path: '',
    },
    {
      active: location.pathname?.includes('activity'),
      content: 'activity',
      path: 'activity',
    },
  ]

  const followingOrder: boolean = orderMembers?.[orderSid as string]?.some((orderMember: User) => orderMember?.sid === loggedInUser?.sid)

  const membersList: OrderMemberAvatars[] = orderMembers?.[orderSid as string]?.map(
    (member: User, index: number) => {
    const firstName: string = member.firstName ?? ' '
    const lastName: string  = member.lastName ?? ' '
      return {
        initials: firstName[0] + lastName[0],
        orgName: order.organizationSid === member.organizationSid ? organization?.name : order?.vendorName,
        email: member.email,
        memberType: member.organizationSid === order?.organizationSid ? 'Tracking' : 'Updating',
        color: colorSequence[index % colorSequence.length],
        border: colorSequence[index % colorSequence.length],
      }
    })

  const uniquePendingInvites: OrderInviteCreate[] = orderInvitesData?.orderInvites?.filter((member: { email: string }, index: number, self: Array<{ email: string }>) =>
    index === self.findIndex((m) => m.email === member.email)
  );

  const pendingInvitesList: OrderMemberAvatars[] = uniquePendingInvites?.map((member: OrderInviteCreate, index: number) => {
    const firstName: string = member.firstName ?? ""
    const lastName: string  = member.lastName ?? ""
    const initials: string = `${firstName[0] ?? ""}${lastName[0] ?? ""}`
    return {
      initials: initials === "" ? `${member.email[0]}${member.email[0]}` : initials,
      orgName: member.organizationName ?? "Pending",
      email: member.email,
      color: '#0d0d0d',
      border: colorSequence[index % colorSequence.length],
      memberType: 'Invited'
    }
  })

  const usersList = [...(membersList ?? []), ...(pendingInvitesList ?? [])]

  const handleFollowOrder = async (): Promise<void> => {
    try {
      const { sid: orderMemberSid } = (await createOrderMember({
        orderSid: orderSid as string,
        memberSid: loggedInUser?.sid as string,
      }).unwrap()) as { sid: string; orderSid: string; userSid: string }
      if (orderMemberSid) {
        dispatch(
          openNotification({
            title: 'You’ve been added to this order as a Tracking member',
            message:
              'You’ll receive notifications about this order’s status as updates are provided by Updating members.',
            type: 'success',
            show: true,
          })
        )
      }
    } catch (e) {
      dispatch(
        openNotification({
          title: 'Something went wrong',
          message:
            'We were not able to complete your order member change. Please try again or contact support@factor.io.',
          type: 'error',
          show: true,
        })
      )
    }
  }

  const handlerAddMembersButton = (removeCheckbox: boolean): void => {
    dispatch(
      openSlideOver({
        name: 'UpdateOrderMembers',
        data: {order, followingOrder, removeCheckbox},
      })
    )
  }

  if (paramOptOut) {
    handlerAddMembersButton(true)
  }

  const followingOptions = [
    {
      icon: <FontAwesomeIcon icon={light('circle-check')} />,
      name: 'You are following this order',
      disabled: true,
    },
    {
      icon: <FontAwesomeIcon icon={light('times-circle')} />,
      name: 'Remove me from this order',
      onClick: () => {handlerAddMembersButton(true)},
    } ,
    {
      icon: <FontAwesomeIcon icon={light('times-circle')} />,
      name: `Never notify me about any orders from ${organization?.name}`,
      disabled: true,
    },
  ]

  const FollowButton: FC = () => {
    return (
      <div className="flex justify-center font-semibold font-sans px-5 py-2 rounded-3xl text-sm shadow-sm antialised bg-teal-dark text-white mr-4">
        <span className="mr-3">Following this order</span>
        <FontAwesomeIcon icon={light('chevron-down')} />
      </div>
    );
  };
  

  return (
    <>
      <div className="min-h-screen bg-offWhite-light">
        <TopSection>
          <div>
            <div>
              <BackLink path={backPath}>{backText}</BackLink>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex">
                <Headline
                  className="text-offWhite-light my-5 self-end"
                  size="xl"
                >
                  {order.title ?? order.uniqueName ?? order.sid}
                  {/* <span className="text-2xl"> */}
                  {/*  <FontAwesomeIcon icon={light('arrow-down-to-line')} /> */}
                  {/* </span> */}
                </Headline>
                <div className="ml-7 flex flex-col ml-28">
                  <div className="text-offBlack-light mb-0 text-xs pt-3">
                    {isVendor ? 'BUYER' : 'VENDOR'}
                  </div>
                  <div className="text-offWhite-light text-2xl self-end flex">
                    <span>
                      {isVendor
                        ? organization?.name ?? order.organizationSid
                        : order?.vendorName ?? vendor?.name}
                    </span>
                  </div>
                </div>
              </div>
              {/* Hide it until there is a Request Update feature
              <div className="justify-z">
                <Button style="gradient">
                  <FontAwesomeIcon className="mr-2" icon={light('comments')} />
                  Request Update
                </Button>
              </div> */}
            </div>
            <div className="flex gap-4">
              {isLoadingOrderMembers ? (
                <div>Loading...</div>
              ) : usersList.length > 0 ? (
                <div className="flex">
                  <AvatarList users={usersList} />
                  <div className="ml-2 self-center">
                    <span className="text-offWhite-light">following</span>
                  </div>
                </div>
              ) : null}
              <div className='flex mb-2 pt-2'>
                {!isUserFollowingOrder ? (
                  <Button
                    style="black-outline"
                    id="followOrder"
                    className="mr-4"
                    onClick={handleFollowOrder}
                  >
                    <span>Follow this order</span>
                  </Button>
                ) : (
                  <Select
                    triggerComponent={FollowButton}
                    options={followingOptions}
                  />
                )}
                <Button
                  style='black-outline'
                  id="addOrderMembers"
                  onClick={() => {
                    handlerAddMembersButton(false)
                  }}
                >
                  <span>Add members</span>
                </Button>
              </div>
            </div>
            {usersList.length === 0 && (
                <div className="text-red-mid">
                  No order members added. Factor is not sending notifications
                  about this order.
                </div>
            )}
            <div className={clsx({"mt-[33px]": usersList.length !== 0},{"mt-[9px]": usersList.length === 0})}>
              <Subnav tabs={tabs} />
            </div>
          </div>
        </TopSection>
        <div className="pt-4">
          <Outlet context={[role]} />
        </div>
      </div>
      {showConfirmationModal && <SuccessConfirmation onConfirm={() => {}} orderTitle={order?.title ?? 'Order'} />}
    </>
  )
}

export default Order
