import { type FC, useEffect, type JSX } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuthWithCookie } from '../hooks'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { hotjar } from 'react-hotjar'
import { sessionSelector } from "../store/selectors";

const ProtectedRoutes: FC = (): JSX.Element => {
  useAuthWithCookie()
  const navigate = useNavigate()
  const session = useSelector(sessionSelector)
  const location = useLocation()
  const userType = useSelector((state: RootState) => state.session.userType)
  const token: string | null = localStorage.getItem('token')
  const shouldRecordHotjar = useSelector((state: RootState) => state.session.shouldRecordHotjar)
  const userEmail = useSelector((state: RootState) => state.session.userEmail)
  const userSid = useSelector((state: RootState) => state.session.userSid)
  useEffect(() => {
    if (token === null) return
    if (userType === 'buyer' && location.pathname === '/') {
      if (session?.features?.length === 1) {
        const feature: string = session.features[0]
        if (feature === 'order_tracking') {
          navigate('/track')
          return
        }
        if (feature === 'clear_to_build') {
          navigate('/plan?filter=all')
          return
        }
      }
      navigate('/track')
      return
    }
    if (userType === 'vendor' && location.pathname === '/') {
      navigate('/update')
      return
    }

    console.log('hotjar', hotjar)
    // Initialize hotjar in production if user is not a Factor employee and Hotjar has not already been initialized
    if (shouldRecordHotjar && !hotjar?.initialized() && process.env.REACT_APP_ENVIRONMENT === "production") {
      // TODO: delete after checking
      console.log('initializing hotjar')
      hotjar.initialize(3473424, 6)
    }
    // if hotjar is initialized, add user data
    if (hotjar?.initialized() && userEmail !== null) {
      // TODO: delete after checking
      console.log('sending email hotjar', userEmail)
      hotjar.identify(userSid, {
        email: userEmail
      })
    }
    navigate(location.pathname + location.search)
  }, [token, userType, shouldRecordHotjar, userSid, userEmail, session])
  return <>{token != null ? <Outlet /> : <Navigate to="/login" />}</>
}

export default ProtectedRoutes
