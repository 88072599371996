import { useEffect } from 'react'
import { useAppDispatch } from '../../store'
import {
  setAccessToken,
  setFeatures,
  setLoggedIn,
  setLoggedOut,
  setShouldRecordHotjar,
  setUserEmail,
  setUserSid,
  setUserType
} from '../../store/session'
import { useGetAccessTokenMutation } from '../../services/auth/Auth'
import { useNavigate } from 'react-router-dom'

const userTypes: Record<string, string> = {
  track: 'buyer',
  update: 'vendor',
}

interface AccessTokenResponse {
  features: string[];
  accessToken: string;
  defaultPage: string;
  recordHotjar: boolean;
  email?: string;
  userSid?: string
}

export default function useAuthWithCookies(): void {
  const [getAccessToken] = useGetAccessTokenMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = (await getAccessToken({})) as { data: AccessTokenResponse }
        const {
          accessToken,
          defaultPage,
          recordHotjar,
          email,
          userSid,
          features
        } = data
        if (accessToken !== undefined) {
          dispatch(setLoggedIn())
          dispatch(setAccessToken(accessToken))
          dispatch(setShouldRecordHotjar(recordHotjar))
          if (email != null) {
            dispatch(setUserEmail(email))
          }
          if (userSid != null) {
            dispatch(setUserSid(userSid))
          }

          const page = defaultPage.replace('/', '')
          const userTYpe = userTypes[page] ?? 'buyer'
          dispatch(setUserType(userTYpe))
          dispatch(setFeatures(features))
        }
      } catch (e: any) {
        console.log('Error on AuthCookie hook', e)
        dispatch(setLoggedOut())
        navigate('/login')
      }
    })()
  }, [])
}
