export enum ShipOption {
  pending = 'PENDING',
  shipped = 'SHIPPED',
}

export enum DeliveryOption {
  pending = 'PENDING',
  delivered = 'DELIVERED',
}

export interface UpdateItemFormValues {
  sid?: string
  itemSid: string
  title?: string
  uniqueName?: string
  trackingNumber?: string
  quantity?: number
  item?: {
    uniqueName?: string
    title?: string
  }
  dueDate: string | null
  shipped: boolean
  shipDate: string | null
  delivered: boolean
  deliveryDate: string | null
  notes: string
}

export interface UpdateItemFormErrors {
  dueDate?: string
  shipped?: string
  shipDate?: string
  delivered?: string
  deliveryDate?: string
  notes?: string
}

export type Role = 'buyer' | 'vendor'
