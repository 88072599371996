import { type FC, Fragment, type ReactNode, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface SlideoverProps {
  children: ReactNode
  title?: string
  backButtonText?: string
  open: boolean
  onClose?: () => void
}

export const Slideover: FC<SlideoverProps> = ({
  children,
  title,
  backButtonText = 'BACK',
  open,
  onClose,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=> {
    if (open) {
      setTimeout(()=>{
        setOpen(true)
      }, 100)
    }
  }, [])

    const handleClose = (): void => {
      setOpen(false);
      setTimeout(()=>{
        onClose?.()
      }, 500)
    }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-offBlack-dark bg-opacity-50 transition-opacity"
            data-testid="headlessui-portal-root"
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md ">
                  <div className="flex h-full flex-col overflow-y-scroll py-7 shadow-xl bg-offWhite-light">
                    <div className="px-8 sm:px-6">
                      <div className="flex h-7 items-center">
                        <button
                          data-testid='close_slideover_btn'
                          type="button"
                          className="flex ring-0 outline-0 rounded-md text-gray-400 hover:text-gray-500 !focus:outline-0 !focus:ring-0 items-center"
                          onClick={() => onClose?.()}
                        >
                          <FontAwesomeIcon
                            className="text-teal-dark"
                            icon={light('arrow-left')}
                          />
                          <span className="text-teal-dark text-sm ml-2 font-mono">
                            {backButtonText}
                          </span>
                        </button>
                      </div>
                      <div className="flex items-start justify-start mt-6 mr-24">
                        <Dialog.Title className="text-xl font-medium text-offBlack-dark mb-6 overflow-hidden max-w-[80%]">
                          {title}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="relative flex-1 px-8 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
