import { type FC, type ReactNode } from 'react'
import { IconLink } from '../IconLink/IconLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'

interface BackButtonProps {
  children: ReactNode
  path: string
}

export const BackLink: FC<BackButtonProps> = ({
  children,
  path,
}: BackButtonProps): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate(path)
  }

  return (
    <IconLink
      testId="back-link"
      Icon={<FontAwesomeIcon icon={light('arrow-left')} />}
      iconLeft={true}
      onClick={handleClick}
    >
      {children}
    </IconLink>
  )
}
