import React, { type FC, type JSX, useRef } from 'react'
import { MemberCard } from '../../../components/MemberCard/MemberCard'
import { useGetTeamQuery } from '../../../services/team/Team'
import { useGetLoggedInUserQuery } from '../../../services/user/User'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetFieldMappingQuery } from '../../../services/fieldMapping/FieldMapping'
import { filterFieldMappingByOrganization } from '../../../components/UploadCTBData/UploadCTBData'
import { FormField } from '../../../components/FormField/FormField'
import { Link } from 'react-router-dom'
import { openSlideOver } from '../../../store/slideOvers'
import { useDispatch } from 'react-redux'
import { type FieldMapping } from '../../../types'
import { FieldArray, Form, Formik } from 'formik'
import * as Yup from 'yup'

export const Team: FC = (): JSX.Element => {
  const { data: user, isLoading: isUserLoading } = useGetLoggedInUserQuery(1)
  const { data: team, isLoading } = useGetTeamQuery(user?.organizationSid, {
    skip: user === undefined,
  })
  const dispatch = useDispatch();

  const iconRef = useRef(null)
  const { data: fieldMappings } = useGetFieldMappingQuery(1)
  const filteredFieldMappings = filterFieldMappingByOrganization(fieldMappings?.fieldMappings, user?.organizationSid,)

  const handleOpenPreview = (fieldMapping: FieldMapping): void => {
    dispatch(openSlideOver({
      name: 'FieldMapping',
      data: {
        fieldMappingSid: fieldMapping.sid,
        fieldMappingName: fieldMapping.name
      },
    }))
  }

  const initialValues = {
    fieldMaps: filteredFieldMappings ?? [],
  };

  const validationSchema = Yup.object().shape({
    fieldMaps: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        id: Yup.string()
      })
    )
  });


  if (isUserLoading || isLoading) return <div>Loading...</div>

  return (
    <>
      <div className="pr-28">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
            TEAM MEMBERS
          </div>

          {team.users.map(
            (member: {
              sid: string
              firstName: string
              lastName: string
              email: string
            }) => (
              <div key={member.sid} className="col-span-4">
                <MemberCard
                  memberName={`${member.firstName} ${member.lastName}`}
                  currentUser={user.sid === member.sid}
                  memberEmail={member.email}
                />
              </div>
            )
          )}
        </div>
        <div className="grid grid-cols-12 w-full">
          <div className="col-span-3"></div>
          <div className="col-span-8 text-sm font-sans text-offBlack-dark">
            <p>
              New team members should use their company email address to register
              for Factor at{' '}
              <a
                className="text-blue-700 underline"
                href="https://my.factor.io/register"
                target="_blank"
                rel="noreferrer"
              >
                factor.io/register
              </a>
              . Factor will automatically add them to this team.
              <a
                className="text-blue-700 underline"
                href="mailto:support@factor.io"
              >
                {' '}
                Contact support
              </a>{' '}
              with any questions or to further manage your team.
            </p>
          </div>
        </div>
        {filteredFieldMappings && filteredFieldMappings?.length > 0 ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              // TODO: Add POST to update fieldMapping
            }}
          >
            {({ values, handleChange, isValid }) => {
              return (
                <Form autoComplete="off">
                  <hr className="mt-10" />
                  <div className="grid grid-cols-12 gap-4 mt-5 mb-10">
                    <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
                      <div className="flex gap-1">
                        <span>Field Maps</span>
                        <div>
                          <div ref={iconRef}>
                            <FontAwesomeIcon className="text-offBlack-mid" icon={light('info-circle')} />
                          </div>
                          <Tooltip position='bottom' ref={iconRef}>
                            <p>
                              Factor uses field maps to import or export your data using the format you choose
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6">
                        <FieldArray name="fieldMaps">
                          {/* @ts-expect-error not mapping to Element[] */}
                          <div>
                            {values.fieldMaps.map((fieldMap, index) => {
                              const name = `fieldMaps[${index}].name`;

                              return (
                                <div className="flex gap-2 items-center" key={fieldMap.sid}>
                                  <FormField
                                    className="w-full z-0"
                                    label='Map name'
                                    inputName={name}
                                    defaultValue={fieldMap.name}
                                    onChange={handleChange}
                                  />
                                  <div className="text-blue-mid underline w-32 cursor-pointer" onClick={() => {
                                    handleOpenPreview(fieldMap)
                                  }}>
                                    Preview map
                                  </div>
                                </div>
                              )})}
                          </div>
                        </FieldArray>
                      <p className="text-xs mt-6 text-offBlack-mid"><Link to="mailto:support@factor.io" className="text-blue-mid underline">Contact support</Link> to add or remove field mappings</p>
                    </div>
                  </div>
                  {/* TODO: Add POST to update fieldMapping */}
                  {/* <div className="flex justify-end mb-10"> */}
                  {/*   <Button type="submit" disabled={!isValid} className="ml-20"> */}
                  {/*     Save Changes */}
                  {/*   </Button> */}
                  {/* </div> */}
                </Form>
              )
            }}
          </Formik>
        ) : null}
      </div>
    </>

  )
}
