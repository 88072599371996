import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { type FC, useEffect, useState } from 'react'

import { Button, Checkbox } from '../../../components'
import { FormField } from '../../../components/FormField/FormField'
import { useUpdateUserMutation } from '../../../services/updateUser/UpdateUser'
import { useGetLoggedInUserQuery } from '../../../services/user/User'
import { useAppDispatch } from '../../../store'
import { openNotification } from '../../../store/notifications'

export const Profile: FC = (): JSX.Element => {
  const [showPreviousPassword, setShowPreviousPassword] =
    useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [updateUser] = useUpdateUserMutation()
  const { data: user, isLoading: isUserLoading } = useGetLoggedInUserQuery(1)
  const [usePassword, setUsePassword] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      currentPassword: '',
      newPassword: '',
    },
    initialErrors: {
      firstName: '',
      lastName: '',
      currentPassword: '',
      newPassword: '',
    },
    onSubmit: async ({ firstName, lastName, currentPassword, newPassword }) => {
      try {
        await updateUser({
          sid: user.sid,
          firstName,
          lastName,
          currentPassword,
          newPassword,
        })
        dispatch(
          openNotification({
            title: 'Organization setting updated',
            message:
              'We’ve successfully updated your organization’s information',
            type: 'success',
            show: true,
          })
        )
      } catch (error) {
        dispatch(
          openNotification({
            title: 'Organization setting failed to update',
            message:
              'We were not able to save your changes to your organization’s information. Please try again',
            type: 'error',
            show: true,
          })
        )
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      firstName: user?.firstName,
      lastName: user?.lastName,
      currentPassword: '',
      newPassword: '',
    })
    setUsePassword(user?.password)
  }, [user])

  if (isUserLoading) return <div>Loading...</div>
  const toggleShowPassword = (passwordSetter: any): void => {
    console.log('toggle show password')
    passwordSetter((prevState: any) => !prevState)
  }

  const noPreviousPasswordHint: string =
    "Enter thepassword you'd like to use below:"
  const changePasswordHint: string =
    'To change your password, enter your previous password and your new password.'
  const passwordStepHint: string = user?.password
    ? changePasswordHint
    : noPreviousPasswordHint
  const passwordHint: string = usePassword
    ? passwordStepHint
    : 'Add the Password login method above to create a password.'

  return (
    <div className="pr-28">
      <div className="grid grid-cols-10 gap-4 w-full">
        <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
          Personal Information
        </div>
        <div className="col-span-3">
          <FormField
            defaultValue={formik.values.firstName}
            onChange={formik.handleChange}
            inputName={'firstName'}
            label={'First Name'}
          />
        </div>
        <div className="col-span-3">
          <FormField
            defaultValue={formik.values.lastName}
            onChange={formik.handleChange}
            inputName={'lastName'}
            label="Last Name"
          />
        </div>
        <div className="col-span-6">
          <FormField
            inputName="Email"
            label="Email Address"
            defaultValue={user?.email}
            disabled={true}
          />
        </div>
      </div>

      <div className="grid grid-cols-10 w-full">
        <div className="col-span-3"></div>
        <div className="col-span-7 text-sm font-sans text-offBlack-dark">
          <p>
            <a
              className="text-blue-700 underline"
              href="mailto:support@factor.io"
            >
              {' '}
              Contact support
            </a>{' '}
            to change your account&apos;s email address.
          </p>
        </div>
      </div>

      <hr className="mt-10" />

      <div className="grid grid-cols-10 gap-4 w-full mt-5 mb-20">
        <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
          Login Methods
        </div>
        <div className="col-span-6">
          <Checkbox
            label={'Magic link (You can always use this method)'}
            handleChange={() => {}}
            disabled={true}
            checked={true}
          />
        </div>
        <div className="col-span-6">
          <Checkbox
            dataTestId="password-checkbox"
            label={'Password'}
            handleChange={() => {
              setUsePassword((prevState: boolean) => !prevState)
            }}
            disabled={false}
            checked={usePassword}
          />
        </div>
        <div className="col-span-6">
          <p>{passwordHint}</p>
        </div>
        <div className="col-span-6">
          {usePassword && user?.password && (
            <FormField
              onChange={formik.handleChange}
              defaultValue={formik.values.currentPassword}
              inputName={'currentPassword'}
              label={'Previous password'}
              icon={
                showPreviousPassword ? (
                  <div data-testid="input_icon_currentPassword">
                    <FontAwesomeIcon
                      onClick={() => {
                        toggleShowPassword(setShowPreviousPassword)
                      }}
                      className="cursor-pointer"
                      icon={light('eye')}
                    />
                  </div>
                ) : (
                  <div data-testid="input_icon_currentPassword">
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      onClick={() => {
                        toggleShowPassword(setShowPreviousPassword)
                      }}
                      icon={light('eye-slash')}
                    />
                  </div>
                )
              }
            />
          )}
        </div>
        <div className="col-span-6">
          <FormField
            onChange={formik.handleChange}
            defaultValue={formik.values.newPassword}
            inputName={'newPassword'}
            label={`${
              usePassword && user?.password ? 'New password' : 'Password'
            }`}
            disabled={!usePassword}
            icon={
              showNewPassword ? (
                <div data-testid="input_icon_newPassword">
                  <FontAwesomeIcon
                    onClick={() => {
                      toggleShowPassword(setShowNewPassword)
                    }}
                    className="cursor-pointer"
                    icon={light('eye')}
                  />
                </div>
              ) : (
                <div data-testid="input_icon_newPassword">
                  <FontAwesomeIcon
                    onClick={() => {
                      toggleShowPassword(setShowNewPassword)
                    }}
                    className="cursor-pointer"
                    icon={light('eye')}
                  />
                </div>
              )
            }
          />
        </div>
      </div>

      <hr className="mt-10" />

      <div className="grid grid-cols-10 gap-4 w-full mt-5 mb-20">
        <div className="col-span-3 row-span-2 font-mono text-offBlack-mid text-sm font-medium uppercase">
          Documentation
        </div>
        <div className="col-span-6">
          By using Factor, you agree to our{' '}
          <a
            className="underline text-sm text-blue-mid"
            href="https://www.factor.io/documentation/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            <span>terms and conditions</span>
          </a>
        </div>
        <div className="col-span-6">
          <a
            className="underline text-sm text-blue-mid"
            href="https://www.factor.io/documentation/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <span>View our privacy policy here</span>
          </a>
        </div>
        <div className="col-span-7"></div>
        <div className="col-span-2">
          <Button
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="ml-20"
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  )
}
