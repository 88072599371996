import { useFormik } from 'formik'
import React, { type JSX, useEffect, useState } from 'react'

import { Button } from '../../../components'
import { Switch } from '../../../components/Switch/Switch'
import {
  useGetSettingsQuery,
  usePostUpdateSettingsMutation,
} from '../../../services/organizationSettings/OrganizationSettings'
import { useGetLoggedInUserQuery } from '../../../services/user/User'
import { useAppDispatch } from '../../../store'
import { openNotification } from '../../../store/notifications'
import { TurnOffCommunication } from 'components/Modals/TurnOffCommunication/TurnOffCommunication'
import { unwrapResult } from '@reduxjs/toolkit'

const defaultGlobalPOAcknowledgementCadenceSid =
  'CAbdrk7d4gj93my47km874xxqmp8k8'
const defaultGlobalPastDueCadenceSid = 'CAx4k478k876hjd7rk37rxvvxcmr3m'

export const Communications = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [showTurnOffCommunication, setShowTurnOffCommunication] = useState<boolean>(false)

  const { data: user, isLoading: isUserLoading } = useGetLoggedInUserQuery(1)
  const { data: settings, isLoading } = useGetSettingsQuery(
    user?.organizationSid,
    {
      skip: user === undefined,
    }
  )
  const [updateOrganizationSettings] = usePostUpdateSettingsMutation()

  useEffect(() => {
    if (settings && Object.keys(settings).length !== 0) {
      formik.setFieldValue(
        'enhancedPOAcknowledgement',
        settings?.defaultItemGroupCadence ===
          defaultGlobalPOAcknowledgementCadenceSid
      )
      formik.setFieldValue(
        'updateRequests',
        settings?.sendAutomatedUpdateRequests
      )
      formik.setFieldValue(
        'pastDueCadence',
        settings?.defaultPastDueCadenceSid === defaultGlobalPastDueCadenceSid
      )
    }
  }, [settings])

  const formik = useFormik({
    initialValues: {
      enhancedPOAcknowledgement: false,
      updateRequests: false,
      pastDueCadence: false,
    },
    onSubmit: async (data) => {
      const values = {
        defaultItemGroupCadence: data.enhancedPOAcknowledgement
          ? defaultGlobalPOAcknowledgementCadenceSid
          : null,
        defaultPastDueCadenceSid: data.pastDueCadence
          ? defaultGlobalPastDueCadenceSid
          : null,
        sendAutomatedUpdateRequests: data.updateRequests,
      }

      const allFalse = Object.values(data).every((value) => !value);

      if (allFalse && !showTurnOffCommunication) {
        setShowTurnOffCommunication(true);
        return;
      }
      else{
        setShowTurnOffCommunication(false);
      }

      try {
        const response = await updateOrganizationSettings({
          organizationSid: user?.organizationSid,
          ...values,
        })
        unwrapResult(response as any);
        dispatch(
          openNotification({
            title: 'Settings updated',
            message: 'Your settings were updated successfully',
            type: 'success',
            show: true,
          })
        )
      } catch (e) {
        console.log('error', e)
        dispatch(
          openNotification({
            title: 'Something went wrong',
            message: 'Your settings were not updated. Please try again or contact us at support@factor.io for assistance.',
            show: true,
            type: 'error',
          })
        )
      }
    },
  })

  const handleChange = (name: string, value: boolean): void => {
    formik.setFieldValue(name, value)
  }

  if (isUserLoading || isLoading) return <div>Loading...</div>

  return (
    <>
      <div className="pr-28">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="col-span-3 row-span-5 font-mono text-offBlack-mid text-sm font-medium uppercase">
            EMAIL SEQUENCES
          </div>
          <div className="col-span-9 flex flex-col gap-6">
            <div className="flex gap-2">
              <div>
                <Switch
                  id="enhancedPOAcknowledgement"
                  enabled={formik.values.enhancedPOAcknowledgement}
                  onChange={(value: boolean) => {
                    handleChange('enhancedPOAcknowledgement', value)
                  }}
                />
              </div>
              <div>
                <p className="text-offBlack-dark text-sm mb-1">
                  Enhanced PO Acknowledgement
                </p>
                <p className="text-offBlack-mid text-xs">
                  When line items are first added to Factor, we send an email
                  asking vendor contacts to confirm the PO. This cadence adds
                  additional reminders for any vendors who do not confirm POs to
                  take action. This cadence is helpful if your team wants to add
                  extra safeguards to ensure vendors agree to the terms of a PO.
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <div>
                <Switch
                  id="updateRequests"
                  enabled={formik.values.updateRequests}
                  onChange={(value: boolean) => {
                    handleChange('updateRequests', value)
                  }}
                />
              </div>
              <div>
                <p className="text-offBlack-dark text-sm mb-1">
                  Update Requests
                </p>
                <p className="text-offBlack-mid text-xs">
                  Factor will send update requests to your vendor contacts on{' '}
                  <a
                    href="https://www.factor.io/support/understanding-factor-communications"
                    target="_blank"
                    className="text-blue-mid underline"
                    rel="noreferrer"
                  >
                    our recommended cadence
                  </a>{' '}
                  as the due dates of your line items approach.
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <div>
                <Switch
                  id="pastDueCadence"
                  enabled={formik.values.pastDueCadence}
                  onChange={(value: boolean) => {
                    handleChange('pastDueCadence', value)
                  }}
                />
              </div>
              <div>
                <p className="text-offBlack-dark text-sm mb-1">
                  Past Due Cadence
                </p>
                <p className="text-offBlack-mid text-xs">
                  If any line items become past due, Factor will send an email
                  on your behalf asking for an urgent update. Modify how Factor
                  defines Past Due items for your organization in Flag Settings.
                </p>
              </div>
            </div>
            <div>
              <a
                className="text-xs text-blue-mid underline"
                href="https://www.factor.io/support/understanding-factor-communications"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about the emails Factor can send on your behalf.
              </a>
            </div>
            <div className="flex justify-end">
              <Button onClick={formik.handleSubmit}>
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showTurnOffCommunication && 
        <TurnOffCommunication
          onConfirm={() => {
            formik.handleSubmit();
          }}
          onClose={() => {setShowTurnOffCommunication(false)}}
        />}
    </>
  )
}
