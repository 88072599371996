import { type CaseReducer, type Draft, type PayloadAction, } from '@reduxjs/toolkit'
import { type SlideOversState } from '../slideOvers'

export const openSlideOverAction: CaseReducer<
  SlideOversState,
  PayloadAction<SlideOversState>
> = (state: Draft<SlideOversState>, action) => {
  state.name = action.payload.name
  state.data = action.payload.data
  state.isFetching = action.payload.isFetching
  state.onClose = action.payload.onClose
}

export const closeSlideOverAction: CaseReducer<SlideOversState> = (state) => {
  state.name = null
  state.data = null
  state.isFetching = false
  state.onClose = null
}
