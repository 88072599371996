import { formatDateForRender } from '../formatDatesForRender/formatDatesForRender'
import { type ItemGroupWithRelations } from '../../types'
import { getItemGroupLabel } from '../getItemGroupLabel/getItemGroupLabel'

function getVendorStats(vendorsStats: any, vendorSid: string, vendorName: string): any {
  if (vendorsStats === undefined || vendorsStats === null || Object.keys(vendorsStats).length === 0) return undefined
  const itemGroupStats = Object.entries(vendorsStats).find( entry  => {
    const [key] = entry
    return vendorsStats[key].vendorOrganizationSid === vendorSid || vendorsStats[key]?.buyerVendorName === vendorName
  })
  if (itemGroupStats === undefined) return undefined
  const [,itemGroupVendorStats]: any = itemGroupStats
  return itemGroupVendorStats;
}

function getFlagsForItemGroup(flags: any, itemGroupSid: any): any[] {
  const itemGroupFlags: any[] = []
  Object.entries(flags).forEach(([key, value]) => {
    const flagInfo = flags[key]
    if (itemGroupSid === flagInfo.itemGroupSid) {
      itemGroupFlags.push(value)
    }
  })
  return itemGroupFlags;
}

export const formatItemGroupRelations = (
  itemGroupsData: any
): ItemGroupWithRelations[] => {

  if (itemGroupsData?.itemGroups === undefined) return []
  const itemGroupsWithRelations = []
  for (const itemGroup of itemGroupsData.itemGroups) {
    const item = itemGroupsData.items[itemGroup.itemSid]
    const order = itemGroupsData.orders[item.orderSid]
    const organization = itemGroupsData.organizations[order.organizationSid]
    const vendor = itemGroupsData.organizations[order.vendorSid]
    const vendorName = order.vendorName !== null && order.vendorName !== undefined ? order.vendorName : vendor?.name
    const vendorSid = order.vendorSid === null ? '' : order.vendorSid
    const vendorStats = getVendorStats(itemGroupsData.vendorStats, vendorSid , vendorName)
    const flags = getFlagsForItemGroup(itemGroupsData.flags, itemGroup.sid)
    const dates = formatDateForRender(
      itemGroup,
      itemGroupsData.lastUpdateRequests[itemGroup.sid],
      itemGroupsData.updateRequestConfigs[itemGroup.sid],
      vendorStats
    )
    const label = getItemGroupLabel(item)

    itemGroupsWithRelations.push({
      ...itemGroup,
      ...dates,
      flags,
      label,
      organization: {
        ...organization,
      },
      vendor: {
        name: vendorName,
        sid: vendorSid,
      },
      item: {
        ...item,
      },
      order: {
        ...order,
      },
      updateRequestConfig: {
        ...itemGroupsData.updateRequestConfigs[itemGroup.sid],
      },
      lastUpdateRequest: {
        ...itemGroupsData.lastUpdateRequests[itemGroup.sid],
      },
    })
  }
  return itemGroupsWithRelations
}
