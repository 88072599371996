import { Slideover } from 'components/Slideover/Slideover'
import React, { type FC } from 'react'
import { Button, TextArea } from 'components'
import { useCreateUpdateRequestMutation } from '../../services/updateRequests/UpdateRequests'
import { useSelector } from 'react-redux'
import { slideOverSelector, searchParamsSelector } from 'store/selectors'
import { useAppDispatch } from '../../store'
import { closeSlideOver } from '../../store/slideOvers'
import { useFormik } from 'formik'
import {
  type FormattedLabels,
  formatUpdateRequestItemGroups,
} from '../../utils/formatUpdateRequestItemGroups/formatUpdateRequestItemGroups'
import { type ItemGroupWithRelations } from '../../types'

export const RequestUpdates: FC = () => {
  const { searchText, pageToken } = useSelector(searchParamsSelector)
  const { data, name, isFetching } = useSelector(slideOverSelector)
  const namesFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  })
  const show = name === 'RequestUpdate'
  const [createUpdateRequest] = useCreateUpdateRequestMutation()
  const dispatch = useAppDispatch()

  const onClose = (): void => {
    formik.resetForm()
    dispatch(closeSlideOver())
  }

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    onSubmit: ({ note }) => {
      // TODO: use bulk update request mutation when it's available
      data.selectedItemGroups.map(async (ig: ItemGroupWithRelations) => {
        await createUpdateRequest({
          payload: {
            itemGroupSid: ig.sid,
            organizationSid: ig.organization.sid,
            note,
          },
          searchParams: { searchText, pageToken }
        })
      })
      onClose?.()
    },
    enableReinitialize: true,
  })
  const isLoading: boolean = isFetching ?? false
  if (isLoading) return null
  if (data === null) return null
  const formattedLabels = formatUpdateRequestItemGroups(
    data.selectedItemGroups,
    data.orderMembers
  )
  return (
    <Slideover open={show} onClose={onClose} title="Request Updates">
      <p className="text-sm">
        Factor will send an update request on the following items:
      </p>
      <div className="py-5">
        {formattedLabels.map((label: FormattedLabels, index: number) => (
          <div key={`ig-update-${index}`}>
            <hr className="my-4" />
            <p>
              <strong>{label.itemGroupsNumber} items</strong> across{' '}
              <strong>{label.ordersNumber} orders</strong> with{' '}
              <strong>{label.vendor}</strong>
            </p>
            <p className="text-sm">
              {label.recipients?.length === 0 ? (
                'No recipients'
              ) : (
                <div>
                  Recipients:{' '}
                  {namesFormatter.format(
                    label.recipients.map((r: { name: string }) => r.name)
                  )}
                </div>
              )}
            </p>
          </div>
        ))}
      </div>
      <TextArea
        onChange={(value): void => {
          formik.setFieldValue('note', value)
        }}
        defaultValue={formik.values.note}
        placeholder="Type your custom message here..."
      />
      <div className="flex justify-end mt-auto absolute bottom-0 right-6">
        <Button style="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={formik.handleSubmit} loading={formik.isSubmitting} cySelector='requestUpdate'>
          Request Update
        </Button>
      </div>
    </Slideover>
  )
}
