import React, { type FC } from 'react'

import { Datepicker } from '../../Datepicker/Datepicker'
import { FormField } from '../../FormField/FormField'

interface DateFieldProps {
  role: 'buyer' | 'vendor'
  onChange: (value: string | null) => void
  value: string
  error: string
}
export const DateField: FC<DateFieldProps> = ({
  role,
  onChange,
  value,
  error,
}) => {
  const defaultValue = !!error && error !== '' ? error : value
  return (
    <>
      {role === 'buyer' ? (
        <Datepicker
          className="mt-5 mb-6"
          label="Due date"
          onChange={onChange}
          value={value}
          error={error}
        />
      ) : (
        <FormField
          className="mt-5 mb-10"
          label="Due date"
          inputName="dueDate"
          disabled
          defaultValue={defaultValue}
        />
      )}
    </>
  )
}
