import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useLazyGetSecureTokenQuery } from 'services/secureTokens/SecureTokens';
import { sessionSelector } from "../../store/selectors";


export const EmailOptOut = (): JSX.Element => {
  const { token } = useParams()
  const { loggedIn } = useSelector(sessionSelector)
  const navigate = useNavigate()
  const [getOptOut] = useLazyGetSecureTokenQuery()

  useEffect(() => {
    const fetchOptOut = async (): Promise<void> => {
      try {
        const response = await getOptOut(token ?? '').unwrap()
        const searchParams = {
          fromOptOut: 'true',
        }
        if (response.optOut.startsWith('OR') && loggedIn) {
          navigate({
            pathname: `/orders/${response.optOut}`,
            search: `${createSearchParams(searchParams)}`,
          })
        } else if (response.optOut.startsWith('OR') && !loggedIn) {
          navigate(`/add-members-no-auth/${token}`)
        }
      } catch (err) {
        console.log('error while fetching email out auth data')
      }
    }
    fetchOptOut()
  }, [token, loggedIn]);


  return (
    <div>
    </div>
  )
}
