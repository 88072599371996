import React from 'react'
import ReactDOM from 'react-dom/client'
import "shepherd.js/dist/css/shepherd.css";
import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import router from 'routing/router'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { type Configuration } from 'rollbar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { hotjar } from "react-hotjar";

dayjs.extend(utc)
dayjs.extend(tz)

const rollbarConfig: Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: (process.env.REACT_APP_ENVIRONMENT !== 'local')
}

console.log('VERSION', process.env.REACT_APP_BUILD_VERSION)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </RollbarProvider>
    </Provider>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// initialize hot jar in production
if (!hotjar.initialized() && process.env.NODE_ENV === "production") {
    hotjar.initialize(3473424, 6)
}
