import { type UpdateItemFormErrors, type UpdateItemFormValues } from './types'
import dayjs from 'dayjs'

export const validate = (role: string) => (values: UpdateItemFormValues): object => {
  const errors = {} as UpdateItemFormErrors
  if (role === 'buyer') return errors
  const today = dayjs()

  if (!values.shipped) {
    const newDate = dayjs(values.shipDate, 'MM/DD/YY')
    if (newDate.isSameOrBefore(today)) {
      errors.shipDate =
        'To update a pending shipment, please provide a date in the future'
    }
  }

  if (!values.delivered) {
    const newDate = dayjs(values.deliveryDate, 'MM/DD/YY')
    if (newDate.isSameOrBefore(today)) {
      errors.deliveryDate =
        'To update a pending delivery, please provide a date in the future'
    }
  }

  if (values.delivered && !values.shipped) {
    errors.shipDate =
      'Please mark Shipped and provide a ship date to update to Delivered'
  }

  if (values.shipped && values.shipDate === null) {
    errors.shipDate = 'Please provide a ship date to mark as Shipped'
  }

  if (values.delivered && values.deliveryDate === null) {
    errors.deliveryDate = 'Please provide a delivery date to mark as Delivered'
  }

  if (dayjs(values.deliveryDate).isSameOrBefore(dayjs(values.shipDate))) {
    errors.deliveryDate =
      'Delivery date cannot be before ship date'
  }

  return errors
}
