import { createApi } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { toTimelineEvents } from 'utils/timelineEvent/timelineEvent';



import { type BulkUpdateWithParams, type Flag, type ItemGroup, type ItemUpdateMessage, type UpdateItemGroupPayload } from '../../types';
import { enrichEventsWithEmailContent } from '../inboundEmails/InboundEmails';
import { apiItemGroupSlice } from '../itemgroup/ItemGroup';
import { apiOrderMembersSlice } from '../orderMembers/OrderMembers';
import publicAPIBaseQuery from '../publicApiBaseQuery';


export const apiOrdersSlice = createApi({
  reducerPath: 'ordersApi',
  baseQuery: publicAPIBaseQuery,
  tagTypes: [
    'Orders',
    'Order',
    'Items',
    'Flags',
    'ItemGroups',
    'ItemGroupsByOrder',
    'ItemEvents',
    'ItemGroupEvents',
    'OrderEvents',
  ],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => '/v1/Orders',
    }),
    getOrder: builder.query({
      query: (sid: string) => `/v1/Orders/${sid}`,
    }),
    getBuyerItems: builder.query({
      query: (buyerSid: string) => `/v1/Items?buyerSid=${buyerSid}`,
      providesTags: ['Items'],
    }),
    getVendorItems: builder.query({
      query: (vendorSid: string) => `/v1/Items?vendorSid=${vendorSid}`,
      providesTags: ['Items'],
    }),
    getItemGroupByItemId: builder.query({
      query: (itemSid: string) => `/v1/ItemGroups?itemSid=${itemSid}`,
      providesTags: ['Items'],
    }),
    deleteItemGroups: builder.mutation({
      query: (itemGroupSids: string) => ({
        url: `v1/ItemGroups/${itemGroupSids}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ItemGroups'],
    }),
    getOrderItemsByOrderId: builder.query({
      query: (orderSid: string) => `/v1/Items?orderSid=${orderSid}`,
      providesTags: ['Order'],
    }),
    deleteItem: builder.mutation({
      query: (itemSid: string) => ({
        url: `v1/Items/${itemSid}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            apiItemGroupSlice.util.invalidateTags(['ItemGroups'])
          )
        })
      },
      invalidatesTags: ['ItemGroups'],
    }),
    getVendor: builder.query({
      query: (sid: string) => `/v1/Organizations/${sid}/PublicProfile`,
    }),
    getOrganization: builder.query({
      query: (sid: string) => `/v1/Organizations/${sid}/PublicProfile`,
    }),
    updateItemGroup: builder.mutation({
      query: (itemGroup: Partial<UpdateItemGroupPayload>) => ({
        url: `/v1/ItemGroups/${itemGroup?.sid}`,
        method: 'POST',
        body: itemGroup,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemGroup } = await queryFulfilled

          if (updatedItemGroup?.status === 'canceled') {
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroups',
                null,
                (draft) => {
                  draft.itemGroups = draft.itemGroups.filter(
                    (ig: any) => ig.sid !== updatedItemGroup.sid
                  )
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsTrack',
                null,
                (draft) => {
                  draft.itemGroups = draft.itemGroups.filter(
                    (ig: any) => ig.sid !== updatedItemGroup.sid
                  )
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsUpdate',
                null,
                (draft) => {
                  draft.itemGroups = draft.itemGroups.filter(
                    (ig: any) => ig.sid !== updatedItemGroup.sid
                  )
                }
              )
            )
          } else {
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroups',
                null,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsTrack',
                null,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsUpdate',
                null,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
          }
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
    }),
    createItemGroup: builder.mutation({
      query: (itemGroup: Partial<ItemGroup>) => ({
        url: 'v1/ItemGroups',
        method: 'POST',
        body: itemGroup,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: createdItemGroup } = await queryFulfilled
          console.log(createdItemGroup)
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsTrack',
              null,
              (draft) => {
                draft.itemGroups.push(createdItemGroup)
              }
            )
          )
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsUpdate',
              null,
              (draft) => {
                draft.itemGroups.push(createdItemGroup)
              }
            )
          )
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
      invalidatesTags: ['ItemGroupsByOrder'],
    }),
    createMessageForItemUpdate: builder.mutation({
      query: (message: ItemUpdateMessage) => ({
        url: `/v1/Messages`,
        method: 'POST',
        body: message,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    getFlagBySid: builder.query({
      query: (flagSid: string) => `/v1/Flags/${flagSid}`,
    }),
    dismissFlags: builder.mutation({
      query: (flag: Flag) => ({
        url: `v1/Flags/${flag.sid}`,
        method: 'POST',
        body: {
          dismissed: dayjs.utc(Date.now()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          status: 'dismissed',
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: flagResponse } = await queryFulfilled
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroups',
              null,
              (draft) => {
                draft.flags[flagResponse.sid] = flagResponse
              }
            )
          )
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsTrack',
              null,
              (draft) => {
                draft.flags[flagResponse.sid] = flagResponse
              }
            )
          )
          dispatch(
            apiItemGroupSlice.util.updateQueryData(
              'getItemGroupsUpdate',
              null,
              (draft) => {
                draft.flags[flagResponse.sid] = flagResponse
              }
            )
          )
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
    }),
    getOrderEvents: builder.query({
      query: (orderSid: string) => `v1/Orders/${orderSid}/Events`,
      providesTags: ['OrderEvents'],
    }),
    getItemEvents: builder.query({
      query: (itemSid: string) => `v1/Items/${itemSid}/Events`,
      providesTags: ['ItemEvents'],
      onQueryStarted: async (
        itemSid: any,
        { queryFulfilled, dispatch, getState }
      ) => {
        try {
          const { data } = await queryFulfilled
          const itemGroupEvents = toTimelineEvents(data.itemEvents)
          const enrichedEvents = await enrichEventsWithEmailContent(
            itemGroupEvents,
            dispatch
          )
          dispatch(
            apiOrdersSlice.util.updateQueryData(
              'getItemEvents',
              itemSid,
              () => enrichedEvents
            )
          )
        } catch (error) {
          console.error('Failed to process item group events:', error)
        }
      },
    }),
    getItemGroupEvents: builder.query({
      query: (itemSid: string) => `v1/ItemGroups/${itemSid}/Events`,
      providesTags: ['ItemEvents'],
      onQueryStarted: async (
        itemSid: any,
        { queryFulfilled, dispatch, getState }
      ) => {
        try {
          const { data } = await queryFulfilled
          const itemGroupEvents = toTimelineEvents(data.itemGroupEvents)
          const enrichedEvents = await enrichEventsWithEmailContent(
            itemGroupEvents,
            dispatch
          )
          dispatch(
            apiOrdersSlice.util.updateQueryData(
              'getItemGroupEvents',
              itemSid,
              () => enrichedEvents
            )
          )
        } catch (error) {
          console.error('Failed to process item group events:', error)
        }
      },
    }),
    bulkUpdateItemGroups: builder.mutation({
      query: (bulkUpdateItemGroups: BulkUpdateWithParams) => ({
        url: 'v1/ItemGroups/BulkUpdates',
        method: 'POST',
        body: bulkUpdateItemGroups.itemGroups,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted({ searchParams }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemGroups } = await queryFulfilled
          console.log(updatedItemGroups)
          updatedItemGroups.forEach((updatedItemGroup: ItemGroup) => {
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroups',
                null,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroups',
                updatedItemGroups[0]?.orderSid,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsTrack',
                searchParams,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsUpdate',
                searchParams,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
          })
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
    }),
    dismissItemGroupFlags: builder.mutation({
      query: (itemGroups) => ({
        url: 'v1/ItemGroups/BulkUpdates',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: itemGroups,
        providesTags: ['Flags', 'Items'],
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemGroups } = await queryFulfilled
          updatedItemGroups.forEach((updatedItemGroup: ItemGroup) => {
            dispatch(
              apiItemGroupSlice.util.updateQueryData(
                'getItemGroupsUpdate',
                null,
                (draft) => {
                  const index = draft.itemGroups
                    .map((ig: any) => ig.sid)
                    .indexOf(updatedItemGroup.sid)
                  draft.itemGroups[index] = updatedItemGroup
                }
              )
            )
          })
        } catch (e) {
          console.log('error on dom updating item groups', e)
        }
      },
    }),
    uploadOpenOrderFile: builder.mutation({
      query: (body) => ({
        url: '/v1/OpenOrderUploads',
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
    }),
    getAllMembers: builder.query({
      query: (orderSid) => `v1/OrderMembers?orderSid=${orderSid}`,
    }),
    getUserOrderMember: builder.query({
      query: ({ orderSid, memberSid }) =>
        `v1/OrderMembers?orderSid=${orderSid}&memberSid=${memberSid}`,
    }),
    getMember: builder.query({
      query: (memberSid) => `v1/Users/${memberSid}`,
    }),
    removeUserOrderMember: builder.mutation({
      query: (orderMemberSid) => ({
        url: `/v1/OrderMembers/${orderMemberSid}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            apiOrderMembersSlice.util.invalidateTags(['OrderMembers'])
          )
        })
      },
    }),
    createOrderMember: builder.mutation({
      query: (orderMember: { orderSid: string; memberSid: string }) => ({
        url: '/v1/OrderMembers',
        method: 'POST',
        body: orderMember,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            apiOrderMembersSlice.util.invalidateTags(['OrderMembers'])
          )
        })
      },
    }),
    updateItem: builder.mutation({
      query: ({ sid, title, uniqueName}: { sid: string, title?: string; uniqueName?: string }) => ({
        url: `/v1/Items/${sid}`,
        method: 'POST',
        body: { title, uniqueName },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            apiItemGroupSlice.util.invalidateTags(['ItemGroups'])
          )
        })
      },
    }),
  }),
})

export const {
  useLazyGetVendorQuery,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useGetOrderQuery,
  useUpdateItemGroupMutation,
  useLazyGetItemEventsQuery,
  useUploadOpenOrderFileMutation,
  useLazyGetItemGroupEventsQuery,
  useCreateMessageForItemUpdateMutation,
  useGetOrderEventsQuery,
  useDismissFlagsMutation,
  useBulkUpdateItemGroupsMutation,
  useCreateItemGroupMutation,
  useLazyGetAllMembersQuery,
  useLazyGetMemberQuery,
  useCreateOrderMemberMutation,
  useLazyGetUserOrderMemberQuery,
  useGetFlagBySidQuery,
  useUpdateItemMutation,
  useDeleteItemGroupsMutation,
  useDeleteItemMutation,
  useLazyGetItemGroupByItemIdQuery
} = apiOrdersSlice
