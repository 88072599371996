import clsx from 'clsx'
import { type FC } from 'react'

interface EmptyBlockProps {
  className: string
}

export const EmptyBlock: FC<EmptyBlockProps> = ({ className }) => {
  return (
    <div className={clsx('bg-offBlack-lightest rounded-md mr-2', className)} />
  )
}
