import React, { type FC } from 'react'
import { type ItemGroupWithRelations } from 'types'
import { type Role } from '../utils/types'

interface TitleProps {
  itemGroups: ItemGroupWithRelations[]
  role: Role
}
export const Title: FC<TitleProps> = ({ itemGroups, role }) => {
  return (
    <h1 className="text-xl mb-5">
      {role === 'buyer' ? 'Edit' : 'Update'}
      {' '}
      {itemGroups?.length === 1 ? itemGroups?.[0].label : null}
      {itemGroups?.length > 1 ? `${itemGroups[0]?.label} and ${itemGroups.length - 1} more ${itemGroups?.length === 2 ? 'item' : 'items'}` : null}
    </h1>
  )
}
