import { createSlice } from '@reduxjs/toolkit'

export interface SessionState {
  loggedIn: boolean
  shouldRecordHotjar: boolean
  accessToken: string | null
  userType?: string
  userEmail: string | null
  userSid: string | null
  features?: string[]
}

const initialState: SessionState = {
  loggedIn: typeof localStorage.getItem('token') === 'string',
  accessToken: localStorage.getItem('token'),
  shouldRecordHotjar: false,
  userEmail: null,
  userSid: null,
  features: []
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      localStorage.setItem('token', action.payload)
      return {
        ...state,
        accessToken: action.payload,
        loggedIn: true,
      }
    },
    setLoggedIn(state) {
      return {
        ...state,
        loggedIn: true,
      }
    },
    setLoggedOut(state) {
      localStorage.removeItem('token')
      return {
        ...state,
        loggedIn: false,
      }
    },
    setUserType(state, action) {
      state.userType = action.payload
    },
    setShouldRecordHotjar(state, action) {
      state.shouldRecordHotjar = action.payload
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload
    },
    setUserSid(state, action) {
      state.userSid = action.payload
    },
    setFeatures(state, action) {
      state.features = action.payload
    }
  },
})

export const {
  setLoggedIn,
  setLoggedOut,
  setAccessToken,
  setUserType,
  setShouldRecordHotjar,
  setUserEmail,
  setUserSid,
  setFeatures
} =
  sessionSlice.actions
export default sessionSlice.reducer
