import { type FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import clsx from 'clsx'

export interface SelectorProps {
  title: string
  description: string
  onClick?: () => void
  active?: boolean
}

export const Selector: FC<SelectorProps> = ({
  title,
  description,
  onClick,
  active = false,
}) => {
  const handleClick = (): void => {
    onClick?.()
  }

  return (
    <div
      onClick={handleClick}
      className={`first:ml-0 mx-2 relative cursor-pointer rounded-lg bg-offBlack-dark border-offBlack-mid border-2 text-white sm:px-4 sm:py-7 p-2 hover:border-offWhite-dark transition flex-1 ${
        active ? 'border-teal-mid' : ''
      }`}
    >
      <div className={clsx("absolute top-2 right-3 flex justify-center items-center w-6 h-6",
        { 'bg-teal-mid rounded-full text-black': active })}>
        <FontAwesomeIcon
          className="w-4 h-4"
          icon={light('eye')}
        />
      </div>
      <div className="flex flex-col">
        <span className="text-2xl font-normal">{title !== 'undefined' ? title : '0'}</span>
        <span className="font-mono uppercase text-xs">{description}</span>
      </div>
    </div>
  )
}
