export const uploadCTBOptions = [
  {
    label: 'BOM',
    value: 'BOM',
  },
  {
    label: 'Inventory',
    value: 'INVENTORY',
  },
  {
    label: 'Open Order',
    value: 'OPEN_ORDERS',
  },
]
