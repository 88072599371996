import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiOpenOrderUploadSlice = createApi({
  reducerPath: 'openOrderUploadApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['OpenOrderUpload'],
  endpoints: (builder) => ({
    getOpenOrderUpload: builder.query({
      query: (sid: string) => `/v1/OpenOrderUploads/${sid}`,
      providesTags: ['OpenOrderUpload'],
    }),
  }),
})

export const {
  useLazyGetOpenOrderUploadQuery
} = apiOpenOrderUploadSlice
