import { Switch as ToggleSwitch } from '@headlessui/react'
import clsx from 'clsx'
import { type FC } from 'react'

interface SwitchProps {
  id: string
  enabled: boolean
  onChange: (value: boolean) => void
}

export const Switch: FC<SwitchProps> = ({ id, enabled, onChange }) => {
  return (
    <ToggleSwitch
      checked={enabled}
      onChange={onChange}
      className={clsx(
        {
          'bg-teal-mid': enabled,
          'bg-gray-200': !enabled,
        },
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
      )}
    >
      <span className="sr-only">{id}</span>
      <span
        aria-hidden="true"
        className={clsx(
          {
            'translate-x-5': enabled,
            'translate-x-0': !enabled,
          },
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </ToggleSwitch>
  )
}
