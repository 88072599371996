import { type ChangeEventHandler, type FC, type MouseEventHandler } from "react";
import { type ItemGroupWithRelations } from "../../../types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { StageBadge } from "../../StageBadge/StageBadge";
import dayjs from "dayjs";
import { formatName } from "../utils";
import { ItemGroupDate } from "../ItemGroupDate/ItemGroupDate";
import { TableRow } from "../TableRow/TableRow";

interface VendorRowProps {
  data: ItemGroupWithRelations
  index: number
  onSelect?: (item: any) => void
  hide?: string[]
  isCheck: boolean
  onClick?: (open: boolean, itemName: string, itemSid: string, itemGroupSid: string) => void
}

const VendorRow: FC<VendorRowProps> = ({
  data,
  index,
  onSelect,
  hide = [],
  isCheck,
  onClick,
}) => {
  const MAX_SIZE_NAME = 30

  const handleViewHistory: MouseEventHandler<HTMLSpanElement> = (e): void => {
    e.stopPropagation();
    onClick?.(true, data.label, data.item?.sid as string, data.sid)
  }

  const handleSelectItemGroup: MouseEventHandler<HTMLTableRowElement> | ChangeEventHandler<HTMLInputElement> = (e: any): void => {
    e.stopPropagation();
    onSelect?.(data.sid)
  }

  return (
    <TableRow
      key={data.sid}
      testId={`table-row-${index}`}
      index={index}
      onClick={handleSelectItemGroup as MouseEventHandler<HTMLTableRowElement>}
      selected={isCheck}
    >
      <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm font-medium">
        <input
          onChange={handleSelectItemGroup as ChangeEventHandler<HTMLInputElement>}
          className="mr-2 border-offBlack-light outline-0 rounded"
          type="checkbox"
          checked={isCheck}
          data-testid={`${data.sid}-checkbox`}
        />
        <span
          className="align-middle ml-1 text-blue-mid underline font-semibold cursor-pointer "
          onClick={handleViewHistory}
        >
          {formatName(data.label, MAX_SIZE_NAME)}
        </span>
      </td>
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0">
        <span className="font-medium">{`${
          data.quantity
        }`}</span>
      </td>
      {!hide.includes('order') ? (
        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
          <Link
            className="font-semibold antialiased text-blue-mid border-b border-blue-mid"
            to={`/orders/${data.item?.orderSid as string}`}
          >
            {data.order?.title ?? data.order?.uniqueName ?? data.order?.sid}
          </Link>
        </td>
      ) : null}
      {!hide.includes('buyer') ? (
        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0">
          {/* Hide it until there is a view of a buyer
          <Link
            className="font-semibold antialiased text-blue-mid border-b border-blue-mid"
            to={`/buyer/${data.organization.sid}`}
          >
            {data.organization.name}
          </Link> */}
          {data.organization.name}
        </td>
      ) : null}
      <td className="whitespace-nowrap py-4 pr-2 text-sm font-medium sm:pl-2 md:pl-0">
        <StageBadge itemGroup={data} />
      </td>
      {/**
       * Dates
       */}
      <ItemGroupDate date={data.shipDate} condition={data.shipped} />
      <ItemGroupDate date={data.deliveryDate} condition={data.delivered} />
      <ItemGroupDate date={data.dueDate} condition={data.deliveryDate !== 'Unknown' || data.shipDate !== 'Unknown'} />
      {/**
       * Update Requests
       */}
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium sm:pl-6 md:pl-0">
        <span
          className={clsx(
            'font-medium',
            data.lastUpdateDate === 'Not yet' && 'text-red-mid',
            dayjs
              .utc(data.lastUpdateDate)
              .isBefore(data.updateRequestLastCreated) && 'text-red-mid'
          )}
        >
          {data.lastUpdateDate}
        </span>
      </td>
    </TableRow>
  )
}

export default VendorRow
