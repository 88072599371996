import React, { Suspense } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { ShepherdTour } from 'react-shepherd'
import Order from 'views/Order/Order/Order'
import Project from 'views/Project/Project'
import ProjectItems from 'views/Project/ProjectItems/ProjectItems'
import { updateSteps, updateTourOptions } from 'views/Update/UpdateTour'

import { FirstLogin } from 'components/FirstLogin/FirstLogin'
import { ShowConfirmationModal } from 'components/ShowConfirmationModal/ShowConfirmationModal'

import { Layout } from '../components'
import { AddMembersNoAuth } from '../views/EmailOptOut/AddMembersNoAuth'
import { EmailOptOut } from '../views/EmailOptOut/EmailOptOut'
import Login from '../views/Login/Login'
import { Activity, Items } from '../views/Order'
import { Flags } from '../views/Organization/Flags/Flags'
import Register from '../views/Register/Register'
import Reports from '../views/Reports/Reports'
import { Communications } from '../views/Settings/Communications/Communications'
import { Profile } from '../views/Settings/Profile/Profile'
import Settings from '../views/Settings/Settings/Settings'
import { Team } from '../views/Settings/Team/Team'
import ProtectedRoutes from './ProtectedRoutes'
import * as paths from './paths'

const Track = React.lazy(async () => await import('views/Track/Track'))
const Update = React.lazy(async () => await import('views/Update/Update'))
const Plan = React.lazy(async () => await import('views/Plan/Plan'))
const Overview = React.lazy(
  async () => await import('views/Project/Overview/Overview')
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ShowConfirmationModal>
          <FirstLogin>
            <Outlet />
          </FirstLogin>
        </ShowConfirmationModal>
      }
    >
      <Route path={paths.EMAIL_OPT_OUT} element={<EmailOptOut />} />
      <Route path={paths.ADD_MEMBERS_NO_AUTH} element={<AddMembersNoAuth />} />
      <Route path={paths.LOGIN} element={<Login />} />
      <Route path={paths.REGISTER} element={<Register />} />
      <Route element={<ProtectedRoutes />}>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path={paths.DASHBOARD} element={<Navigate to="/track" />} />
          <Route path={paths.REPORT} element={<Reports />} />
          <Route path={paths.SETTINGS} element={<Settings />}>
            <Route index element={<Profile />} />
            <Route path={paths.TEAM} element={<Team />} />
            <Route path={paths.COMMUNICATIONS} element={<Communications />} />
            <Route path={paths.FLAGS} element={<Flags />} />
          </Route>
          <Route path={paths.TRACK}>
            <Route
              index
              element={
                <Suspense>
                  <Track />
                </Suspense>
              }
            />
          </Route>
          <Route path={paths.UPDATE}>
            <Route
              index
              element={
                <ShepherdTour
                  steps={updateSteps}
                  tourOptions={updateTourOptions}
                >
                  <Suspense>
                    <Update />
                  </Suspense>
                </ShepherdTour>
              }
            />
          </Route>
          <Route path={paths.ORDER} element={<Order />}>
            <Route index element={<Items />} />
            <Route path={paths.ACTIVITY} element={<Activity />} />
          </Route>
          <Route path={paths.PROJECT} element={<Project />}>
            <Route
              index
              element={
                <Suspense>
                  <Overview />
                </Suspense>
              }
            />
            <Route path={paths.PROJECT_ITEMS} element={<ProjectItems />} />
          </Route>
          <Route
            path={paths.PLAN}
            element={
              <Suspense>
                <Plan />
              </Suspense>
            }
          />
        </Route>
      </Route>
    </Route>
  )
)

export default router
