import React, { type FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components'
import { Slideover } from 'components/Slideover/Slideover'
import Dropzone from 'components/Dropzone/Dropzone'

import { useUploadOpenOrderFileMutation } from 'services/orders/Orders'

import { slideOverSelector } from 'store/selectors'
import { useAppDispatch } from 'store'
import { closeSlideOver } from 'store/slideOvers'
import { useGetFieldMappingQuery } from 'services/fieldMapping/FieldMapping'
import { type BOMUpload, type FieldMapping, type InventoryUpload, type OpenOrderUpload } from 'types'
import { openNotification } from 'store/notifications'
import { useLazyGetOpenOrderUploadQuery } from 'services/openOrderUpload/OpenOrderUpload'
import { RadioButtons } from '../RadioButtons/RadioButtons'
import { uploadCTBOptions } from './options'
import { useLazyGetBOMUploadQuery, useUploadBOMDataMutation } from '../../services/boms/Boms'
import { useLazyGetInventoryUploadQuery, useUploadInventoryDataMutation } from '../../services/inventory/Inventory'

export const filterFieldMappingByOrganization = (fieldMappings: FieldMapping[] , organizationSid: string): FieldMapping[] => {
  if(fieldMappings === undefined || organizationSid === undefined) return []
  return fieldMappings.filter(fieldMapping => fieldMapping.organizationSid === organizationSid && fieldMapping.importAllowed)
}

export const UploadCTBData: FC = () => {
  const { data: organizationSid, name } = useSelector(slideOverSelector)
  const show = name === 'UploadCTBData'

  const [file, setFile] = useState<File | null>(null)
  const [uploadType, setUploadType] = useState('BOM')
  const [uploadFile, { isLoading }] = useUploadOpenOrderFileMutation()
  const [uploadBOMData, { isLoading: isLoadingBOMData }] = useUploadBOMDataMutation()
  const [uploadInventoryData, { isLoading: isLoadingInventoryData }] = useUploadInventoryDataMutation()

  const { data: fieldMappings, isLoading: isLoadingFieldMappings } = useGetFieldMappingQuery(1)
  const [getOpenOrderUpload] = useLazyGetOpenOrderUploadQuery()
  const [getBOMUPload] = useLazyGetBOMUploadQuery()
  const [getInventoryUpload] = useLazyGetInventoryUploadQuery()

  const dispatch = useAppDispatch()

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  const handleFile = (uploadedFile: File): void => {
    setFile(uploadedFile)
  }

  const filteredFieldMappings = filterFieldMappingByOrganization(fieldMappings?.fieldMappings, organizationSid)
  const [template, setTemplate] = useState<string | null>(filteredFieldMappings[0]?.sid);

  useEffect(()=> {
    if (template === undefined) {
      setTemplate(filteredFieldMappings[0]?.sid)
    }
  }, [filteredFieldMappings])

  const handleTemplateChange = (selector: any): void => {
    setTemplate(selector.target.value);
  };

  async function sleepInMS(ms: number): Promise<any> {
    return await new Promise((resolve) => setTimeout(resolve, ms))
  }

  const refetchOpenOrderUploadUntilFinished = async (sid: string): Promise<void> => {
    const {status} = (await getOpenOrderUpload(sid).unwrap())
    await sleepInMS(1000)
    if (status === 'processing') {
      await refetchOpenOrderUploadUntilFinished(sid)
    }
  }

  const refetchBOMUploadUntilFinished = async (sid: string): Promise<void> => {
    const {status} = (await getBOMUPload(sid).unwrap())
    await sleepInMS(1000)
    if (status === 'processing') {
      await refetchBOMUploadUntilFinished(sid)
    }
  }

  const refetchInventoryUploadUntilFinished = async (sid: string): Promise<void> => {
    const {status} = (await getInventoryUpload(sid).unwrap())
    await sleepInMS(1000)
    if (status === 'processing') {
      await refetchInventoryUploadUntilFinished(sid)
    }
  }

  const showInProgressNotification = (): void => {
    dispatch(openNotification({
      title: 'New data processing',
      message: 'Your data is being added to Factor. Please do not close your browser',
      type: 'success',
      show: true,
    }))
  }

  const refetchBOMUploadStatusUntilFinished = async (uploadBOMDataSid: string): Promise<void> => {
    await refetchBOMUploadUntilFinished(uploadBOMDataSid)
    const {status: BOMUploadStatus, rowCountSuccess, rowCountFailure} = (await getBOMUPload(uploadBOMDataSid).unwrap()) as BOMUpload
    if(BOMUploadStatus === 'finished' && rowCountFailure === 0) dispatch(openNotification({title: "All items added", message: "Your BOMs have been added successfully!", type: 'success', show: true}))
    else if(BOMUploadStatus === 'finished' && rowCountFailure > 0) dispatch(openNotification({title: "New BOMs added, but there were some issues", message: `${rowCountSuccess} BOMs were added successfully, but ${rowCountFailure} BOMs failed. Try checking your CSV for blank fields or other issues, and try uploading any rows with problems again.`, type: 'warning', show: true}))
    else  dispatch(openNotification({title: "New BOM upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
  }

  const refetchInventoryUploadStatusUntilFinished = async(inventoryUploadSid: string): Promise<void> => {
    await refetchInventoryUploadUntilFinished(inventoryUploadSid)
    const {status: inventoryUploadStatus, rowCountSuccess, rowCountFailure} = (await getInventoryUpload(inventoryUploadSid).unwrap()) as InventoryUpload
    if(inventoryUploadStatus === 'finished' && rowCountFailure === 0) dispatch(openNotification({title: "All inventory items added", message: "Your items have been added successfully!", type: 'success', show: true}))
    else if(inventoryUploadStatus === 'finished' && rowCountFailure > 0) dispatch(openNotification({title: "New inventory items added, but there were some issues", message: `${rowCountSuccess} items were added successfully, but ${rowCountFailure} items failed. Try checking your CSV for blank fields or other issues, and try uploading any rows with problems again.`, type: 'warning', show: true}))
    else  dispatch(openNotification({title: "New Inventory upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
  }

  const refetchOpenOrderUploadStatusUntilFinished = async(openOrderUploadSid: string): Promise<void> => {
    await refetchOpenOrderUploadUntilFinished(openOrderUploadSid)
    const {status: openOrderUploadStatus, rowCountSuccess, rowCountFailure} = (await getOpenOrderUpload(openOrderUploadSid).unwrap()) as OpenOrderUpload
    if(openOrderUploadStatus === 'finished' && rowCountFailure === 0) dispatch(openNotification({title: "All items added", message: "Your items have been added successfully! Your suppliers can now use Factor to provide updates on these orders.", type: 'success', show: true}))
    else if(openOrderUploadStatus === 'finished' && rowCountFailure > 0) dispatch(openNotification({title: "New items added, but there were some issues", message: `${rowCountSuccess} items were added successfully, but ${rowCountFailure} items failed. Try checking your CSV for blank fields or other issues, and try uploading any rows with problems again.`, type: 'warning', show: true}))
    else  dispatch(openNotification({title: "New item upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
  }

  const handleSave = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', file as File)
    data.append('organizationSid', organizationSid)
    if (template !== '' && template !== undefined) data.append('fieldMappingSid', template as string)

    try {
      if (uploadType === 'BOM') {
        const { sid: uploadBOMDataSid } = (await uploadBOMData(data).unwrap()) as BOMUpload
        handleClose();
        showInProgressNotification();
        refetchBOMUploadStatusUntilFinished(uploadBOMDataSid);
      } else if (uploadType === 'INVENTORY') {
        const { sid: inventoryUploadSid } = (await uploadInventoryData(data).unwrap()) as InventoryUpload
        handleClose()
        showInProgressNotification();
        refetchInventoryUploadStatusUntilFinished(inventoryUploadSid);
      } else if (uploadType === 'OPEN_ORDERS') {
        const { sid: openOrderUploadSid } = (await uploadFile(data).unwrap()) as OpenOrderUpload
        handleClose()
        showInProgressNotification();
        refetchOpenOrderUploadStatusUntilFinished(openOrderUploadSid);
      }
    } catch (e) {
      dispatch(openNotification({title: "New item upload failed", message: (<span> Your CSV upload failed. Please try again or contact <a href = "mailto: support@factor.io">support@factor.io</a> for more assistance.</span>), type: 'error', show: true}))
      handleClose()
    }
  }

  const handleChangedUploadType = (value: string): void => {
    setUploadType(value)
  }

  if (isLoadingFieldMappings) return <div>Loading...</div>

  return (
    <Slideover open={show} onClose={handleClose} title="Add new Plan data">
      <p className="text-sm"> Add inventory, open orders or BOMs to Factor to update your clear-to-build reporting. Choose the data type and ssociated template so we can accurately import your data.
      <br/><br/>Please note, if the file you add does not align with the data type and template, your upload will fail</p>
      <br/>
      <div>
        <RadioButtons
          className="mb-5"
          options={uploadCTBOptions}
          onChange={handleChangedUploadType}
          selected={uploadType}
        />
      </div>
      <div className='relative flex flex-col'>
        <label className='absolute text-xs text-gray-400 pl-2 mt-1'>Template</label>
        <select name="template" defaultValue={template ?? ''} onChange={handleTemplateChange} className='text-sm pt-5 text-black-400 border border-offBlack-lightest rounded-lg shadow-lg'>
          {filteredFieldMappings.map(({name, sid}: {name: string, sid: string | null}) => {
            return <option value={sid ?? ''} key={sid}>{name}</option>;
          })}
          <option value=''>Factor Default</option>
        </select>
      </div>
      <div className="mt-5">
        <Dropzone
          onChange={handleFile}
          title="Drag and drop your open orders files here"
          subtitle="Accepted file formats: .csv"
        />
      </div>
      <div className="flex justify-end mt-auto absolute bottom-0 right-6 z-50">
        <Button style="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          cySelector='savePlanItems'
          disabled={file == null}
          loading={isLoading || isLoadingBOMData || isLoadingInventoryData}
        >
          Add data
        </Button>
      </div>
    </Slideover>
  )
}
