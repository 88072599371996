import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from '../baseQuery'

export const apiClearToBuildWebSlice = createApi({
  reducerPath: 'clearToBuildWebApi',
  baseQuery,
  tagTypes: ['ClearToBuildWeb','BomLines'],
  endpoints: (builder) => ({
    getClearToBuildsByDatesAndBom: builder.query({
      query: ({ctbStartDate, ctbEndDate, ctbReferenceSids}) => ({
          url: `/api/cleartobuild`,
          params: {ctbStartDate, ctbEndDate, ctbReferenceSids}
      }),
      providesTags: ['ClearToBuildWeb'],
    }),
    getBomLineCTB: builder.query({
      query: ({ctbStartDate, ctbReferenceSids}) => ({
        url: `/api/cleartobuild?ctbStartDate=${ctbStartDate}&${ctbReferenceSids}`,
      }),
      providesTags: ['ClearToBuildWeb'],
    }),
    getClearToBuildsByBomsAndDate: builder.query({
      query: ({ctbStartDate, ctbReferenceSids}) => ({
        url: `/api/cleartobuild?ctbStartDate=${ctbStartDate}&ctbReferenceSids=${ctbReferenceSids}`,
      }),
      providesTags: ['ClearToBuildWeb'],
    }),
    getWebBomLinesByBomSid: builder.query({
      query: ({
                bomSid,
                inventoryCountLessThanEqualTo,
                orderedQuantityLessThanEqualTo,
                orderBy,
                sortOrder,
              }: {
        bomSid: string
        inventoryCountLessThanEqualTo?: number
        orderedQuantityLessThanEqualTo?: number
        orderBy?: string
        sortOrder?: 'Asc' | 'Desc'
      }) => ({
        url: `/api/bomlines`,
        params: {
          bomSid,
          inventoryCountLessThanEqualTo,
          orderedQuantityLessThanEqualTo,
          orderBy,
          sortOrder,
        },
      }),
      providesTags: ['BomLines'],
    }),
  }),
})

export const {
  useGetClearToBuildsByDatesAndBomQuery,
  useGetBomLineCTBQuery,
  useGetClearToBuildsByBomsAndDateQuery,
  useGetWebBomLinesByBomSidQuery
} = apiClearToBuildWebSlice
