import { type FC, type ReactNode } from 'react'

interface BackButtonProps {
  Icon: JSX.Element
  children: ReactNode
  iconLeft?: boolean
  accentColor?: 'primary' | 'warning'
  onClick?: () => void
  testId?: string
}

const accentColors = {
  primary: 'text-teal-mid',
  warning: 'text-orange',
}

export const IconLink: FC<BackButtonProps> = ({
  Icon,
  children,
  iconLeft = false,
  accentColor = 'primary',
  onClick,
  testId,
}: BackButtonProps): JSX.Element => {
  return (
    <div
      data-testid={testId}
      className={`flex ${
        iconLeft ? '' : 'flex-row-reverse'
      } cursor-pointer w-28 ${accentColors[accentColor]}`}
      onClick={onClick}
    >
      <div className={`${iconLeft ? 'mr-2' : 'ml-2'}`}>{Icon}</div>
      <div className="font-mono uppercase text-sm font-light antialiased flex items-center">{children}</div>
    </div>
  )
}
