export const DASHBOARD = '/'
export const TRACK = '/track'
export const UPDATE = '/update'

export const REPORT = '/report'
export const SETTINGS = '/settings'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const ORDER = '/orders/:orderSid'
export const ACTIVITY = 'activity'

export const ITEMS = 'items'
export const DETAILS = 'details'

export const PLAN = '/plan'
export const PROJECT = '/projects/:bomSid'
export const OVERVIEW = 'overview'
export const PROJECT_ITEMS = 'project_items'

export const PROFILE = 'my-profile'
export const TEAM = 'team'
export const EMAIL_OPT_OUT = '/emailOptOut/:token'
export const ADD_MEMBERS_NO_AUTH = 'add-members-no-auth/:token'
export const COMMUNICATIONS = 'communications'
export const FLAGS = 'flags'
