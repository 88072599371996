import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

interface updateOrgPayload {
  organizationSid?: string,
  defaultUnresponsiveAfterBusinessDays?: number,
  pastDueBusinessDays?: number,
  defaultItemGroupCadence?: string | null,
  defaultPastDueCadenceSid?: string | null,
  sendAutomatedUpdateRequests?: boolean | null,
}

export const apiOrganizationSettingsSlice = createApi({
  reducerPath: 'organizationSettingsApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['OrganizationSettings'],
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: (organizationSid: string) => `/v1/OrganizationSettings/${organizationSid}`,
      providesTags: ['OrganizationSettings'],
    }),
    postUpdateSettings: builder.mutation({
      query: ({ organizationSid, ...body }: updateOrgPayload) => ({
        url: `/v1/OrganizationSettings/${organizationSid}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
      invalidatesTags: ['OrganizationSettings'],
    }),
  })
})

export const {
  useGetSettingsQuery,
  usePostUpdateSettingsMutation,
} = apiOrganizationSettingsSlice
