import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiFileExportsSlice = createApi({
  reducerPath: 'fileExportsApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['FileExports'],
  endpoints: (builder) => ({
    getFileExportContent: builder.query({
      query: (fileExportSid: string) => `/v1/FileExports/${fileExportSid}/Content`,
      providesTags: ['FileExports'],
    }),
    postFileExport: builder.mutation({
      query: (body: any) => ({
        url: `/v1/FileExports`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FileExports'],
    }),
    getFileExport: builder.query({
      query: (fileExportSid: string) => `/v1/FileExports/${fileExportSid}`,
      providesTags: ['FileExports'],
    }),
  }),
})

export const {
  useLazyGetFileExportContentQuery,
  useLazyGetFileExportQuery ,
  usePostFileExportMutation,
} = apiFileExportsSlice
