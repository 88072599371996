import { type FC } from 'react'
import clsx from 'clsx'

interface Option {
  content: string
  active: boolean
  onClick?: () => void
}

interface TabsProps {
  options: Option[]
}

export const Tabs: FC<TabsProps> = ({ options }: TabsProps): JSX.Element => {
  const getBorders = (index: number): string => {
    const dynamicClasses = {
      0: 'rounded-l-lg border-y-2 border-l-2',
      [options.length - 1]: 'rounded-r-lg border-r-2 border-2',
    }
    return dynamicClasses[index] ?? 'border-l-2 border-y-2'
  }
  return (
    <>
      <div className="flex">
        {options.map((option: Option, index: number) => (
          <div
            data-testid={`tab-${index}`}
            key={`tab-${index}`}
            onClick={option.onClick}
            className={clsx(
              getBorders(index),
              { 'bg-offBlack-dark text-offWhite-light': option.active },
              { 'bg-white': !option.active },
              'p-3 shadow  border-none cursor-pointer'
            )}
          >
            <div
              className={clsx('self-center text-sm font-sans', {
                'font-semibold': option.active,
              })}
            >
              {option.content}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
