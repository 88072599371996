import React from 'react'

export interface CheckboxProps {
  label: string
  name?: string
  handleChange: (e: React.SyntheticEvent) => void
  className?: string
  checked?: boolean
  disabled?: boolean
  dataTestId?: string
}

export const Checkbox = ({
  label,
  name,
  handleChange,
  className = '',
  checked = false,
  disabled = false,
  dataTestId = '',
}: CheckboxProps): JSX.Element => {
  const activeClasses = 'checked:bg-teal-light hover:bg-teal-light focus:text-teal-light focus-visible:text-teal-light default:right-2 border-solid border-2 border-gray-400 border-offBlack-light outline-0 rounded'
  const disabledClasses = 'checked:bg-offBlack-light hover:bg-offBlack-light focus:text-offBlack-dark focus-visible:text-offBlack-light default:right-2 border-solid border-2 border-gray-800 border-offBlack-light outline-0 rounded'

  return (
    <div className={className}>
      <input
        data-testid={dataTestId}
        id={name}
        checked={checked}
        className={`${disabled ? disabledClasses : activeClasses}`}
        name={name}
        type="checkbox"
        onChange={(e) => {
          handleChange(e)
        }}
      />
      <label className="ml-2 text-offBlack-dark text-md" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}
