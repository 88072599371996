import React, { type FC, type ReactNode } from 'react'
import { type FactorEvent, type MessageProps, UpdateKey } from './types'
import dayjs from 'dayjs'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch } from "../../store";
import { getDateOrDefault } from '../../utils/formatDatesForRender/formatDatesForRender'
import {
  FlagCreateEvent,
  ItemGroupCreateEvent,
  ItemGroupUpdateEvent,
  MessageCreateEvent, 
  OrderInviteCreateEvent, 
  OrderInviteDeleteEvent,
  UpdateRequestCreateEvent,
  InboundEmailRelationsCreateEvent
} from "../../utils/timelineEvent/timelineEvent";
import { openSlideOver } from "../../store/slideOvers";

export const Message: FC<MessageProps> = ({ data, itemName, selectedItemGroups = [], role = 'buyer', showItemUniqueName = true }) => {
  const { event: eventData, eventType } = data
  const event = eventData as FactorEvent
  const dispatch = useAppDispatch()

  const openQualityIssueModal = (): void => {
    dispatch(
      openSlideOver({
        name: 'QualityIssue',
        data: {
          eventData,
          itemName,
          selectedItemGroups,
          role,
        },
      })
    )
  }

  const getBooleanValue = (key: UpdateKey): boolean => {
    return event?.updates[key] !== null
      ? (event?.updates[key]?.current as unknown as boolean)
      : (event?.itemGroup[key] as boolean)
  }

  const getShipDateChange = (): ReactNode => {
    return (
      <div key={`shipDate-${event?.id}`}>
        <span>
          Ship date changed,{' '}
          {getBooleanValue(UpdateKey.SHIPPED) ? 'shipped' : 'not shipped'}: Est
          ship date:{' '}
        </span>
        <span className="line-through pr-1">
          {' '}
          {getDateOrDefault(event?.updates?.shipDate?.previous, 'Unknown')}{' '}
        </span>
        <FontAwesomeIcon icon={light('arrow-right')} />
        <span className="font-bold pl-1">
          {' '}
          {getDateOrDefault(event?.updates?.shipDate?.current, 'Unknown')}
        </span>
      </div>
    )
  }

  const getDeliveryDateChange = (): ReactNode => {
    return (
      <div key={`deliveryDate-${event?.id}`}>
        <span>
          Delivery date changed,{' '}
          {getBooleanValue(UpdateKey.DELIVERED) ? 'delivered' : 'not delivered'}
          : Est delivery date:{' '}
        </span>
        <span className="line-through pr-1">
          {getDateOrDefault(event?.updates?.deliveryDate?.previous, 'Unknown')}
        </span>
        <FontAwesomeIcon icon={light('arrow-right')} />
        <span className="font-bold pl-1">
          {getDateOrDefault(event?.updates?.deliveryDate?.current, 'Unknown')}
        </span>
      </div>
    )
  }

  const getShippedOn = (): ReactNode => {
    return (
      <div key={`shippedOn-${event?.id}`}>
        <span>
          Shipped on:{' '}
          {dayjs(event?.updates?.shipDate?.current).format('MM/DD/YYYY')}
        </span>
      </div>
    )
  }

  const getDeliveredOn = (): ReactNode => {
    return (
      <div key={`deliveredOn-${event?.id}`}>
        <span>
          Delivered on:{' '}
          {dayjs(event?.updates?.deliveryDate?.current).format('MM/DD/YYYY')}
        </span>
      </div>
    )
  }

  const getTrackingInfo = (): ReactNode => {
    return (
      <div key={`trackingInfo-${event?.id}`}>
        <span>
          Tracking info added: {event?.updates?.trackingNumber?.current}
        </span>
      </div>
    )
  }

  const getDueDateChange = (): ReactNode => {
    return (
      <div key={`dueDate-${event?.id}`}>
        <span>
          Due date changed:{' '}
        </span>
        <span className="line-through pr-1">
          {getDateOrDefault(event?.updates?.dueDate?.previous, 'Unknown')}
        </span>
        <FontAwesomeIcon icon={light('arrow-right')} />
        <span className="font-bold pl-1">
          {getDateOrDefault(event?.updates?.dueDate?.current, 'Unknown')}
        </span>
      </div>
    )
  }

  const getItemGroupUpdateMessage = (): ReactNode => {
    return (
      <div key={`itemGroupUpdateMessage-${event?.id}`}>
        {event?.updates?.shipDate !== null && getShipDateChange()}
        {event?.updates?.deliveryDate !== null && getDeliveryDateChange()}
        {(event?.updates?.shipped?.current as unknown as boolean) &&
          getShippedOn()}
        {(event?.updates?.delivered?.current as unknown as boolean) &&
          getDeliveredOn()}
        {event?.updates?.trackingNumber !== null && getTrackingInfo()}
        {event?.updates?.dueDate !==  null && getDueDateChange()}
      </div>
    )
  }

  const trimmedEmailContent = (): string => {
    return event.emailContent && event.emailContent.length > 150
    ? event.emailContent.substring(0, 150) + "..."
    : event.emailContent ?? "Email update provided";
  }

  switch (eventType) {
    case FlagCreateEvent:
      return (
        <>
          {(event.flag.type === 'qualityIssue' && (
            <div
              className='align-middle ml-1 text-blue-mid underline font-semibold cursor-pointer'
              onClick={openQualityIssueModal}
            >
              added a flag to this item
            </div>
          )) || (<span>added a flag to this item</span>)
          }
        </>
      )
    case ItemGroupCreateEvent:
        return (
            <>
              <span>
                created <span className="italic">{itemName}</span> in Factor
              </span>
            </>
      )
    case ItemGroupUpdateEvent:
      return (
        <>
          {event.confirmed === true && event.confirmed !== null ? (
            <>
              <span>
                {event.createdBy?.human?.name}
              </span>
              <span>confirmed dates</span>
            </>
          ) : (
            <>
              <span>
                provided an update on <span className="italic">{itemName}{ showItemUniqueName && event?.itemUniqueName ? ' for ' + event?.itemUniqueName : ''}:</span>
              </span>
              <span className="my-2 block">{getItemGroupUpdateMessage()}</span>
            </>
          )}
        </>
      )
    case MessageCreateEvent:
      return (
        <>
            {(event?.message?.content as string | null) !== null ? (
                <span>
                    <span> left a message on <span className="italic">{itemName}:</span></span>
                    <span className="block bg-white p-3 shadow-lg rounded-xl italic my-2">{`"${event?.message?.content}"`}</span>
                </span>
                ) : null}
        </>
      )
    case UpdateRequestCreateEvent: {
      const showMessage: boolean = (event?.updateRequest?.note as string | null) !== '' && (event?.updateRequest?.note as string | null) !== null
      return (
        <>
          <span>
            requested an update on <span className="italic">{itemName}{showMessage ? ':' : ''}</span>
          </span>
          {showMessage ? (
            <span className="block bg-white p-3 shadow-lg rounded-xl italic my-2">{`"${event?.updateRequest?.note}"`}</span>
          ) : null}
        </>
      )
    }
    case OrderInviteCreateEvent: {
      return (
        <span>added order members: {event.OrderInvite_Email}</span>
      )
    }
    case OrderInviteDeleteEvent: {
      return (
        <span>removed themselves from this order</span>
      )
    }
    case InboundEmailRelationsCreateEvent:
      return (
        <>
          <span>{trimmedEmailContent()}</span>
        </>
      )
    default:
      return <span></span>
  }
}
