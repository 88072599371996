import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'
import { type FC, Fragment, type JSX } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../store'
import { closeNotification } from '../../store/notifications'
import { notificationSelector } from '../../store/selectors'

export interface NotificationProps {
  title: string
  message: string
  type?: 'success' | 'error' | 'warning'
  show?: boolean
}

export const Notification: FC<NotificationProps> = ({
  title,
  message,
  type = 'success',
}: NotificationProps): JSX.Element => {
  const { show } = useSelector(notificationSelector)
  const dispatch = useAppDispatch()
  const accent = {
    success: 'green-500',
    error: 'red-500',
    warning: 'yellow-500',
  }

  const icons = {
    success: <FontAwesomeIcon icon={light('check-circle')} />,
    error: <FontAwesomeIcon icon={light('xmark-circle')} />,
    warning: <FontAwesomeIcon icon={light('exclamation-circle')} />,
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <div
        className={`absolute z-50 w-full max-w-md mx-auto overflow-hidden bg-offWhite-light right-5 top-5 rounded-lg shadow-xl p-5`}
      >
        <button
          onClick={() => {
            dispatch(closeNotification({ show: false, title: '', message: '' }))
          }}
          className="absolute top-2 right-2 text-offBlack-light focus:outline-none z-50 cursor-pointer"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex">
          <div className={`w-10 h-10 mr-2 text-xl text-${accent[type]}`}>
            {icons[type]}
          </div>
          <div data-cy="notificationMessage">
            <h4 className="text-sm font-bold text-offBlack-dark mb-2">
              {title}
            </h4>
            <p className="text-offBlack-light text-sm">{message}</p>
          </div>
        </div>
      </div>
    </Transition.Root>
  )
}
