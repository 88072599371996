import { type FC, useEffect, useState } from 'react'
import FBDatepicker from 'flowbite-datepicker/Datepicker'
import { FormField } from '../FormField/FormField'
import { RadioButtons } from '../RadioButtons/RadioButtons'
import { Popover } from '@headlessui/react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

const mutationObserverOptions = {
  attributes: true,
  childList: true,
  subtree: true,
}

interface DatePickerProps {
  value?: string | null
  onChange?: (date: string | null) => void
  className?: string
  label?: string
  error?: string | null
  name?: string
  minDate?: string | null
  cySelector?: string | null
}

enum DateOption {
  known = 'KNOWN',
  unknown = 'UNKNOWN',
}

const radioOptions = [
  { label: 'Known date', value: DateOption.known },
  { label: 'Unknown date', value: DateOption.unknown },
]

const unknownDateString =
  DateOption.unknown.charAt(0).toUpperCase() +
  DateOption.unknown.slice(1).toLowerCase()

export const Datepicker: FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  className,
  error = null,
  name = 'date',
  minDate = null,
  cySelector
}) => {
  const [loaded, setLoaded] = useState(false)
  const [calendarCel, setCalendarCel] = useState<Element | null>(null)
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [pickerValue, setPickerValue] = useState<string | null>('')
  const [selectedDateOption, setSelectedDateOption] = useState<boolean>(false)

  const isSelectedValueBeforeMinDate = dayjs(value, 'MM/DD/YY').isSameOrBefore(dayjs(minDate, 'MM/DD/YY'));
  const currentValue = isSelectedValueBeforeMinDate ? minDate : value;

  useEffect(()=> {
    if (value !== selectedDate) {
      setSelectedDate(value as string)
    }
  }, [value])

  useEffect(() => {
    setSelectedDate(value != null ? value : unknownDateString)
    setSelectedDateOption(value != null)
  }, [])

  const showPicker = (): void => {
    setLoaded(false)
    setPickerValue('')
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }

  const handleSelectDateOption = (value: string): void => {
    const isKnown = value === DateOption.known
    setSelectedDateOption(isKnown)
    if (isKnown) {
      setSelectedDate('')
    } else {
      onChange?.(null)
      setSelectedDate(unknownDateString)
    }
  }

  const handleSelectDate = (): void => {
    const newDate = document.querySelector('.datepicker-cell.selected')
    const dateString = newDate?.getAttribute('data-date') as string

    if (dateString !== undefined) {
      const date = dayjs(Number(dateString)).format('MM/DD/YY')
      if (selectedDate === '' || date !== selectedDate) {
        setSelectedDate(date)
        setPickerValue(date)
        onChange?.(date)
      }
    }
  }

  const observer = new MutationObserver(handleSelectDate)
  useEffect(() => {
    if (loaded) {
      const datepickerEl = document?.getElementById('datepickerId')
      if (datepickerEl != null) {
        // eslint-disable-next-line no-new
        new FBDatepicker(datepickerEl, {
          minDate
        })
        const calendarCell = document.querySelector('.datepicker-cell')
        setCalendarCel(calendarCell)
      }
    }
  }, [loaded, minDate])

  useEffect(() => {
    if (calendarCel != null) {
      observer.observe(calendarCel, mutationObserverOptions)
      return () => {
        if (observer !== null) {
          observer.disconnect()
        }
      }
    }
  }, [calendarCel])

  const handleClose = (callback: () => void): void => {
    if (
      selectedDate !== '' &&
      pickerValue !== '' &&
      selectedDate === pickerValue
    ) {
      callback?.()
    }
  }

  return (
    <div className={clsx('relative w-full relative', className)}>
      <Popover>
        <Popover.Button onClick={showPicker} className="w-full">
          <FormField
            className="w-full z-0"
            label={label}
            inputName={name}
            defaultValue={selectedDate}
            readOnly
            error={error}
            cySelector={cySelector as string}
          />
        </Popover.Button>
        <Popover.Panel className="z-40 border rounded-md absolute mt-2 left-1/2 max-w-fit -translate-x-48 transform px-4 sm:px-0">
          {({ close }) => (
            <>
              {loaded ? (
                <div className="z-10 relative bg-white p-5 w-fit shadow-xl rounded-md">
                  <RadioButtons
                    sm
                    options={radioOptions}
                    onChange={handleSelectDateOption}
                    selected={
                      selectedDateOption ? DateOption.known : DateOption.unknown
                    }
                  />
                  {selectedDateOption ? null : (
                    <div className="py-12">
                      <h3 className="text-sm font-bold text-center mb-5">
                        Unknown date selected
                      </h3>
                      <p className="min-w-[241px] text-xs">
                        Factor will display “Unknown” instead of an date for
                        this value. Order owners will see that this date has
                        been updated to Unknown.
                      </p>
                    </div>
                  )}
                  <div
                    className={clsx('z-10 mt-5 w-fit h-fit', {
                      hidden: !selectedDateOption,
                    })}
                    onClick={() => {
                      handleClose(close)
                    }}
                  >
                    <div id="datepickerId" data-date={currentValue}></div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  )
}
