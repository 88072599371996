import { type FC } from 'react'
import { Badge } from '../Badge/Badge'
import { itemGroupStage } from '../../utils/itemGroupStage/itemGroupStage'
import { type ItemGroupWithRelations } from '../../types'

interface StageBadgeProps {
  itemGroup: ItemGroupWithRelations
}

export const StageBadge: FC<StageBadgeProps> = ({ itemGroup }) => {
  const stage = itemGroupStage(itemGroup)

  const styles = {
    success: {
      text: 'Delivered',
    },
    warning: {
      text: 'Shipped',
    },
    empty: {
      text: 'Open',
    },
  }

  return (
    <Badge color={stage}>
      <div>
        <span>{styles[stage].text}</span>
      </div>
    </Badge>
  )
}
