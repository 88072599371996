import {
  type BaseQueryFn,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react'
import { setAccessToken, setLoggedOut } from '../store/session'

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const accessToken: string | null = localStorage.getItem('token')
    if (accessToken != null) {
      headers.set(
        'Authorization',
        `AKh7qhk7m9kfbd377m3y8jqg4ft4qh:${accessToken}`
      )
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error != null && result.error.status === 401) {
    const { data }: any = await baseQuery('/accesstoken/', api, extraOptions)

    if (data as boolean) {
      api.dispatch(setAccessToken(data as string))

      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(setLoggedOut())
    }
  }
  return result
}

export default baseQueryWithReauth
