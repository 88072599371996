import Logo from 'assets/factor_logo.svg';
import { Link, useParams } from "react-router-dom";



import { useGetSecureTokenQuery } from "../../services/secureTokens/SecureTokens";
import { FormField } from "../../components/FormField/FormField";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePostCreateInvitesAndAddMeMutation } from "../../services/orderMembers/OrderMembers";
import { Button, Checkbox } from "../../components";
import { type MessageInfo, getMessageInfo } from "../../utils/getMessageInfo/getMessageInfo";
import { openNotification } from "../../store/notifications";
import { useAppDispatch } from "../../store";
import { Notification } from "../../components/Notification/Notification";
import { useSelector } from "react-redux";
import { notificationSelector } from "../../store/selectors";


export const AddMembersNoAuth = (): JSX.Element => {
  const { token } = useParams()
  const { data: optOut, isLoading } = useGetSecureTokenQuery(token ?? '')
  const [createOrderMembersAndMe] = usePostCreateInvitesAndAddMeMutation()
  const dispatch = useAppDispatch()
  const notification = useSelector(notificationSelector)

  const emailListSchema = Yup.string().test(
    'is-email-list',
    'Invalid email list',
    function (value) {
      if (!value) {
        return true;
      }
      const emailArray = value.split(',').map((email) => email.trim());
      return emailArray.every((email) => Yup.string().email().isValidSync(email));
    }
  )

  const formik = useFormik({
    initialValues: {
      emailList: ''
    },
    initialErrors: {
      emailList: '',
    },
    initialTouched: {
      emailList: false,
    },
    validationSchema: Yup.object({
      emailList: emailListSchema,
    }),
    onSubmit: async (data) => {
      const emailList: string[] = data.emailList.split(',').map(email => email.trim())
      try {
        await createOrderMembersAndMe({
          orderSid: optOut.optOut,
          emails: emailList.join(','),
          addMe: false,
          removeMe: true,
          emailOptOutToken: token ?? '',
        })

        const messageInfo : MessageInfo = getMessageInfo(emailList.join(','), false, true)
        dispatch(
          openNotification({
            title: messageInfo?.title,
            message: messageInfo?.message,
            show: true,
          })
        )
      } catch(error) {
        dispatch(
          openNotification({
            title: 'Something went wrong',
            message:
              'We were not able to complete your order member change. Please try again or contact support@factor.io.',
            type: 'error',
            show: true,
          })
        )
      }
    },
    enableReinitialize: true,
  })

  if (isLoading) return <div>Loading...</div>

  return (
    <div className={'px-[400px] pt-10'}>
      <Notification {...notification} />
      <div></div>
      <div className={'col-end-3'}>
        <img src={Logo} alt="Factor Logo" />
      </div>
      <div className='shadow-xl bg-offWhite-light mt-5 p-5 rounded-2xl px-10'>
        <div>
          <h2 className='text-xl'>Change contacts for {optOut.orderName}</h2>
        </div>
        <div className='mt-3'>
          <p className='text-sm'>
            Enter the email address(es) of the correct contacts for this order, separated by commas. Then, select an
            option to remove yourself from this order.
          </p>
        </div>
        <div>
          <div className="py-5">
            <FormField
              className="mb-5"
              label="Email address"
              inputName="emailList"
              onChange={formik.handleChange}
              onSubmit={formik.handleSubmit}
            />
          </div>
          <div>
            <div>
              <Checkbox
                className='text-sm mr-2 border-offBlack-light outline-0 rounded'
                checked={true}
                name="removeMeFromOrder"
                label="Remove me from this order"
               handleChange={() => {}}/>
              <div className='pl-6 pb-4 text-xs text-offBlack-light'>
                You will no longer receive any notifications about this order, and other Factor users will not be able
                to add you to the order again.
              </div>
              {/* <Checkbox
              className='text-sm mr-2 border-offBlack-light outline-0 rounded'
              handleChange={}
              checked={}
              name="removeMeFromOrder"
              label="Never notify me about any orders from Organization"
            />
            <div className='pl-6 pb-4 text-xs text-offBlack-light'>
              You will never receive notifications about any orders from this organization again.
            </div> */}
            </div>
          </div>
          <Link className="underline font-semibold text-blue-mid text-xs cursor-pointer mt-5"
                to={`https://coda.io/@factor-support/upload-csvs-in-factor`} >
            Learn more about order members in Factor
          </Link>
        </div>

        <div className='flex justify-end mt-5'>
          <Button
            disabled={
              !formik.isValid ||
              !formik.dirty || false || (!(formik.values.emailList.trim() !== ''))
            }
            className='self-end'
            style='primary'
            onClick={formik.handleSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}
