import { TopSection } from '../../components/TopSection/TopSection'
import React, { type FC } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { BackLink } from 'components/BackLink/BackLink'
import { Headline } from 'components'

import { Subnav } from 'components/Subnav/Subnav'
import { useGetBomQuery } from 'services/boms/Boms'

export const Project: FC = () => {
  const location = useLocation()
  const { bomSid } = useParams()
  const { data: bom, isLoading: bomLoading} = useGetBomQuery(bomSid as string)
  
  const backText = 'Plan'
  const backPath = '/plan'

  const tabs = [
    {
        active: !location.pathname?.includes('project_items'),
        content: 'Overview',
        path: ''
    },
    {
        active: location.pathname?.includes('project_items'),
        content: 'Items',
        path: 'project_items?filter=all-items',
    },
  ]

  if (bomLoading) return <div>Loading...</div>

  return (
    <>
      <div className="min-h-screen bg-offWhite-light">
        <TopSection sm>
          <div>
            <div>
              <BackLink path={backPath}>{backText}</BackLink>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex" data-testid="project_title">
                <Headline
                  className="text-offWhite-light mt-2 mb-8 self-end"
                  size="xl"
                >
                    {bom?.uniqueName}
                </Headline>
              </div>
            </div>
            <div className="mt-3">
              <Subnav tabs={tabs} />
            </div>
          </div>
        </TopSection>
        <Outlet/>
      </div>
    </>
  )
}

export default Project
