import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from '../baseQuery'

export const apiSecureTokensSlice = createApi({
  reducerPath: 'secureTokenApi',
  baseQuery,
  tagTypes: ['secureToken'],
  endpoints: (builder) => ({
    getSecureToken: builder.query({
      query: (secureToken: string) => ({
        url: `/api/secureTokens/${secureToken}`,
      }),
    }),
  }),
})

export const {
  useLazyGetSecureTokenQuery,
  useGetSecureTokenQuery,
} = apiSecureTokensSlice
