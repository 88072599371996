import { createApi } from '@reduxjs/toolkit/query/react'

import baseQuery from '../baseQuery'
import { apiOrderInvitesSlice } from "../orderInvites/OrderInvites";

export const apiOrderMembersSlice = createApi({
  reducerPath: 'orderMembersApi',
  baseQuery,
  tagTypes: ['OrderMembers'],
  endpoints: (builder) => ({
    getOrderMembersByOrder: builder.query({
      query: (orderSids: string) =>
        `/api/order-members?${orderSids}&excludeCurrentUser=true`,
      providesTags: ['OrderMembers'],
    }),
    getOrderMembersWithCurrentUser: builder.query({
      query: (orderSids: string) => `/api/order-members?${orderSids}&excludeCurrentUser=false&publicProfile=false`,
      providesTags: ['OrderMembers'],
    }),
    postCreateInvitesAndAddMe: builder.mutation({
      query: (body) => ({
        url: `api/order-members/add-members`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, {
        dispatch,
        queryFulfilled
      }) {
        try {
          const { data: membersAdded } = await queryFulfilled
          const { orderInvites } = membersAdded
          if ( orderInvites.length > 0 ) {
            dispatch(apiOrderInvitesSlice.util.updateQueryData('getOrderInvites', args.orderSid, (draft) => {
              draft.orderInvites.push(...orderInvites.map((invite: string) => ({
                email: invite
              })))
            }))
          }

        } catch (e) {
          console.log('error on dom updating order invites', e)
        }
      },
      invalidatesTags: ['OrderMembers'],
    }),
  }),
})

export const {
  usePostCreateInvitesAndAddMeMutation,
  useLazyGetOrderMembersByOrderQuery,
  useGetOrderMembersWithCurrentUserQuery,
} = apiOrderMembersSlice
