import React, { type FC } from 'react'
import { Timeline } from '../../../components/Timeline/Timeline'
import { useGetOrderQuery, } from '../../../services/orders/Orders'
import { useParams } from 'react-router-dom'
import { toTimelineEvents } from '../../../utils/timelineEvent/timelineEvent'
import {useGetFeedOrderEventsQuery} from "../../../services/feedEvents/feedEvents";

export const Activity: FC = () => {
  const { orderSid } = useParams()
  const { data: order, isLoading: isLoadingOrder } = useGetOrderQuery(
    orderSid as string
  )
  const { data: orderEvents, isLoading } = useGetFeedOrderEventsQuery(
      {orderSid:orderSid as string}
  )
  if (isLoading || isLoadingOrder) return <div>Loading...</div>
  const sortedEvents =
    orderEvents !== undefined
      ? toTimelineEvents(orderEvents)
      : []

  return (
    <div className="flex">
      <div className="relative">
        <Timeline
          itemName={order?.uniqueName ?? orderSid}
          eventFeed={sortedEvents}
        />
      </div>
    </div>
  )
}

export default Activity
