import React, { type FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { useGetFieldMappingQuery } from '../../services/fieldMapping/FieldMapping'
import { usePostFileExportMutation } from '../../services/fileExports/FileExports'
import { useAppDispatch } from '../../store'
import { setFileSid } from '../../store/downloadCSV'
import { downloadCSVSelector, slideOverSelector } from '../../store/selectors'
import { closeSlideOver } from '../../store/slideOvers'
import { type FieldMapping, type FileExport } from '../../types'
import { sortFieldMappingsForSelect } from '../../utils/sortFieldMappingsForSelect/sortFieldMappingsForSelect'
import { Button } from '../Button/Button'
import { Slideover } from '../Slideover/Slideover'

export const DownloadCSV: FC = () => {
  const { fileStatus } = useSelector(downloadCSVSelector)
  const [fieldMappingSid, setFieldMappingSid] = useState<string | null>(null)
  const [postFileExport] = usePostFileExportMutation()
  const { data: fieldMappings, isLoading: isLoadingFieldMappings } =
    useGetFieldMappingQuery(1)
  const { name } = useSelector(slideOverSelector)
  const dispatch = useAppDispatch()

  if (isLoadingFieldMappings) return <div>Loading...</div>

  const loadingStatus: any = {
    uninitialized: false,
    creating: true,
    waiting: true,
    failed: false,
    finished: false,
  }

  const show = name === 'DownloadCSV'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  const outputTemplate: FieldMapping[] = sortFieldMappingsForSelect(
    fieldMappings.fieldMappings
  )

  const createFileExport = async (): Promise<void> => {
    let selectedMapping =
      fieldMappingSid === null ? outputTemplate[0].sid : fieldMappingSid
    selectedMapping =
      selectedMapping === 'factor-default' ? null : selectedMapping

    const { sid } = (await postFileExport({
      query: {
        itemGroups: [],
      },
      fieldMappingSid: selectedMapping,
    }).unwrap()) as FileExport
    dispatch(setFileSid({ fileSid: sid }))
    handleClose()
  }

  return (
    <Slideover open={show} onClose={handleClose} title={`Download open items`}>
      <div>
        <div className="text-sm mb-4 block">
          <h3>
            Download your open item list, including the most current ship and
            delivery dates provided by your suppliers, from Factor.
          </h3>
          <h3 className="mt-4">Choose an output template for your file:</h3>
        </div>

        <div className="text-offBlack-dark text-sm block">
          <label
            htmlFor="template"
            className="text-offBlack-light relative top-6 left-3 text-xs"
          >
            Template
          </label>
          {isLoadingFieldMappings ? (
            <div>Loading...</div>
          ) : (
            <select
              className="rounded-md py-6 pb-3 w-full focus:border-offBlack-light border-offWhite-dark"
              name="template"
              id="template"
              onChange={(e) => {
                setFieldMappingSid(e.target.value)
              }}
            >
              {outputTemplate.map(({ name, sid }: FieldMapping) => (
                <option key={`${sid}-output`} value={sid ?? 'factor-default'}>
                  {name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="underline text-blue-mid text-xs cursor-pointer mt-5">
          Learn more about output templates.
        </div>

        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={loadingStatus[fileStatus]}
            onClick={createFileExport}
          >
            Download item list
          </Button>
        </div>
      </div>
    </Slideover>
  )
}
