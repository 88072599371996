import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {type FC, useEffect, useState} from 'react'
import dayjs from 'dayjs'
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu'
import { useOutletContext, useParams } from 'react-router-dom'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { ItemsTable } from 'components/ItemsTable/ItemsTable'

import { 
  useDismissFlagsMutation, 
  useLazyGetItemEventsQuery, 
  useBulkUpdateItemGroupsMutation, 
} from 'services/orders/Orders'

import { useLazyGetItemGroupsQuery } from 'services/itemgroup/ItemGroup'

import { formatItemGroupRelations } from 'utils/formatItemGroupRelations/formatItemGroupRelations'
import { openSlideOver } from 'store/slideOvers'
import { type Flag, type ItemGroupWithRelations } from 'types'
import { useAppDispatch } from 'store'
import { Paginator } from '../../../components/Paginator/Paginator'
import { useLazyGetOrderMembersByOrderQuery } from "../../../services/orderMembers/OrderMembers";
import { confirmItemGroupDates } from 'utils/itemGroupUpdate/itemGroupUpdate'
import _ from "lodash";

dayjs.extend(isSameOrBefore)

export const Items: FC = () => {
  const { orderSid } = useParams()
  const [role]: string[] = useOutletContext()
  const [selectedDetail, setSelectedDetail] = useState<string[]>([])
  const [selectedItemName, setSelectedItemName] = useState<string | null>(null)
  const [selectedItemGroupSid, setselectedItemGroupSid] = useState<string | null>(null)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageToken, setPageToken] = useState(null)
  const [bulkUpdateItemGroups] = useBulkUpdateItemGroupsMutation()

  const [ getItemGroups, { data: itemGroupsData, isLoading } ] = useLazyGetItemGroupsQuery()
  const [
    getSelectedItemEvents,
    { data: selectedItemEvents, isFetching: isFetchingEvents },
  ] = useLazyGetItemEventsQuery()
  const [dismissFlag] = useDismissFlagsMutation()

  const dispatch = useAppDispatch()

  const itemGroupsWithRelations = formatItemGroupRelations(
    itemGroupsData
  );

  const selectedItemGroups = itemGroupsWithRelations.filter(
    (item: ItemGroupWithRelations) => selectedDetail.includes(item.sid)
  )

  const [getMembers, { data: orderMembers, isFetching: isFetchingMembers }] =
    useLazyGetOrderMembersByOrderQuery()

  const orderSids = Object.keys(
    _.groupBy(
      selectedItemGroups,
      (ig: ItemGroupWithRelations) => ig.item?.orderSid
    )
  )

  useEffect(() => {
    if (selectedDetail.length > 0) {
      getMembers(orderSids.map((sid) => `orderSids=${sid}`).join('&'))
    }
  }, [selectedDetail])

  const handleUpdateItems = (): void => {
    const selectedItemGroups = itemGroupsWithRelations.filter(
      (item: ItemGroupWithRelations) => selectedDetail.includes(item.sid)
    )
    dispatch(
      openSlideOver({ name: 'UpdateOrderItem', data: { items: selectedItemGroups, role } })
    )
  }

  const handleAddShipments = (): void => {
    const selectedItemGroups = itemGroupsWithRelations.filter(
        (item: ItemGroupWithRelations) => selectedDetail.includes(item.sid)
    )
    dispatch(
        openSlideOver({ name: 'AddShipment', data: { items: selectedItemGroups, role } })
    )
  }

  const handleSelectAll = (): void => {
    if (allSelected) {
      setSelectedDetail([])
      setAllSelected(false)
    } else {
      setSelectedDetail(
        itemGroupsWithRelations.map(
          (ig: ItemGroupWithRelations): string => ig.sid
        )
      )
      setAllSelected(true)
    }
  }

  const handleDismissFlags = (): void => {
    if (role === 'vendor') {
      return
    }
    const selectedItemGroupsFlags: Flag[] = []
    itemGroupsWithRelations.forEach((itemgroup: ItemGroupWithRelations) => {
      if (selectedDetail.includes(itemgroup.sid)) {
        itemgroup.flags?.forEach((flag: Flag) => {
          if (flag.status === 'active') {
            selectedItemGroupsFlags.push(flag)
          }
        })
      }
    })
    selectedItemGroupsFlags.forEach((flag: Flag) => {
      dismissFlag(flag)
    })
  }

  const handleRequestUpdate = (): void => {
    dispatch(openSlideOver(
      { name: 'RequestUpdate',
        data: selectedDetail.length > 0 ? { selectedItemGroups, orderMembers } : null,
        isFetching: isFetchingMembers
      }))
  }

  const handleSelectItem = (checkedItem: string): void => {
    console.log('selecting')
    if (selectedDetail.includes(checkedItem)) {
      const newSelection = selectedDetail.filter((item) => item !== checkedItem)
      setSelectedDetail(newSelection)
      return
    }
    setSelectedDetail((prevState) => {
      if (!prevState.includes(checkedItem)) {
        return [...prevState, checkedItem]
      }
      return prevState;
    })
  }

  const handleToggleItemActivity = (
    value: boolean,
    itemName: string | null,
    itemSid: string | null,
    itemGroupSid: string | null,
  ): void => {
    setSelectedItemName(itemName)
    setselectedItemGroupSid(itemGroupSid)
    if (itemSid != null) {
      getSelectedItemEvents(itemSid)
    }
  }

  useEffect(() => {
    if (selectedItemEvents === undefined) return
    if (selectedItemName !== null && selectedItemEvents?.length > 0) {
      const selectedItemGroups = itemGroupsWithRelations.filter(
        (itemGroup: ItemGroupWithRelations) => 
          itemGroup.sid === selectedItemGroupSid
      )
      dispatch(
        openSlideOver({
          name: 'ItemActivity',
          data: { 
            itemName: selectedItemName, 
            sortedEvents: selectedItemEvents, 
            items: selectedItemGroups 
          },
          isFetching: isFetchingEvents
        })
      )
    }
  }, [selectedItemName, selectedItemEvents, isFetchingEvents])

  useEffect(() => {
    if (typeof itemGroupsData === 'undefined') {
      getItemGroups(orderSid)
    }
  }, [])


  useEffect(() => {
    if (pageToken !== null) {
      getItemGroups(orderSid, pageToken)
    }
  }, [pageToken])

  const handleNext = (): void => {
    (Boolean((itemGroupsData?.page?.nextPageToken))) && setPageToken(itemGroupsData.page.nextPageToken)
    setCurrentPage(prevState => prevState + 1)
  }

  const handlePrevious = (): void => {
    (Boolean((itemGroupsData?.page?.previousPageToken))) && setPageToken(itemGroupsData.page.previousPageToken)
    setCurrentPage(prevState => prevState - 1)
  }

  if (isLoading) return <div>Loading...</div>
  const actions = []

  if (role === 'vendor') {
    actions.push({ name: 'Update Items', onClick: handleUpdateItems, disabled: selectedDetail.length === 0, icon: (
      <FontAwesomeIcon className="mr-1" icon={light('sync')} />
    ) })
    actions.push({
      name: 'Confirm Dates',
      onClick: async () => {
        await confirmItemGroupDates(itemGroupsWithRelations, selectedDetail, bulkUpdateItemGroups);
      },
      disabled: selectedDetail.length === 0,
      icon: (
        <FontAwesomeIcon
          className="mr-1"
          icon={light('circle-check')}
        />
      )
    });
    actions.push({ name: 'Add Shipment', onClick: handleAddShipments, disabled: selectedDetail.length !== 1, icon: (
          <FontAwesomeIcon className="mr-1" icon={light('truck')} />
      ) })
  } else {
    actions.push({ name: 'Edit Items', onClick: handleUpdateItems, disabled: selectedDetail.length === 0 })
    actions.push({ name: 'Request Updates', onClick: handleRequestUpdate, disabled: selectedDetail.length === 0 })
    actions.push({ name: 'Dismiss Flags', onClick: handleDismissFlags, disabled: selectedDetail.length === 0 })
  }
  
  return (
    <>
      <div className={''}>
        <div className="flex justify-between">
          <div className="flex ml-auto pr-5">
            <ActionMenu
              actions={actions}
            />
          </div>
        </div>
        <div>
          <ItemsTable
            itemGroupData={itemGroupsWithRelations}
            hide={role === 'vendor' ? ['order', 'buyer'] : ['order', 'vendor']}
            onSelect={handleSelectItem}
            role={role}
            onSelectAll={handleSelectAll}
            selected={selectedDetail}
            onClick={handleToggleItemActivity}
          />
          {itemGroupsWithRelations.length > 0 ? (
            <Paginator
              currentPage={currentPage}
              currentPageResults={itemGroupsWithRelations.length}
              onNext={handleNext}
              onPrev={handlePrevious}
              totalCount={itemGroupsData.itemGroupTotalCount}
            />
          ): null}
        </div>
      </div>
    </>
  )
}

export default Items
