import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiInventorySlice = createApi({
  reducerPath: 'inventoryApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['InventoryCounts', 'InventoryUploads'],
  endpoints: (builder) => ({
    getInventoryCount: builder.query({
      query: () => `/v1/InventoryCounts`,
      providesTags: ['InventoryCounts'],
    }),
    uploadInventoryData: builder.mutation({
      query: (body) => ({
        url: '/v1/InventoryUploads',
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
    }),
    getInventoryUpload: builder.query({
      query: (sid: string) => `/v1/InventoryUploads/${sid}`,
      providesTags: ['InventoryUploads'],
    }),
  }),
})

export const {
  useGetInventoryCountQuery,
  useUploadInventoryDataMutation,
  useLazyGetInventoryUploadQuery,
} = apiInventorySlice
