import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { type FC } from 'react'

interface PaginatorProps {
  onNext: () => void
  onPrev: () => void
  totalCount?: number
  currentPage?: number
  currentPageResults?: number
  enablePrev?: boolean
  enableNext?: boolean
  hideCount?: boolean
}
export const Paginator: FC<PaginatorProps> = ({
  onNext,
  onPrev,
  totalCount = 0,
  currentPage = 1,
  currentPageResults = 0,
  enablePrev,
  enableNext,
  hideCount = false,
}) => {
  const currentRangeEnd = (currentPage - 1) * 100 + currentPageResults
  const currentRangeStart = currentPage === 1 ? 1 : (currentPage - 1) * 100 + 1
  const disablePrev = enablePrev !== undefined ? !enablePrev : currentPage === 1
  const disableNext =
    enableNext !== undefined ? !enableNext : currentRangeEnd === totalCount

  return (
    <div className="mb-10">
      <div className="flex justify-between items-center px-5 sm:px-8">
        <div className="text-xs">
          {!hideCount ? (
            <>
              Showing <strong>{currentRangeStart}</strong> to{' '}
              <strong>{currentRangeEnd}</strong>
            </>
          ) : null}
        </div>
        <div>
          <div className="flex items-center gap-[5px]">
            <button
              disabled={disablePrev}
              onClick={onPrev}
              className={clsx(
                'rounded-full h-7 w-7 p-[5px] flex items-center justify-center',
                {
                  'bg-transparent': disablePrev,
                  'bg-white shadow-base': !disablePrev,
                }
              )}
            >
              <FontAwesomeIcon icon={light('arrow-left')} />
            </button>
            <button
              disabled={disableNext}
              onClick={onNext}
              className={clsx(
                'rounded-full h-7 w-7 p-[5px] flex items-center justify-center',
                {
                  'bg-transparent': disableNext,
                  'bg-white shadow-base': !disableNext,
                }
              )}
            >
              <FontAwesomeIcon icon={light('arrow-right')} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
