import { type FC } from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'

interface ItemGroupDateProps {
  date: string | null
  condition: boolean
  className?: string;
}

export const ItemGroupDate: FC<ItemGroupDateProps> = ({
  date,
  condition,
  className,
}: ItemGroupDateProps): JSX.Element => {
  const today = dayjs.utc(Date.now())
  const dateCompare = dayjs(date)
  const isPastDateOrUnknown = (dateCompare.isBefore(today) && !condition ) || date === 'Unknown'

  return (
    <>
      <td className={clsx(`whitespace-nowrap py-4 pr-3 text-sm sm:pl-6 md:pl-0`, isPastDateOrUnknown && 'text-red-mid', className)}>
        <span className="font-semibold">{date}</span>
      </td>
    </>
  )
}
