import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { type FC, Fragment, useRef, useState } from 'react'

import { Tooltip } from '../Tooltip/Tooltip'

interface SelectProps {
  triggerComponent: FC
  disabled?: boolean
  disabledMessage?: string
  options: any[]
}

export const Select: FC<SelectProps> = ({
  triggerComponent: TriggerComponent,
  disabled = false,
  disabledMessage,
  options,
}) => {
  const actionRef = useRef(null)
  const [showDisabledTooltip, setShowTooltip] = useState(false)
  return (
    <div>
      <Menu
        as="div"
        className="relative inline-block text-left"
        ref={actionRef}
      >
        <Menu.Button data-testid="action-btns" type="button">
          <TriggerComponent />
        </Menu.Button>
        {disabled && (
          <Tooltip position="top" ref={actionRef}>
            {' '}
            <p>{disabledMessage} </p>
          </Tooltip>
        )}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 min-w-44 bg-white rounded divide-y divide-gray-100 shadow right-0 py-1 text-sm text-gray-700 mt-2">
            {options.map((option, i) => {
              const showTooltip = option.tooltip !== undefined
              return (
                <div
                  onMouseEnter={
                    showTooltip
                      ? () => {
                          setShowTooltip(true)
                        }
                      : () => {}
                  }
                  onMouseLeave={
                    showTooltip
                      ? () => {
                          setShowTooltip(false)
                        }
                      : () => {}
                  }
                  key={`option-${i}`}
                >
                  {showDisabledTooltip && showTooltip && (
                    <div className="group flex absolute">
                      <div
                        className="p-2 font-medium text-white duration-300 bg-gray-900 rounded-lg shadow-sm tooltip"
                        style={{ marginTop: 40, marginLeft: -100, width: 300 }}
                      >
                        {option.tooltip}
                      </div>
                    </div>
                  )}
                  <Menu.Item>
                    <div
                      data-testid={option.testId}
                      className={clsx(
                        'block py-2 px-4 cursor-pointer flex gap-2 items-center min-w-[241px]',
                        { 'text-offBlack-light': option.disabled },
                        {
                          'hover:bg-gray-100 text-offBlack-dark':
                            option.disabled === false || !option.disabled,
                        }
                      )}
                      onClick={() => option.onClick?.()}
                    >
                      {option.icon !== undefined && option.icon}
                      <span className="text-sm font-semibold">
                        {option.name}
                      </span>
                    </div>
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
