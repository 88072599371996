import React, {
  type ChangeEvent,
  type FC,
  type MouseEvent,
  type MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface DropzoneProps {
  title: string;
  subtitle?: string;
  onChange: (file: File) => void
}
const Dropzone: FC<DropzoneProps> = ({ title, onChange, subtitle }) => {
  const [file, setFile] = useState<File | null>(null)
  const dndRef = useRef<HTMLDivElement>(null)

  const addFiles = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files != null) {
      setFile(Array.from(event.target.files)[0])
    }
  }

  const handleBrowse = (event: MouseEvent<HTMLInputElement>): void => {
    const files = (event.target as unknown as { files: File[] }).files
    if (files != null) {
      setFile(Array.from(files)[0])
    }
  }

  useEffect(() => {
    if (file != null) {
      onChange?.(file)
    }
  }, [file])

  const handleRemove: MouseEventHandler<HTMLParagraphElement> = (e): void => {
    e.preventDefault()
    e.stopPropagation()
    setFile(null)
  }

  return (
    <div className="w-full">
      <div className="relative flex flex-col text-gray-400 rounded">
        <div
          ref={dndRef}
          className={clsx(
            'relative flex flex-col text-gray-400 border border-offBlack-lightest border-dotted rounded cursor-pointer px-4 pt-6 pb-8',
            {
              'bg-white border-none shadow':
                file !== null && file?.type === 'text/csv',
            }
          )}
        >
          <input
            accept="*"
            type="file"
            className="absolute inset-0 z-40 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
            onChange={addFiles}
            onDragOver={() => {
              dndRef.current?.classList.add('border-blue-400')
              dndRef.current?.classList.add('ring-4')
              dndRef.current?.classList.add('ring-inset')
            }}
            onDragLeave={() => {
              dndRef.current?.classList.remove('border-blue-400')
              dndRef.current?.classList.remove('ring-4')
              dndRef.current?.classList.remove('ring-inset')
            }}
            onDrop={() => {
              dndRef.current?.classList.remove('border-blue-400')
              dndRef.current?.classList.remove('ring-4')
              dndRef.current?.classList.remove('ring-inset')
            }}
            title=""
          />
          <div className="flex flex-col items-center justify-center pt-10 text-center">
            {file === null || (file !== null && file?.type !== 'text/csv') ? (
              <>
                <FontAwesomeIcon
                  icon={light('file-circle-plus')}
                  className="text-teal-mid mb-5 h-6"
                />
                <p className="m-0 text-sm font-sans text-offBlack-dark font-normal">
                  {title}
                </p>
                {subtitle ? <p className="text-xs text-offBlack-mid">{subtitle}</p> : null}
                {file !== null && file?.type !== 'text/csv' ? (
                  <>
                    <p className="text-red-mid text-xs">
                      File must be in .csv format. Please try again
                    </p>
                    <p
                      className="text-blue-mid text-xs underline p-3 pb-0 mt-2 z-50 relative"
                      onClick={handleBrowse}
                    >
                      Browse
                    </p>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={light('file-circle-check')}
                  className="text-teal-mid mb-5 h-6"
                />
                <p className="m-0 text-sm font-sans text-offBlack-dark font-normal">
                  <span className="font-bold">{file.name}</span> successfully
                  uploaded!
                </p>
                <p
                  className="text-red-mid text-xs underline p-3 mt-4 z-50 relative"
                  onClick={handleRemove}
                >
                  Remove
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropzone
