import React, { type FC, useState } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ProjectItemRow from 'components/ItemsTable/ProjectItemRow/ProjectItemRow'
import { Table } from 'components/Table/Table'

import {
  useGetWebBomLinesByBomSidQuery,
} from 'services/clearToBuildWeb/ClearToBuildWeb'

import {
  type Bom,
  type BomLine
} from 'types'

import { Tabs } from '../../../components'
import { useGetBomByUniqueNameQuery } from "services/boms/Boms";

const getBomLinesUniqueNames = (bomLines: BomLine[]): string[] => {
  return bomLines?.map((bomLine: BomLine) => {
    return bomLine.uniqueName
  })
}

export const ProjectItems: FC = () => {
  const [orderBy, setOrderBy] = useState<string | undefined>('requiredQuantity')
  const [sortOrder, setSortOrder] = useState<'Asc' | 'Desc' | undefined>(undefined)
  const { bomSid } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const activeFilter = searchParams.get('filter') ?? 'all-items'
  const bomLineQueryParam = {
    bomSid: bomSid as string,
    inventoryCountLessThanEqualTo:
      activeFilter === 'all-items' || activeFilter === 'no-orders'
        ? undefined
        : 0,
    orderedQuantityLessThanEqualTo:
      activeFilter === 'all-items' || activeFilter === 'no-inventory'
        ? undefined
        : 0,
    orderBy,
    sortOrder,
  }

  const { data: bomLinesData, isLoading: isLoadingBomLines, isFetching: isFetchingBomLines } =
    useGetWebBomLinesByBomSidQuery(bomLineQueryParam)

  const bomLines: BomLine[] = bomLinesData?.bomLines
  const bomLinesUniqueNames = getBomLinesUniqueNames(bomLines)
  const {
    data: bomLinesWithbomsData,
    isLoading: bomLinesWithbomsLoading,
  } = useGetBomByUniqueNameQuery(bomLinesUniqueNames?.join('&uniqueName='),
    { skip: bomLinesUniqueNames === undefined || bomLinesUniqueNames?.length === 0 }
  )

  if (
    isLoadingBomLines ||
    isFetchingBomLines ||
    bomLinesWithbomsLoading
  )
    return <div>Loading...</div>

  const bomLinesWithBoms = bomLinesWithbomsData?.boms

  const getBomForBomLine = (bomLine: BomLine) : string | null => {
    const bom = bomLinesWithBoms.filter((bom: Bom) => bom.uniqueName === bomLine.uniqueName)
    return (bom.length === 0) ? null : bom[0].sid
  }

  const getBomLineData = (
    bomLine: BomLine
  ): any => {

    return {
      name: bomLine?.uniqueName,
      ordered: bomLine?.orderedAmount,
      onhand: bomLine?.onHandAmount,
      ctb: bomLine?.clearToBuild,
      req: String(Number(bomLine?.requiredQuantity)),
      ordersOnTime: bomLine?.orderLabelsOnTime,
      ordersPastDue: bomLine?.orderLabelsPastDue,
      bomSid: getBomForBomLine(bomLine),
    }
  }

  const Items = bomLines?.map((bomLine: BomLine) => {
    return getBomLineData(
      bomLine
    )
  })

  const handleClickHeader = (name: string): (() => void ) => (): void => {
    setOrderBy(name)
    setSortOrder(sortOrder === 'Desc' ? 'Asc' : 'Desc')
  }

  const sortedDirection = sortOrder === undefined ? sortOrder : sortOrder.toUpperCase() as 'ASC' | 'DESC' | null

  const Headers = [
    { name: 'item', mobile: true },
    { name: 'ordered', mobile: true, onClick: handleClickHeader('orderedQuantity'), sortedDirection: orderBy === 'orderedQuantity' ? sortedDirection : undefined },
    { name: 'on-hand', tablet: true, onClick: handleClickHeader('inventoryQuantity'), sortedDirection: orderBy === 'inventoryQuantity' ? sortedDirection : undefined },
    { name: 'ctb', tablet: true, onClick: handleClickHeader('ctb'), sortedDirection: orderBy === 'ctb' ? sortedDirection : undefined },
    { name: 'req/1', tablet: true, onClick: handleClickHeader('requiredQuantity'), sortedDirection: orderBy === 'requiredQuantity' ? sortedDirection : undefined },
    { name: 'orders', tablet: false },
  ]

  const handleTabClick = (tab: string): void => {
    navigate(`/projects/${bomSid}/project_items?filter=${tab}`)
  }

  return (
    <>
      <div className="pt-8 pl-8 mb-3">
        <Tabs
          options={[
            {
              content: 'All items',
              active: activeFilter === 'all-items',
              onClick: () => {
                handleTabClick('all-items')
              },
            },
            {
              content: 'No orders',
              active: activeFilter === 'no-orders',
              onClick: () => {
                handleTabClick('no-orders')
              },
            },
            {
              content: 'No inventory',
              active: activeFilter === 'no-inventory',
              onClick: () => {
                handleTabClick('no-inventory')
              },
            },
          ]}
        />
      </div>
      {Items.length > 0 ? (
        <Table
          data={Items}
          rowItem={ProjectItemRow}
          headers={Headers}
          canSelect={false}
        />
      ) : (
        <div className="min-h-[60vh] flex items-center justify-center text-2xl" data-cy="emptyItemGroups">
          <p>No items to show</p>
        </div>
      )}
    </>
  )
}

export default ProjectItems
