/* eslint-disable */
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from 'assets/factor_logo.svg'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { type FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { FirstLoginContext } from 'components/FirstLogin/FirstLogin'
import { FormField } from 'components/FormField/FormField'
import { ShowConfirmationModalContext } from 'components/ShowConfirmationModal/ShowConfirmationModal'

import { Button, Checkbox, Headline } from 'components'

import {
  useAutoRegisterUserMutation,
  useCreateRegisterInvitationMutation,
  useGetInvitationQuery,
  useLazyGetInvitationQuery,
  useRegisterUserMutation,
} from 'services/registration/Registration'

import { type PreRegistration, type RegistrationData } from 'types'

import { useGetAccessTokenMutation } from '../../services/auth/Auth'
import { useAppDispatch } from '../../store'
import { setAccessToken } from '../../store/session'

const Register: FC = () => {
  const { setFirstLogin } = useContext(FirstLoginContext)
  const { setOrderConfirmed } = useContext(ShowConfirmationModalContext)
  const [searchParams] = useSearchParams()
  const autoRegister: null | string = searchParams.get('autoRegister')
  const confirmItemGroups: null | string = searchParams.get('confirm')

  const redirectUrl: null | string = searchParams.get('redirectUrl')
  const [autoRegisterMessage, setAutoRegisterMessage] = useState<string>()
  const [dontUsePassword, setDontUsePassword] = useState<boolean>(true)
  const [showSuccess, setShowSuccess] = useState<boolean>(
    () => autoRegister === 'true'
  )

  // Need to check for duplicate invite params for an error case
  const inviteParams: null | string | string[] = searchParams.getAll('invite')
  const inviteTokenSid: string | undefined = inviteParams?.find((invite) =>
    /^[A-Z]{2}/.test(invite)
  )

  const [getAccessToken, accessTokenResponse] = useGetAccessTokenMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [getInvitation, invitationResponse] = useLazyGetInvitationQuery()
  const [registerUser] = useRegisterUserMutation()
  const [autoRegisterUser, autoRegisterUserResponse] =
    useAutoRegisterUserMutation()
  const [createRegisterInvitation, registerInvitationResponse] =
    useCreateRegisterInvitationMutation()

  const { data: inviteData } = useGetInvitationQuery(inviteTokenSid, {
    skip:
      inviteTokenSid === undefined ||
      inviteTokenSid === null ||
      autoRegister !== 'true',
  })
  const inviteOrderSid: string | null = inviteData?.orderSid ?? null
  if (confirmItemGroups) {
    setOrderConfirmed(inviteOrderSid)
  }

  useEffect(() => {
    // Fetch the invite if we're not in the auto register flow
    if (inviteTokenSid && autoRegister === null) {
      getInvitation(inviteTokenSid).then((response) => {
        if (response.isError) {
          navigate('/')
        }
      })
    }
  }, [inviteTokenSid])

  useEffect(() => {
    if (registerInvitationResponse.isSuccess) {
      setShowSuccess(true)
    }
  }, [registerInvitationResponse])

  useEffect(() => {
    if (invitationResponse?.data !== undefined) {
      const { inviteTokenSid, email, inviteSid, organizationName } =
        invitationResponse.data
      formik.setFieldValue('inviteTokenSid', inviteTokenSid)
      formik.setFieldValue('email', email)
      formik.setFieldValue('inviteSid', inviteSid)
      if (organizationName !== null) {
        formik.setFieldValue('organizationName', organizationName)
      }
    }
  }, [invitationResponse])

  useEffect(() => {
    if (inviteTokenSid && autoRegister === 'true') {
      setAutoRegisterMessage('Setting up your account...')
      autoRegisterUser({ inviteTokenSid, confirmItemGroups, redirectUrl })
    }
  }, [autoRegister])

  useEffect(() => {
    if (autoRegisterUserResponse.isSuccess) {
      getAccessToken({})
    } else if (autoRegisterUserResponse.isError) {
      navigate(redirectUrl ?? '/')
    }
  }, [autoRegisterUserResponse])

  useEffect(() => {
    if (accessTokenResponse.isSuccess) {
      const {
        data: { accessToken, defaultPage },
      } = accessTokenResponse as {
        data: { accessToken: string; defaultPage: '/track' | '/update' }
      }
      setFirstLogin(true)
      dispatch(setAccessToken(accessToken))
      navigate(redirectUrl ?? defaultPage)
    }
  })

  const handleToggleUsePassword = (e: React.SyntheticEvent): void => {
    const checkbox = e.target as HTMLInputElement
    setDontUsePassword(checkbox.checked)
    if (checkbox.checked) {
      formik.setFieldValue('password', undefined)
    }
  }

  const registrationInitialValues = {
    email: '',
    inviteTokenSid: '',
    organizationName: '',
    firstName: '',
    lastName: '',
  }

  const preRegistrationInitialValues = {
    email: '',
  }

  const initialValues = inviteTokenSid
    ? registrationInitialValues
    : preRegistrationInitialValues

  const registrationValidationSchema = Yup.object({
    email: Yup.string().email(),
    firstName: Yup.string()
      .required('First name is a required field')
      .min(1)
      .max(255, 'First name must be less than 255 characters'),
    lastName: Yup.string()
      .required('Last name is a required field')
      .min(1)
      .max(255, 'Last name must be less than 255 characters'),
    password: Yup.string().min(8).max(40),
    organizationName: Yup.string()
      .required('Organization name is a required field')
      .min(1)
      .max(191, 'Organization name must be less than 191 characters'),
  })

  const preRegistrationValidationSchema = Yup.object({
    email: Yup.string().email(),
  })

  const validationSchema = inviteTokenSid
    ? registrationValidationSchema
    : preRegistrationValidationSchema

  const formik = useFormik({
    initialValues,
    initialErrors: {
      email: '',
    },
    initialTouched: {
      email: false,
      organizationName: false,
      firstName: false,
      lastName: false,
      password: false,
    },
    validationSchema,
    onSubmit: async (data: RegistrationData) => {
      if (inviteTokenSid) {
        try {
          const response = (await registerUser(data)) as any
          if ('error' in response) {
            return
          }
          setFirstLogin(true)
          navigate('/')
        } catch (e: any) {
          console.log('Error on Submiting form', e)
        }
      } else {
        try {
          const expiration = dayjs().add(1, 'day').toISOString()
          const response = await createRegisterInvitation({
            ...data,
            expiration,
          } as PreRegistration)
          if ('error' in response) {
            return
          }
          console.log('navigating')
          setShowSuccess(true)
        } catch (e: any) {
          console.log('Error on Submiting form', e)
        }
      }
    },
  })

  const isValidEmail = (value: string, errors: string | undefined): boolean => {
    return value === '' || errors !== undefined
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-offWhite-light">
      <div className="bg-brand-gradient flex align-center px-10 pb-9 md:px-20 md:w-1/2 flex-col justify-center">
        <img
          alt="factor logo"
          className="max-w-[154px] md:max-w-[250px] mb-8 mt-9 md:mt-0 md:mb-20 m-auto md:m-0"
          src={Logo}
        />
        <Headline className="text-offBlack-dark leading-normal m-auto md:m-0">
          Tracking orders is about to get a whole lot easier.
        </Headline>
      </div>
      <div className="flex flex-col justify-center px-10 md:px-20 md:w-1/2 py-10">
        {autoRegister !== null ? (
          <h2 className="mb-5 text-xl">{autoRegisterMessage}</h2>
        ) : (
          <>
            <h2 className="mb-5 text-xl">
              {autoRegister === null && inviteTokenSid
                ? "Let's set up your account"
                : 'Get started with Factor'}
            </h2>
            {autoRegister === null && showSuccess ? (
              <p className="text-green-dark text-md mb-3">
                Confirmation sent! Click the link in your inbox to continue.
              </p>
            ) : (
              <>
                {inviteTokenSid ? (
                  <div className="mb-7">
                    <FormField
                      label="Organization Name"
                      type="text"
                      inputName="organizationName"
                      setTouched={formik.setFieldTouched}
                      touched={formik.touched.organizationName}
                      onChange={formik.handleChange}
                      defaultValue={formik.values.organizationName}
                      disabled={
                        invitationResponse?.data?.organizationName !== null
                      }
                      error={formik.errors.organizationName}
                    />
                  </div>
                ) : null}
                <FormField
                  className="mb-5"
                  label="Email address"
                  inputName="email"
                  defaultValue={formik.values.email}
                  onChange={formik.handleChange}
                  onSubmit={formik.handleSubmit}
                  success={
                    inviteTokenSid &&
                    !isValidEmail(formik.values.email, formik.errors.email)
                      ? 'Email address validated!'
                      : null
                  }
                />
                {inviteTokenSid ? (
                  <>
                    <div className="mb-5">
                      <FormField
                        className="disabled:bg-amber-500"
                        label="Password"
                        type="password"
                        disabled={dontUsePassword}
                        inputName="password"
                        icon={
                          <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={light('eye')}
                          />
                        }
                        defaultValue={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors.password as string}
                      />
                    </div>

                    <Checkbox
                      handleChange={handleToggleUsePassword}
                      className="mb-5"
                      checked={dontUsePassword}
                      name="nopassword"
                      label="Create my account without a password"
                    />
                    {dontUsePassword && (
                      <>
                        <p className="my-3 text-sm">
                          You will be able to access Factor using a magic link
                          we&apos;ll send to your email
                        </p>
                      </>
                    )}
                    <div className="mb-7">
                      <FormField
                        label="First Name"
                        type="text"
                        inputName="firstName"
                        setTouched={formik.setFieldTouched}
                        touched={formik.touched.firstName}
                        onChange={formik.handleChange}
                        error={formik.errors.firstName as string}
                      />
                    </div>
                    <div className="mb-7">
                      <FormField
                        label="Last Name"
                        type="text"
                        inputName="lastName"
                        setTouched={formik.setFieldTouched}
                        touched={formik.touched.lastName}
                        onChange={formik.handleChange}
                        onSubmit={formik.handleSubmit}
                        error={formik.errors.lastName as string}
                      />
                    </div>
                  </>
                ) : null}
                <div className="mb-7 text-xs text-offBlack-light">
                  By registering for factor, you agree to our{' '}
                  <a
                    className="underline"
                    href="https://www.factor.io/documentation/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>terms and conditions</span>
                  </a>
                </div>
                <div className="flex justify-end">
                  <Button
                    className="w-1/4"
                    onClick={formik.handleSubmit}
                    disabled={Object.keys(formik?.errors).length > 0}
                    loading={formik.isSubmitting}
                  >
                    Register
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Register
