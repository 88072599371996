import { createApi } from '@reduxjs/toolkit/query/react'

import baseQuery from '../baseQuery'

export const apiItemGroupSlice = createApi({
  reducerPath: 'itemGroupsApi',
  baseQuery,
  tagTypes: ['ItemGroups', 'ItemGroupsByOrder', 'ItemGroupsByItem'],
  endpoints: (builder) => ({
    getAllItemGroups: builder.query({
      query: (pageToken?: string) =>
        `/api/itemgroups?statusNot=canceled${
          typeof pageToken !== 'undefined' ? `&pageToken=${pageToken}` : ''
        }`,
      providesTags: ['ItemGroups'],
    }),
    getItemGroups: builder.query({
      query: (orderSid?: string | null, pageToken?: string) =>
        `/api/itemgroups?statusNot=canceled&orderSid=${orderSid}${
          typeof pageToken !== 'undefined' ? `&pageToken=${pageToken}` : ''
        }`,
      providesTags: ['ItemGroupsByOrder'],
    }),
    getItemGroupsByItemSid: builder.query({
      query: (itemSid?: string | null, pageToken?: string) =>
        `/api/itemgroups/track?statusNot=canceled&itemSid=${itemSid}${
          typeof pageToken !== 'undefined' ? `&pageToken=${pageToken}` : ''
        }`,
      providesTags: ['ItemGroupsByItem'],
    }),
    getItemGroupsTrack: builder.query({
      query: (
        data: {
          searchText?: string | null
          pageToken?: string | null
        } | null
      ) =>
        `/api/itemgroups/track?statusNot=canceled&pageSize=10&includeTotalCount=true${
          data?.pageToken && data?.pageToken !== null && data?.pageToken !== ''
            ? `&pageToken=${data?.pageToken}`
            : ''
        }${
          data?.searchText &&
          data?.searchText !== null &&
          data?.searchText !== ''
            ? `&searchText=${data?.searchText}`
            : ''
        }`,
      providesTags: ['ItemGroups'],
    }),
    getItemGroupsUpdate: builder.query({
      query: (
        data: {
          searchText?: string | null
          pageToken?: string | null
        } | null
      ) =>
        `/api/itemgroups/update?statusNot=canceled&pageSize=10&includeTotalCount=true${
          data?.pageToken && data?.pageToken !== null && data?.pageToken !== ''
            ? `&pageToken=${data?.pageToken}`
            : ''
        }${
          data?.searchText &&
          data?.searchText !== null &&
          data?.searchText !== ''
            ? `&searchText=${data?.searchText}`
            : ''
        }`,
      providesTags: ['ItemGroups'],
    }),
    confirmItemGroups: builder.mutation({
      query: (outboundEmailSid) => ({
        url: `/api/clicktoconfirm/${outboundEmailSid}`,
        method: 'POST',
      }),
      invalidatesTags: ['ItemGroupsByOrder'],
    }),
    getFileTest: builder.query({
      query: () => `/api/file-exports/FE8xhvgd9wwhtj8pgf7dkwkc8qy33x/content`,
    }),
  }),
})

export const {
  useLazyGetItemGroupsByItemSidQuery,
  useGetItemGroupsQuery,
  useGetAllItemGroupsQuery,
  useLazyGetItemGroupsQuery,
  useLazyGetItemGroupsTrackQuery,
  useLazyGetItemGroupsUpdateQuery,
  useConfirmItemGroupsMutation,
  useLazyGetFileTestQuery,
} = apiItemGroupSlice
