import { type TimelineEvent } from '../../components/Timeline/types'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

export const ItemGroupCreateEvent = 'ItemGroupCreateEvent'
export const ItemGroupUpdateEvent = 'ItemGroupUpdateEvent'
export const MessageCreateEvent = 'MessageCreateEvent'
export const UpdateRequestCreateEvent = 'UpdateRequestCreateEvent'
export const FlagCreateEvent = 'FlagCreateEvent'
export const OrderInviteCreateEvent = 'OrderInviteCreateEvent'
export const OrderInviteDeleteEvent = 'OrderInviteDeleteEvent'
export const InboundEmailRelationsCreateEvent = 'InboundEmailRelationsCreateEvent'

export const supportedEvents = [
  ItemGroupCreateEvent,
  ItemGroupUpdateEvent,
  MessageCreateEvent,
  UpdateRequestCreateEvent,
  FlagCreateEvent,
  OrderInviteCreateEvent,
  OrderInviteDeleteEvent,
  InboundEmailRelationsCreateEvent,
]

export const toTimelineEvents = (events: TimelineEvent[]): TimelineEvent[] => {
  return events
    .map((e: any) => toTimelineEvent(e))
    .sort((a: TimelineEvent, b: TimelineEvent) => timelineSort(a, b))
}

export const toTimelineEvent = (e: TimelineEvent): TimelineEvent => {
  return {
    ...e,
    event: JSON.parse(e.event as unknown as string),
  }
}

export const timelineSort = (a: TimelineEvent, b: TimelineEvent): number => {
  return dayjs(a.eventTime, 'h:mm A').isSameOrBefore(
    dayjs(b.eventTime, 'h:mm A')
  )
    ? 1
    : -1
}

export const isSupportedEvent = (eventType: string): boolean => {
  return supportedEvents.includes(eventType)
}