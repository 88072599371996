import { createSlice } from '@reduxjs/toolkit';
import { setSearchParamsAction } from './actions/searchParameters'

export interface SearchParamsState {
  searchText: string | null,
  pageToken: string | null,
}

export const initialState: SearchParamsState = {
  searchText: '',
  pageToken: null,
}

export const searchParametersSlice = createSlice({
  name: 'searchParameters',
  initialState,
  reducers: {
    setSearchParams: setSearchParamsAction,
  },
});

export const {
  setSearchParams,
} = searchParametersSlice.actions

export default searchParametersSlice.reducer