import { type FC, useEffect, useState } from 'react'
import { Table } from '../Table/Table'
import { type Item, type PartsTableProps } from './types'
import BuyerRow from './BuyerRow/BuyerRow'
import VendorRow from './VendorRow/VendorRow'
import { type ItemGroupWithRelations } from '../../types'
import dayjs from 'dayjs'

const sortProps = {
  item: 'label',
  'est arrival': 'estimatedArrivalDate',
  ship: 'shipDate',
  deliver: 'deliveryDate',
  due: 'dueDate',
  updated: 'lastUpdateDate',
  next: 'updateRequestNext'
}
export const ItemsTable: FC<PartsTableProps> = ({
  itemGroupData,
  selected,
  onSelect,
  hide = [],
  role,
  onClick,
  onSelectAll = () => {}
}) => {
  const [sortedItems, setSortedItems] = useState<Item[]>([])

  useEffect(() => {
    if (itemGroupData !== null) {
      setSortedItems(itemGroupData)
    }
  }, [itemGroupData])

  const handleSort = (
    sortBy: string | null,
    sortDirection: 'DESC' | 'ASC' | null
  ): void => {
    let sortProp;
    const sortByDate = (a: ItemGroupWithRelations , b: ItemGroupWithRelations, prop: string, order: number): number => {
      const dateA = dayjs(a[prop], 'MM/DD/YY');
      const dateB = dayjs(b[prop], 'MM/DD/YY');
      return dateB.isBefore(dateA) ? order : -order;
    }

    const getSortedItems = (a: ItemGroupWithRelations, b: ItemGroupWithRelations, sortBy: string, order: number): number  => {
      switch(sortBy) {
        case 'item':
        case 'est arrival':
          sortProp = sortProps[sortBy];
          return a[sortProp] > b[sortProp] ? order : -order
        case 'qty ':
          return Number(a.quantity) > Number(b.quantity) ? order : -order
        case 'flag':
          return (a.flags?.length ?? 0) > (b.flags?.length ?? 0) ? order : -order;
        case 'order':
          return (a.order?.uniqueName as string) > (b.order?.uniqueName as string) ? order : -order
        case 'vendor':
          return (a.vendor.name) > (b.vendor.name) ? order : -order
        case 'buyer':
          return (a.buyer.name) > (b.buyer.name) ? order : -order
        case 'ship':
        case 'deliver':
        case 'updated':
          return sortByDate(a, b, sortProps[sortBy], order);
        default:
          return 0;
      }
    }

    const order = sortDirection === 'DESC' ? -1 : 1

    const newSortedItems =
      sortBy !== null
        ? [...itemGroupData].sort((a, b) => getSortedItems(a, b, sortBy, order))
        : itemGroupData
    setSortedItems(newSortedItems)
  }

  const buyerHeaders = [
    { name: 'item', mobile: true },
    { name: 'order', mobile: true },
    { name: 'vendor', tablet: true },
    { name: 'qty', tablet: true },
    { name: 'stage', tablet: true },
    {
      name: 'dates',
      children: [
        { label: 'ship', startSection: true },
        { label: 'deliver', startSection: false },
        { label: 'due', startSection: false },
      ],
    },
    { name: 'Last updated', tablet: true },
  ]

  const vendorHeaders = [
    { name: 'item' },
    { name: 'qty' },
    { name: 'order' },
    { name: 'buyer' },
    { name: 'stage' },
    {
      name: 'dates',
      children: [
        { label: 'ship', startSection: true },
        { label: 'deliver', startSection: false },
        { label: 'due', startSection: false },
      ],
    },
    { name: 'last update' },
  ]

  const ItemRow = role === 'buyer' ? BuyerRow : VendorRow
  const headers = role === 'buyer' ? buyerHeaders : vendorHeaders
  const filteredHeaders =
    hide?.length > 0
      ? headers.filter((header) => !hide.includes(header.name))
      : headers

  return (
    <>
      {itemGroupData.length > 0 ? (
        <Table
          data={sortedItems}
          rowItem={ItemRow}
          headers={filteredHeaders}
          onSelect={onSelect}
          onSort={handleSort}
          hide={hide}
          selected={new Set([...selected])}
          onClick={onClick}
          handleSelectAll={onSelectAll}
        />
      ) : (
        <div className="min-h-[60vh] flex items-center justify-center text-2xl" data-cy="emptyItemGroups">
          <p>No items to show</p>
        </div>
      )}
    </>
  )
}
