import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiTeamSlice = createApi({
  reducerPath: 'teamApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['team'],
  endpoints: (builder) => ({
    getTeam: builder.query({
      query: (organizationSid: string) => `/v1/Users?organizationSid=${organizationSid}`,
      providesTags: ['team'],
    })
  }),
})

export const {
  useGetTeamQuery,
} = apiTeamSlice
