import { Slideover } from 'components/Slideover/Slideover'
import React, { useState, type FC } from 'react'
import { Button, Checkbox } from 'components'
import { useSelector } from 'react-redux'
import { slideOverSelector } from '../../store/selectors'
import { useAppDispatch } from '../../store'
import { closeSlideOver } from '../../store/slideOvers'
import { useFormik } from 'formik'
import { FormField } from 'components/FormField/FormField'
import { Link } from 'react-router-dom'
import { usePostCreateInvitesAndAddMeMutation } from 'services/orderMembers/OrderMembers'
import { openNotification } from "../../store/notifications";
import { type Order } from 'types'
import * as Yup from 'yup'
import { getMessageInfo, type MessageInfo } from "../../utils/getMessageInfo/getMessageInfo";

export const UpdateOrderMembers: FC = (): JSX.Element => {
  const { data, name } = useSelector(slideOverSelector)
  const show = name === 'UpdateOrderMembers'
  const dispatch = useAppDispatch()
  const [createOrderMembersAndMe] = usePostCreateInvitesAndAddMeMutation()

  const order: Order =  data.order
  const followingOrder: boolean = data.followingOrder
  const removeInitialValue: boolean = data.removeCheckbox ?? false

  const [addMeToOrder, setAddMeToOrder] = useState<boolean>(false)
  const [removeMeFromOrder, setRemoveMeFromOrder] = useState<boolean>(removeInitialValue)

  const emailListSchema = Yup.string().test(
    'is-email-list',
    'Invalid email list',
    function (value) {
      if (!value) {
        return true;
      }
      const emailArray = value.split(',').map((email) => email.trim());
      return emailArray.every((email) => Yup.string().email().isValidSync(email));
    }
  );

  const onClose = (): void => {
    formik.resetForm()
    dispatch(closeSlideOver())
  }
  const formik = useFormik({
    initialValues: {
      emailList: ''
    },
    initialErrors: {
      emailList: '',
    },
    initialTouched: {
      emailList: false,
    },
    validationSchema: Yup.object({
      emailList: emailListSchema,
    }),
    onSubmit: async (data) => {
      const emailList: string[] = data.emailList.split(',').map(email => email.trim())
      try {
        await createOrderMembersAndMe({
          orderSid: order.sid,
          emails: emailList.join(','),
          addMe: addMeToOrder,
          removeMe: removeMeFromOrder,
        })
        const messageInfo : MessageInfo = getMessageInfo(emailList.join(','), addMeToOrder, removeMeFromOrder)
        dispatch(
          openNotification({
            title: messageInfo?.title,
            message: messageInfo?.message,
            show: true,
          })
        )
        onClose?.()
      } catch(error) {
        dispatch(
          openNotification({
            title: 'Something went wrong',
            message:
              'We were not able to complete your order member change. Please try again or contact support@factor.io.',
            type: 'error',
            show: true,
          })
        )
      }
    },
    enableReinitialize: true,
  })

  const handleToggleaddMeToOrder = (e: React.SyntheticEvent): void => {
    const checkbox = e.target as HTMLInputElement
    setAddMeToOrder(checkbox.checked)
    if (checkbox.checked) {
      formik.setFieldValue('addMeToOrder', true)
    }
  }

  const handleToggleRemoveMeFromOrder = (e: React.SyntheticEvent): void => {
    const checkbox = e.target as HTMLInputElement
    setRemoveMeFromOrder(checkbox.checked)
    if (checkbox.checked) {
      formik.setFieldValue('removeMeFromOrder', true)
    }
  }

  return (
    <Slideover open={show} onClose={onClose} title={`Add order members to ${order.title ?? order.uniqueName ?? order.sid}`}>
      <div>
        <p className="text-sm">
          Enter email addresses of the users you wish to add to this order, separated by commas.
          If needed, you can also {followingOrder ? 'unfollow' : 'follow'} this order at the same time.
        </p>
        <div className="py-5">
          <FormField
            className="mb-5"
            label="Email address"
            inputName="emailList"
            onChange={formik.handleChange}
            onSubmit={formik.handleSubmit}
          />
        </div>
        {!followingOrder && 
          <div>
            <Checkbox
              className='text-sm mr-2 border-offBlack-light outline-0 rounded'
              handleChange={handleToggleaddMeToOrder}
              checked={addMeToOrder}
              name="addMeToOrder"
              label="Add me to this order"
            />
            <div className='pl-6 pb-4 text-xs text-offBlack-light'>
              You will receive notifications about this order’s status as updates are provided by Updating members.
            </div>
          </div>
        }

        {followingOrder && 
          <div>
            <Checkbox
              className='text-sm mr-2 border-offBlack-light outline-0 rounded'
              handleChange={handleToggleRemoveMeFromOrder}
              checked={removeMeFromOrder}
              name="removeMeFromOrder"
              label="Remove me from this order"
            />
            <div className='pl-6 pb-4 text-xs text-offBlack-light'>
              You will no longer receive any notifications about this order, and other Factor users will not be able to add you to the order again.
            </div>
            {/* <Checkbox
              className='text-sm mr-2 border-offBlack-light outline-0 rounded'
              handleChange={}
              checked={}
              name="removeMeFromOrder"
              label="Never notify me about any orders from Organization"
            />
            <div className='pl-6 pb-4 text-xs text-offBlack-light'>
              You will never receive notifications about any orders from this organization again.
            </div> */}
          </div>
        }

        <Link className="underline font-semibold text-blue-mid text-xs cursor-pointer mt-5"
              to={`https://www.factor.io/support/following-and-unfollowing-orders-in-factor`} >
          Learn more about order members in Factor
        </Link>
        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            id="saveOrderMembers" 
            onClick={formik.handleSubmit} 
            loading={formik.isSubmitting}
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              (!addMeToOrder && !removeMeFromOrder && !(formik.values.emailList.trim() !== '')) ||
              (removeMeFromOrder && !(formik.values.emailList.trim() !== ''))
            }
            cySelector='saveChanges'
          >
            Save changes
          </Button>
        </div>
      </div>
    </Slideover>
  )
}
