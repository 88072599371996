import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'
import { createLogger } from 'redux-logger'

import { apiEmailOptOutSlice } from 'services/emailOptOut/EmailOptOut'
import { apiInventorySlice } from 'services/inventory/Inventory'
import { apiRegistrationSlice } from 'services/registration/Registration'

// Services
import { apiAuthSlice } from '../services/auth/Auth'
import { apiBomsSlice } from '../services/boms/Boms'
import { apiFeedEvents } from '../services/feedEvents/feedEvents'
import { apiFieldMappingSlice } from '../services/fieldMapping/FieldMapping'
import { apiFileExportsSlice } from '../services/fileExports/FileExports'
import { apiItemGroupSlice } from '../services/itemgroup/ItemGroup'
import { apiOpenOrderUploadSlice } from '../services/openOrderUpload/OpenOrderUpload'
import { apiOrderMembersSlice } from '../services/orderMembers/OrderMembers'
import { apiOrdersSlice } from '../services/orders/Orders'
import { apiReportsSlice } from '../services/reports/Reports'
import { apiTeamSlice } from '../services/team/Team'
import { apiUpdateRequestSlice } from '../services/updateRequests/UpdateRequests'
import { apiUpdateUserSlice } from '../services/updateUser/UpdateUser'
import { apiUserSlice } from '../services/user/User'
import downloadCSVReducer from './downloadCSV'
import notificationsReducer from './notifications'
import { apiClearToBuildSlice } from 'services/clearToBuild/ClearToBuild'
import { apiClearToBuildWebSlice } from 'services/clearToBuildWeb/ClearToBuildWeb'
import { apiOrderInvitesSlice } from 'services/orderInvites/OrderInvites'
import { apiOrganizationSettingsSlice } from '../services/organizationSettings/OrganizationSettings'
import { apiSecureTokensSlice } from 'services/secureTokens/SecureTokens'
import { apiInboundEmailsSlice } from 'services/inboundEmails/InboundEmails'

// Reducers
import sessionReducer from './session'
import slideOversReducer from './slideOvers'
import searchParameters from './searchParameters'


const middlewares = [
  apiAuthSlice.middleware,
  apiOrdersSlice.middleware,
  apiUserSlice.middleware,
  apiItemGroupSlice.middleware,
  apiUpdateRequestSlice.middleware,
  apiReportsSlice.middleware,
  apiRegistrationSlice.middleware,
  apiOrderMembersSlice.middleware,
  apiFileExportsSlice.middleware,
  apiFieldMappingSlice.middleware,
  apiOpenOrderUploadSlice.middleware,
  apiBomsSlice.middleware,
  apiInventorySlice.middleware,
  apiTeamSlice.middleware,
  apiUpdateUserSlice.middleware,
  apiClearToBuildSlice.middleware,
  apiClearToBuildWebSlice.middleware,
  apiOrderInvitesSlice.middleware,
  apiFeedEvents.middleware,
  apiEmailOptOutSlice.middleware,
  apiOrganizationSettingsSlice.middleware,
  apiSecureTokensSlice.middleware,
  apiInboundEmailsSlice.middleware,
]

process.env.REACT_APP_SEE_LOGGER === 'active' &&
process.env.NODE_ENV !== 'test' &&
middlewares.push(createLogger())

const rootReducer = combineReducers({
  session: sessionReducer,
  slideOvers: slideOversReducer,
  notifications: notificationsReducer,
  downloadCSV: downloadCSVReducer,
  searchParameters: searchParameters,
  [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
  [apiOrdersSlice.reducerPath]: apiOrdersSlice.reducer,
  [apiUserSlice.reducerPath]: apiUserSlice.reducer,
  [apiItemGroupSlice.reducerPath]: apiItemGroupSlice.reducer,
  [apiUpdateRequestSlice.reducerPath]: apiUpdateRequestSlice.reducer,
  [apiReportsSlice.reducerPath]: apiReportsSlice.reducer,
  [apiRegistrationSlice.reducerPath]: apiRegistrationSlice.reducer,
  [apiOrderMembersSlice.reducerPath]: apiOrderMembersSlice.reducer,
  [apiFileExportsSlice.reducerPath]: apiFileExportsSlice.reducer,
  [apiFieldMappingSlice.reducerPath]: apiFieldMappingSlice.reducer,
  [apiOpenOrderUploadSlice.reducerPath]: apiOpenOrderUploadSlice.reducer,
  [apiBomsSlice.reducerPath]: apiBomsSlice.reducer,
  [apiInventorySlice.reducerPath]: apiInventorySlice.reducer,
  [apiTeamSlice.reducerPath]: apiTeamSlice.reducer,
  [apiUpdateUserSlice.reducerPath]: apiUpdateUserSlice.reducer,
  [apiClearToBuildSlice.reducerPath]: apiClearToBuildSlice.reducer,
  [apiClearToBuildWebSlice.reducerPath]: apiClearToBuildWebSlice.reducer,
  [apiOrderInvitesSlice.reducerPath]: apiOrderInvitesSlice.reducer,
  [apiFeedEvents.reducerPath]: apiFeedEvents.reducer,
  [apiEmailOptOutSlice.reducerPath]: apiEmailOptOutSlice.reducer,
  [apiOrganizationSettingsSlice.reducerPath]: apiOrganizationSettingsSlice.reducer,
  [apiSecureTokensSlice.reducerPath]: apiSecureTokensSlice.reducer,
  [apiInboundEmailsSlice.reducerPath]: apiInboundEmailsSlice.reducer,
})

export const setupStore: any = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([...middlewares]),
  })

export const store = setupStore()

setupListeners(store.dispatch)
// Infer the `RootState` and `AppDigspatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
