import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from '../baseQuery'

export const apiReportsSlice = createApi({
  reducerPath: 'reportsApi',
  baseQuery,
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    getReports: builder.query({
      query: (reportId: number) => `/api/reports?reportId=${reportId}`,
      providesTags: ['Reports'],
    }),
  }),
})

export const { useGetReportsQuery } = apiReportsSlice
