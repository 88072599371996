import { createApi } from '@reduxjs/toolkit/query/react'
import publicAPIBaseQuery from '../publicApiBaseQuery'
import { type FactorEvent, type TimelineEvent } from 'components/Timeline/types'

export const apiInboundEmailsSlice = createApi({
  reducerPath: 'inboundEmailsApi',
  baseQuery: publicAPIBaseQuery,
  tagTypes: ['InboundEmail'],
  endpoints: (builder) => ({
    getInboundEmailBySid: builder.query({
      query: (emailSid: string) => `/v1/InboundEmails/${emailSid}`,
      providesTags: ['InboundEmail'],
    })
  }),
})

export const enrichEventsWithEmailContent = async (events: TimelineEvent[], dispatch: (any)): Promise<any> => {
  return await Promise.all(events.map(async (event: TimelineEvent) => {
    if (event.eventType === "InboundEmailRelationsCreateEvent") {
      try {
        const emailSid = (event.event as FactorEvent).inboundEmailSid;
        const emailData = await dispatch(apiInboundEmailsSlice.endpoints.getInboundEmailBySid.initiate(emailSid ?? "")).unwrap();
        return { ...event, event: { ...(event.event as FactorEvent), emailContent: emailData.content, fromEmail: emailData.fromEmail } };
      } catch (error) {
        console.error('Failed to fetch email for event:', error);
        return event;
      }
    }
    return event;
  }));
}

export const { useLazyGetInboundEmailBySidQuery } = apiInboundEmailsSlice
