import { type FC } from 'react'
import clsx from 'clsx'

interface BulletProps {
  first?: boolean
}
export const Bullet: FC<BulletProps> = ({ first = false }) => {
  return (
    <div className="font-extrabold text-center leading-lg relative mr-6 absolute top-[3px]">
      <div
        className={clsx(
          'w-[16px] h-4 rounded-full relative border border-teal-light',
          { 'bg-white': first },
          { 'bg-teal-light': !first }
        )}
      />
    </div>
  )
}
