import { type ItemGroup, type ItemGroupWithRelations } from "../../types";
import { type TimelineEvent as ITimelineEvent } from './types'

export type empty = null | undefined

export interface UpdateEvent {
  delayed: number
  onTime: number
  detailLink: string
}

export interface TimelineEvent {
  id: string
  event: string | FactorEvent
  eventTime: string
  eventType: string
}

export enum UpdateKey {
  COURIER_SID = 'courierSid',
  DELIVERED = 'delivered',
  DELIVERY_DATE = 'deliveryDate',
  DUE_DATE = 'dueDate',
  LAST_UPDATE_DATE = 'lastUpdateDate',
  QUANTITY = 'quantity',
  SHIP_DATE = 'shipDate',
  SHIPPED = 'shipped',
  TRACKING_NUMBER = 'trackingNumber',
  VERSION = 'version',
}

export interface UpdatedItem {
  current: string
  previous: string
}

export interface FactorEvent {
  fromEmail?: string,
  emailContent?: string,
  inboundEmailSid?: string
  created: string
  createdBy?: {
    human: {
      humanSid: string
      name: string
      organizationSid: string
    } | undefined
    requestingApp?: {
      apiKeySid?: string
      name?: string
      organizationSid?: string | null
    }
    sourceApp?: {
      apiKeySid?: string
      name?: string
    }
  }
  updatedBy?: {
    human: {
      humanSid: string
      name: string
      organizationSid: string
    } | undefined
    requestingApp?: {
      apiKeySid?: string
      name?: string
      organizationSid?: string | null
    }
    sourceApp?: {
      apiKeySid?: string
      name?: string
    }
  }
  message?: {
    content: string
  }
  eventType: string
  itemGroup: ItemGroup
  itemGroupCreate?: ItemGroup
  flag?: any
  confirmed: boolean | null
  id: string
  updateRequest: {
    created: string
    note: string
    organizationSid: string
    sid: string
    updated: string
  }
  updates: Record<UpdateKey, UpdatedItem | null>
  CreatedBy_Human_Name?: string
  EventType?: string
  OrderInvite_Email?: string
  itemUniqueName?: string
}

export interface TimelineProps {
  itemName: string
  eventFeed: TimelineEvent[]
  selectedItemGroups?: ItemGroupWithRelations[]
  role?: string
}

export interface MessageProps {
  data: ITimelineEvent
  itemName: string
  selectedItemGroups?: ItemGroupWithRelations[]
  role?: string
  showItemUniqueName?: boolean
}

export interface TimelineEventProps {
  event: ITimelineEvent
  first?: boolean
  itemName: string
  selectedItemGroups?: ItemGroupWithRelations[]
  role?: string
}
