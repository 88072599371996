import { createSlice, type Slice } from "@reduxjs/toolkit";
import { setFileStatusAction, setFileSidAction, setKeepPollingAction, resetDownloadCSVAction } from './actions/downloadCSV'

export interface DownloadCSVState {
  fileStatus: string,
  fileSid: string | null,
  keepPolling: boolean,
}

export const initialState: DownloadCSVState = {
  fileStatus: 'uninitialized',
  fileSid: null,
  keepPolling: false,
}

const downloadCSVStateSlice: Slice<DownloadCSVState> = createSlice({
  name: 'downloadCSV',
  initialState,
  reducers: {
    setFileStatus: setFileStatusAction,
    setFileSid: setFileSidAction,
    setKeepPolling: setKeepPollingAction,
    resetDownloadCSV: resetDownloadCSVAction,
  },
})

export const {
  setFileStatus,
  setFileSid,
  setKeepPolling,
  resetDownloadCSV,
} = downloadCSVStateSlice.actions
export default downloadCSVStateSlice.reducer
