import { type ChangeEventHandler, type FC, type MouseEventHandler } from 'react'
import { type ItemGroupWithRelations, type OrderItem } from '../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import { StageBadge } from '../../StageBadge/StageBadge'
import { getStatusItemGroup } from '../../../utils/getStatusItemGroup/getStatusItemGroup'
import { formatName } from '../utils'
import { ItemGroupDate } from '../ItemGroupDate/ItemGroupDate'
import { TableRow } from '../TableRow/TableRow'
import dayjs from "dayjs";
import clsx from "clsx";

interface BuyerRowProps {
  data: ItemGroupWithRelations
  index: number
  onSelect?: (item: string) => void
  hide?: string[]
  isCheck: boolean
  onClick?: (open: boolean, itemName: string, itemSid: string, itemGroupSid: string, orderSid?: string) => void
}

const BuyerRow: FC<BuyerRowProps> = ({
  data,
  index,
  onSelect,
  hide = [],
  isCheck,
  onClick,
}) => {
  const { status, classes } = getStatusItemGroup(data)
  const item = data.item as OrderItem
  const MAX_SIZE_NAME = 30

  const handleViewHistory: MouseEventHandler<HTMLSpanElement> = (e): void => {
    e.stopPropagation();
    onClick?.(true, data.label, data.item?.sid as string, data.sid, data.order?.sid)
  }

  const handleSelectItemGroup: MouseEventHandler<HTMLTableRowElement> | ChangeEventHandler<HTMLInputElement> = (e: any): void => {
    e.stopPropagation();
    onSelect?.(data.sid)
  }

  return (
    <TableRow
      key={data.sid}
      testId={`table-row-${index}`}
      index={index}
      onClick={handleSelectItemGroup as MouseEventHandler<HTMLTableRowElement>}
      selected={isCheck}
    >
      <td className="whitespace-nowrap py-4 pl-5 sm:pl-8 pr-3 text-sm font-medium ">
          <input
            onChange={handleSelectItemGroup as ChangeEventHandler<HTMLInputElement>}
            className="mr-2 border-offBlack-light outline-0 rounded"
            type="checkbox"
            checked={isCheck}
            data-testid={`${data.sid}-checkbox`}
          />

          <span
            className="align-middle ml-1 text-blue-mid underline font-semibold cursor-pointer"
            onClick={handleViewHistory}
          >
          {formatName(data.label, MAX_SIZE_NAME)}
        </span>

        <div className={`ml-7 mt-1 ${classes} flex items-center`}>
          <FontAwesomeIcon className="mr-2 text-[8px]" icon={light('circle')} />{' '}
          <span className="text-xs">{status}</span>
        </div>
      </td>
      {!hide.includes('order') ? (
        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
          <Link
            className="font-semibold antialiased text-blue-mid border-b border-blue-mid"
            to={`/orders/${item?.orderSid}`}
          >
            {data.order?.title ?? data.order?.uniqueName ?? data.order?.sid}
          </Link>
        </td>
      ) : null}
      {!hide.includes('vendor') ? (
        <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0 hidden md:table-cell">
          {/* Hide it until there is a view of a vendor
          <Link
            className="font-semibold antialiased text-blue-mid border-b border-blue-mid"
            to={`/vendor/${data.vendor.sid}`}
          >
            {data.vendor.name}
          </Link> */}
          {data.vendor.name}
        </td>
      ) : null}
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0  hidden md:table-cell">
        <span className="font-medium">{`${data.quantity ?? ''}`}</span>
      </td>
      <td className="whitespace-nowrap py-4 pr-2 text-sm font-medium sm:pl-2 md:pl-0  hidden md:table-cell">
        <StageBadge itemGroup={data} />
      </td>
      {/**
       * Dates
       */}
      <ItemGroupDate date={data.shipDate} condition={data.shipped} className="hidden xl:table-cell" />
      <ItemGroupDate date={data.deliveryDate} condition={data.delivered}  className="hidden xl:table-cell"/>
      <ItemGroupDate date={data.dueDate} condition={data.deliveryDate !== 'Unknown' || data.shipDate !== 'Unknown'} className="hidden xl:table-cell" />
      {/**
       * Update Requests
       */}
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium sm:pl-6 md:pl-0 hidden xl:table-cell">
        <span
          className={clsx(
            'font-medium',
            data.lastUpdateDate === 'Not yet' && 'text-red-mid',
            dayjs
              .utc(data.lastUpdateDate)
              .isBefore(data.updateRequestLastCreated) && 'text-red-mid'
          )}
        >
          {data.lastUpdateDate}
        </span>
      </td>
      <td className="xl:hidden pr-5">
        <button className="p-2">
          <FontAwesomeIcon icon={light('plus')} />
        </button>
      </td>
    </TableRow>
  )
}

export default BuyerRow
