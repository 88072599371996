import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from '../baseQuery'
import { enrichEventsWithEmailContent } from '../inboundEmails/InboundEmails'
import { toTimelineEvents } from 'utils/timelineEvent/timelineEvent'
import { type TimelineEvent } from 'components/Timeline/types'

export const apiFeedEvents = createApi({
  reducerPath: 'feedEventApi',
  baseQuery,
  tagTypes: ['feedEvents','feedOrderEvents'],
  endpoints: (builder) => ({
    getFeedEvents: builder.query({
      query: ({ itemGroupSid, orderSid }) => ({
        url: `/api/itemgroupevents`,
        params: { itemGroupSid, orderSid },
      }),
      providesTags: ['feedEvents'],
      onQueryStarted: async ({ itemGroupSid, orderSid }, { queryFulfilled, dispatch, getState }) => {
        try {
          let { data: events } = await queryFulfilled;
          events = await enrichEventsWithEmailContent(events, dispatch);
          dispatch(apiFeedEvents.util.updateQueryData('getFeedEvents', { itemGroupSid, orderSid }, draft => {
            return events;
          }));
        } catch (error) {
          console.error('Failed to enrich events:', error);
        }
      },
      transformResponse: (response, meta, arg) => {
        return toTimelineEvents((response as TimelineEvent[]))
      },
    }),
    getFeedOrderEvents: builder.query({
      query: ({ orderSid }) => ({
        url: `/api/orderEvents`,
        params: {orderSid}
      }),
      providesTags: ['feedOrderEvents'],
    }),
  }),
})

export const {
  useGetFeedEventsQuery,
  useGetFeedOrderEventsQuery,
} = apiFeedEvents