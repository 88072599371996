import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { type VendorStats, type ItemGroup, type LastUpdateRequest, type UpdateRequestConfig, } from '../../types'

interface DatesFormatted {
  shipDate: string
  deliveryDate: string
  dueDate: string
  updateRequestLastCreated: string
  lastUpdateDate: string
  updateRequestNext: string
  estimatedArrivalDate: string
}

dayjs.extend(utc)
dayjs.extend(tz)
type empty = null | undefined

export const dateUtcFormatted = (date: string): string => {
  return dayjs.utc(date).format('MM/DD/YY')
}

export const getDateOrDefault = (
  value: string | empty,
  fallback: string
): string => {
  return value === undefined || value === null
    ? fallback
    : dateUtcFormatted(value)
}

export const calculateEstimatedArrivalDate = (itemGroup: ItemGroup, vendorStats: VendorStats | null): string | null => {
  if (itemGroup.dueDate === null && itemGroup.deliveryDate === null) return null;
  const date = itemGroup.deliveryDate === null ? dayjs.utc(itemGroup.dueDate) : dayjs.utc(itemGroup.deliveryDate)
  const averageDelayDays = (vendorStats?.averageDelayDays !== undefined) ? Number(vendorStats?.averageDelayDays) : 0
  const estimatedArrivalDate = date.add(averageDelayDays, 'day')
  if (estimatedArrivalDate.isBefore(dayjs().utc())) {
    return itemGroup.deliveryDate === null ? null : date.format('MM/DD/YYYY'); // returns vendor's delivery date if it's not null
  }
  return estimatedArrivalDate.format('MM/DD/YYYY')
}

export const formatDateForRender = (
  itemGroup: ItemGroup,
  lastUpdateRequests: LastUpdateRequest | empty,
  updateRequestConfigs: UpdateRequestConfig | empty,
  vendorStats: VendorStats | null,
): DatesFormatted => {
  const shipDate: string = getDateOrDefault(itemGroup.shipDate, 'Unknown')
  const deliveryDate: string = getDateOrDefault(
    itemGroup.deliveryDate,
    'Unknown'
  )
  const dueDate: string = getDateOrDefault(itemGroup.dueDate, 'Unknown')
  const updateRequestLastCreated: string =
    lastUpdateRequests !== undefined && lastUpdateRequests !== null
      ? dateUtcFormatted(lastUpdateRequests.created)
      : 'Not yet'
  const lastUpdateDate: string = getDateOrDefault(
    itemGroup.lastUpdateDate,
    'Not yet'
  )
  const updateRequestNext: string =
    updateRequestConfigs !== undefined && lastUpdateRequests !== null
      ? getDateOrDefault(updateRequestConfigs?.nextUpdate, 'Paused')
      : 'Paused'

  const estimatedArrivalDate: string = getDateOrDefault(
    calculateEstimatedArrivalDate(itemGroup, vendorStats),
    'No data yet'
  )
  return {
    shipDate,
    deliveryDate,
    dueDate,
    updateRequestLastCreated,
    lastUpdateDate,
    updateRequestNext,
    estimatedArrivalDate,
  }
}
