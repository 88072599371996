import { clsx } from 'clsx'
import React, { type FC, useRef } from "react";
import { Link } from 'react-router-dom'

import { TableRow } from '../TableRow/TableRow'
import { Tooltip } from "../../Tooltip/Tooltip";

interface ProjectItemRowProps {
  data: any
  index: number
}

const ProjectItemRow: FC<ProjectItemRowProps> = ({ data, index }) => {
  const orders: string[] = data.ordersOnTime?.split(',') ?? []
  const pastDue: string[] = data.ordersPastDue?.split(',') ?? []

  const allOrders = [...orders, ...pastDue].sort()
  const allRefs: Record<string, React.MutableRefObject<any>> = {}
  allOrders.forEach((order: string) => {
    allRefs[order] = useRef(null)
  })
  const pastDueStyle = 'text-offBlack-light italic'
  return (
    <TableRow key={data.sid} testId={`table-row-${index}`} index={index}>
      <td className="self-stretch grow text-sm font-semibold tracking-normal text-left py-4 pl-5 sm:pl-8 pr-3 max-w-md overflow-ellipsis overflow-hidden whitespace-nowrap">
        {data.bomSid !== null ? (
          <Link to={`/projects/${data.bomSid}`}>
            <span className="underline text-blue-mid max-w-max">
              {' '}
              {data.name}
            </span>
          </Link>
        ) : (
          <span className="max-w-max">{data.name}</span>
        )}
      </td>
      <td
        className={clsx(
          'whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0  hidden md:table-cell',
          { 'text-red-mid': data.ordered === '0' }
        )}
      >
        <span>{`${data.ordered ?? ''}`}</span>
      </td>
      <td data-testid={`on-hand-${index}`}
        className={clsx(
          'whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0  hidden md:table-cell',
          { 'text-red-mid': data.onhand === '0' }
        )}
      >
        <span>{`${data.onhand ?? ''}`}</span>
      </td>
      <td
        className={clsx(
          'whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0  hidden md:table-cell',
          { 'text-red-mid': data.ctb === '0' }
        )}
      >
        <span>{`${data.ctb ?? ''}`}</span>
      </td>
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium md:pl-0  hidden md:table-cell">
        <span>{`${data.req ?? ''}`}</span>
      </td>
      <td className="py-4 pr-3 text-sm font-medium md:pl-0 w-[700px] hidden md:table-cell">
        {allOrders.length === 0 ? ('--') : (
          <span>
            {allOrders.map((order: string, i: number) => {
              return (
                <span
                  key={i}
                  ref={allRefs[order]}
                  className={`${pastDue.includes(order) ? pastDueStyle : ''}`}
                >
                  {order}{i === allOrders.length - 1 ? '' : ', '}
                </span>
              )
            })}
          </span>
        )}
      </td>
      {pastDue.length > 0 && (
        pastDue.map((order: string, i: number) => {
          return (
            <Tooltip position={'top'} key={i} ref={allRefs[order]}>
              <span>
                This past-due PO is not <br/>
                considered in ordered calculation
              </span>
            </Tooltip>
          )}
        )
      )}
    </TableRow>
  )
}

export default ProjectItemRow
