import { type FC } from 'react'
import { Slideover } from '../Slideover/Slideover'
import { Timeline } from '../Timeline/Timeline'
import { useSelector } from 'react-redux'
import { slideOverSelector } from '../../store/selectors'
import { useAppDispatch } from '../../store'
import { closeSlideOver, openSlideOver } from '../../store/slideOvers'

export const ItemActivity: FC = () => {
  const { data, name, isFetching, onClose } = useSelector(slideOverSelector)
  const dispatch = useAppDispatch()
  const show = name === 'ItemActivity' && Object.keys(data).length > 0

  const handleClose = (): void => {
    onClose?.();
    dispatch(closeSlideOver())
  }

  const isLoading = isFetching ?? false
  const handleOpenUpdate = (): void => {
      const { items, role } = data;
      dispatch(openSlideOver({ name: 'UpdateOrderItem', data: { items, role } }))
  }

  return (
    <Slideover
      open={show}
      onClose={handleClose}
      title={`Activity on ${data?.itemName ?? ''}`}
    >
      <div className="underline text-blue-mid text-xs absolute -top-[45px] right-6 cursor-pointer" onClick={handleOpenUpdate}>
        View/Edit Item Details
      </div>
      {data === null || isLoading ? (
        <div>{isLoading && 'Loading...'}</div>
      ) : (
        <Timeline itemName={data?.itemName} eventFeed={data?.sortedEvents} selectedItemGroups={data?.items} role={data?.role} />
      )}
    </Slideover>
  )
}
