import { createApi } from '@reduxjs/toolkit/query/react'
import publicApiBaseQuery from '../publicApiBaseQuery'

export const apiFieldMappingSlice = createApi({
  reducerPath: 'fieldMappingApi',
  baseQuery: publicApiBaseQuery,
  tagTypes: ['FieldMapping', 'MappedFields'],
  endpoints: (builder) => ({
    getFieldMapping: builder.query({
      query: () => `/v1/FieldMappings`,
      providesTags: ['FieldMapping'],
    }),
    getMappedFields: builder.query({
      query: (sid: string) => `/v1/MappedFields?fieldMappingSid=${sid}`,
      providesTags: ['MappedFields'],
    }),
  }),
})

export const {
  useGetFieldMappingQuery,
  useGetMappedFieldsQuery,
} = apiFieldMappingSlice
