import { DeliveryOption, ShipOption } from './types'

export const shipmentOptions = [
  {
    label: 'Not shipped',
    value: ShipOption.pending,
  },
  {
    label: 'Shipped',
    value: ShipOption.shipped,
  },
]

export const deliveryOptions = [
  {
    label: 'Not delivered',
    value: DeliveryOption.pending,
  },
  {
    label: 'Delivered',
    value: DeliveryOption.delivered,
  },
]
