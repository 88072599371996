import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetFileExportContentQuery } from '../../services/fileExports/FileExports'
import { useAppDispatch } from '../../store'
import { resetDownloadCSV } from '../../store/downloadCSV'
import { openNotification } from '../../store/notifications'
import { downloadCSVSelector } from '../../store/selectors'
import { downloadFile } from '../../utils/downloadFile/downloadFile'


export default function useDownloadFile(): void {
  const { fileStatus, fileSid } = useSelector(downloadCSVSelector)
  const [getFileExportContent] = useLazyGetFileExportContentQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    ;(async () => {
      if (fileStatus === 'finished' && fileSid !== null) {
        const { url } = (await getFileExportContent(fileSid).unwrap()) as {
          url: string
        }
        const content = await fetch(url)
        const blob = await content.blob()
        downloadFile(blob, 'export.csv')
        dispatch(
          resetDownloadCSV({
            fileStatus: 'uninitialized',
            fileSid: null,
            keepPolling: false,
          })
        )
        dispatch(
          openNotification({
            title: 'Download complete',
            message:
              'Your document has downloaded successfully! Check your browser’s download folder for your file.',
            type: 'success',
            show: true,
          })
        )
      }
    })()
  }, [fileStatus, fileSid])

  useEffect(() => {
    ;(async () => {
      if (fileStatus === 'failed' && fileSid !== null) {
        dispatch(
          openNotification({
            title: 'Download failed',
            message:
              'Your CSV has failed to generate. Please try again or contact support@factor.io for more assistance.',
            type: 'error',
            show: true,
          })
        )
        dispatch(
          resetDownloadCSV({
            fileStatus: 'uninitialized',
            fileSid: null,
            keepPolling: false,
          })
        )
      }
    })()
  }, [fileStatus, fileSid])
}
