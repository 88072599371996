import React, { type FC } from 'react'
import { useSelector } from 'react-redux'
import { Slideover } from 'components/Slideover/Slideover'

import { slideOverSelector } from 'store/selectors'
import { useAppDispatch } from '../../store'
import { closeSlideOver } from '../../store/slideOvers'
import { useGetMappedFieldsQuery } from '../../services/fieldMapping/FieldMapping'
import { type MappedField } from '../../types'

export const FieldMappingSlideover: FC = () => {
  const { data: { fieldMappingSid, fieldMappingName }, name } = useSelector(slideOverSelector)
  const show = name === 'FieldMapping'


  const { data: fieldMapping, isLoading: isLoadingFieldMapping } = useGetMappedFieldsQuery(fieldMappingSid)

  const dispatch = useAppDispatch()

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  if (isLoadingFieldMapping) return <div>Loading...</div>

  return (
    <Slideover open={show} onClose={handleClose} title={`${fieldMappingName} Field Map`}>
      <div className="grid grid-cols-2 text-offBlack-light text-xs uppercase border-b border-offBlack-lightest py-2">
        <div>Your field</div>
        <div>Factor&apos;s field</div>
      </div>
      {fieldMapping.mappedFields.map((field: MappedField)=>(
        <div key={field.sid} className="grid grid-cols-2 text-sm text-offBlack-dark border-b border-offBlack-lightest py-2">
          <div>{field.name}</div>
          <div>{field.mappedFieldType}</div>
        </div>
      ))}
    </Slideover>
  )
}
