import dayjs from 'dayjs'
import React, { type FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useGetFlagBySidQuery,
  useLazyGetItemGroupEventsQuery,
} from '../../services/orders/Orders'
import { useAppDispatch } from '../../store'
import { slideOverSelector } from '../../store/selectors'
import { closeSlideOver, openSlideOver } from '../../store/slideOvers'
import { toTimelineEvents } from '../../utils/timelineEvent/timelineEvent'
import { Button } from '../Button/Button'
import { Slideover } from '../Slideover/Slideover'

export const QualitySlideover: FC = () => {
  const { name, data } = useSelector(slideOverSelector)
  const dispatch = useAppDispatch()
  const [
    getSelectedItemGroupEvents,
    { data: selectedItemEvents, isFetching: isFetchingEvents },
  ] = useLazyGetItemGroupEventsQuery()
  const { data: flag, isLoading: flagLoading } = useGetFlagBySidQuery(
    data?.eventData?.flag.sid
  )

  console.log('flag', flag)

  const sortedEvents =
    selectedItemEvents?.itemGroupEvents !== undefined
      ? toTimelineEvents(selectedItemEvents?.itemGroupEvents)
      : []

  console.log('data', data)

  useEffect(() => {
    if (sortedEvents.length > 0) {
      dispatch(closeSlideOver())
      dispatch(
        openSlideOver({
          name: 'ItemActivity',
          data: {
            itemName: data.itemName,
            sortedEvents,
            items: data.selectedItemGroups,
          },
          isFetching: isFetchingEvents,
        })
      )
    }
  }, [sortedEvents, isFetchingEvents])

  if (isFetchingEvents || flagLoading) return <div>Loading...</div>

  const show = name === 'QualityIssue'

  const handleClose = (): void => {
    dispatch(closeSlideOver())
  }

  const navigateToActivityLog = async (): Promise<void> => {
    getSelectedItemGroupEvents(data.eventData.flag.itemGroupSid, true)
  }

  const openUpdateSlideover = (): void => {
    dispatch(closeSlideOver())
    dispatch(
      openSlideOver({
        name: 'UpdateOrderItem',
        data: { items: data.selectedItemGroups, role: data.role },
      })
    )
  }

  const issue: {
    causeCondition: string
    expectedCondition: string
    disposition: string
  } = JSON.parse(flag?.message ?? '{}')

  return (
    <Slideover
      open={show}
      onClose={handleClose}
      title={`Quality issue on ${data?.itemName ?? ''}`}
    >
      <div
        className="underline text-blue-mid text-xs absolute -top-[45px] right-6 cursor-pointer"
        onClick={navigateToActivityLog}
      >
        Activity Log
      </div>
      <div>
        <div className="text-offBlack-mid text-sm grid grid-cols-2">
          <div className="text-xs uppercase">Created date</div>
          <div>{dayjs(flag?.created).format('MM/DD/YYYY HH:mm A')}</div>
          <div className="text-xs uppercase">Updated date</div>
          <div>{dayjs(flag?.updated).format('MM/DD/YYYY HH:mm A')}</div>
          <div className="text-xs uppercase">Cause</div>
          <div>{issue.causeCondition ?? ''} </div>
          <div className="text-xs uppercase">Expected condition</div>
          <div>{issue.expectedCondition ?? ''} </div>
          <div className="text-xs uppercase">Disposition</div>
          <div>{issue.disposition ?? ''} </div>
        </div>
        <div className="flex justify-end mt-auto absolute bottom-0 right-6">
          <Button style="secondary" onClick={handleClose}>
            Done
          </Button>
          {data?.role === 'vendor' && (
            <Button onClick={openUpdateSlideover}>Add an update</Button>
          )}
        </div>
      </div>
    </Slideover>
  )
}
