import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from '../baseQuery'
import {type AutoRegistrationData, type PreRegistration, type RegistrationData} from 'types'

export const apiRegistrationSlice = createApi({
  reducerPath: 'registrationApi',
  baseQuery,
  tagTypes: ['Registration'],
  endpoints: (builder) => ({
    createRegisterInvitation: builder.mutation({
      query: (body: PreRegistration) => ({
        url: '/api/register',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'AKh7qhk7m9kfbd377m3y8jqg4ft4qh'
        },
        body,
      }),
    }),
    getInvitation: builder.query({
      query: (inviteTokenSid) => `/api/register/${inviteTokenSid}`,
    }),
    registerUser: builder.mutation({
      query: (body: RegistrationData) => ({
        url: `/api/register/${body.inviteTokenSid}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body,
      }),
    }),
    autoRegisterUser: builder.mutation({
      query: (data: AutoRegistrationData) => ({
        url: `/api/register/${data.inviteTokenSid}/auto${data.confirmItemGroups !== null && data.confirmItemGroups !== '' ? `?confirm=${data.confirmItemGroups}` : ''}`,
        method: 'POST'
      }),
    }),
  }),
})

export const {
  useLazyGetInvitationQuery,
  useGetInvitationQuery,
  useRegisterUserMutation,
  useCreateRegisterInvitationMutation,
  useAutoRegisterUserMutation
} =
  apiRegistrationSlice
