import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as paths from '../routing/paths'
import { type INavItem } from '../types'

export const buyerNav: INavItem[] = [
  {
    url: paths.TRACK,
    label: 'Track',
    icon: <FontAwesomeIcon icon={light('map-location-dot')} />,
  },
  {
    url: paths.REPORT,
    label: 'Report',
    icon: <FontAwesomeIcon icon={light('chart-line')} />,
  },
]

export const vendorNav: INavItem[] = [
  {
    url: paths.UPDATE,
    label: 'Update',
    icon: <FontAwesomeIcon icon={light('sync')} />,
  },
]
