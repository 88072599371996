import { type ItemGroup } from 'types'

export const countItems = (itemGroups: ItemGroup[]): number => {
  const itemIds = new Set()
  for (const itemGroup of itemGroups) {
    if (Object.prototype.hasOwnProperty.call(itemGroup, 'itemSid')) {
      itemIds.add(itemGroup.itemSid)
    }
  }
  return itemIds.size
}
